import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MonthlyCalendarDay } from '../schedule/schedule-api.service';
import { ScheduleCalendarActiveSlot } from '../schedule/calendar/schedule-calendar.service';

@Injectable({
  providedIn: 'root'
})
export default class FreeSlotsService {
  public activeSlot = new BehaviorSubject<ScheduleCalendarActiveSlot>(null);
  public activeDateForNewSlot = new BehaviorSubject<Date>(null);
  public freeSlotsCalendarDays = new BehaviorSubject<Record<string, MonthlyCalendarDay>>(null);
  public newSlotsCalendarDays = new Subject<MonthlyCalendarDay[]>();
  public freeSlotsFormValueChanged = new BehaviorSubject<boolean>(null);
  public freeSlotsIdsWithTimesToRemove = new BehaviorSubject<string[]>([]);
}
