import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';

export enum I18nLanguage {
  EN = 'en',
  PL = 'pl',
  UA = 'ua',
  DE = 'de',
  FR = 'gf',
  CZ = 'cz',
  OTHER = 'other',
}

export class LanguageLoader implements TranslateLoader {
  public getTranslation(language: I18nLanguage): Observable<{ [key: string]: string }> {
    return from(import('./i18n/' + language + '.json'));
  }
}

@Injectable({ providedIn: 'root' })
export default class LanguageService {
  constructor(private readonly translateService: TranslateService) {}

  public get locale(): string {
    switch (this.translateService.currentLang) {
      case I18nLanguage.UA:
        return 'UK';
      case I18nLanguage.PL:
        return 'PL';
      case I18nLanguage.EN:
        return 'US';
      default:
        return 'US';
    }
  }
}
