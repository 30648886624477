import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import DealDto from '../schedule/dto/deal.dto';
import TeacherDto from '../schedule/dto/teacher.dto';

export interface DealInfoFormExtra {
  numberOfLessons?: number;
  paymentStatus?: 'paid' | 'unpaid';
  teacherName?: string;
  studentName?: string;
  nextSubscriptionDone?: boolean;
  reserveNextSubscription?: boolean;
}

export type NextSubscriptionReservationsAction = void | 'set-available' | 'stop-first' | 'decline-all';

export interface UpsertDealRequest {
  info?: Partial<DealDto> & DealInfoFormExtra;
  reservationsToCreate?: {
    date: string;
    timeSlots: number[];
  }[];
  futureSubReservationsToCreate?: {
    date: string;
    timeSlots: number[];
  }[];
  reservationsToRemove?: string[];
  reserveNextDeal?: boolean;
}

const HOST = environment.PifagorApiAddress;

@Injectable({
  providedIn: 'root',
})
export default class DealApiService {
  constructor(private readonly http: HttpClient) {}

  public getById(id: string): Observable<DealDto> {
    return this.http.get<DealDto>(`${HOST}/deals/${id}`);
  }

  public getByNumber(number: string): Observable<DealDto> {
    return this.http.get<DealDto>(`${HOST}/deals/number/${number}`);
  }

  public createSubscriptionDeal(data: UpsertDealRequest): Observable<void> {
    return this.http.post<void>(`${HOST}/deals/subscription`, data);
  }

  public updateSubscriptionDealById(id: string, data: UpsertDealRequest): Observable<DealDto> {
    return this.http.put<DealDto>(`${HOST}/deals/subscription/${id}`, data);
  }

  public cancelSubscriptionDealById(dealId: string, reason: string): Observable<void> {
    return this.http.post<void>(`${HOST}/deals/subscription/${dealId}/cancel`, { reason });
  }

  public checkFutureSubscriptionDone(dealId: string): Observable<boolean> {
    return this.http.get<boolean>(`${HOST}/deals/check-future-sub/${dealId}`);
  }

  public getRecommendedTeachers(dealId: string): Observable<Array<Partial<TeacherDto>>> {
    return this.http.get<Array<Partial<TeacherDto>>>(`${HOST}/deals/recommendations/${dealId}`);
  }
}
