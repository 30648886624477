import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import ScheduleCalendarService, { ScheduleCalendarType } from '../schedule-calendar.service';

@Component({
  selector: 'mathema-schedule-calendar-type-switch',
  templateUrl: './schedule-calendar-type-switch.component.html',
  styleUrls: ['./schedule-calendar-type-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarTypeSwitchComponent implements OnInit {

  @Input() desktopMode: boolean;

  @Input() hideYear: boolean;

  public readonly calendarTypes: typeof ScheduleCalendarType = ScheduleCalendarType;

  public typeSwitchMobileMenuOpened: boolean = false;

  public activeTabIndex = 1;

  public fromMonth: number | null = null;
  constructor(
    public readonly scheduleCalendarService: ScheduleCalendarService,
  ) { }

  public activateType(type: ScheduleCalendarType): void {
    const currentDate = this.scheduleCalendarService.calendarDate.value;
    const currentType = this.scheduleCalendarService.calendarType.value;


    if (currentType === ScheduleCalendarType.WEEK && type === ScheduleCalendarType.MONTH) {
      if (this.fromMonth !== currentDate.getMonth()) {
        // compensate current date to match last visited month
        currentDate.setDate(currentDate.getDate() + 6);
      }
    } else if (currentType === ScheduleCalendarType.MONTH && type === ScheduleCalendarType.WEEK) {
      // if change month view to week view for current month, setup date as today to display current week
      const now = new Date();

      if (currentDate.getMonth() === now.getMonth()) {
        currentDate.setDate(now.getDate());
      }
    }

    if (currentType === ScheduleCalendarType.MONTH) {
      this.fromMonth = currentDate.getMonth();
    } else {
      this.fromMonth = null;
    }

    this.activeTabIndex = type;
    this.scheduleCalendarService.calendarType.next(type);
    this.scheduleCalendarService.diapasonDateChange.next(currentDate);
  }

  public ngOnInit(): void {
    this.scheduleCalendarService.calendarType.subscribe(type => {
      if (this.activeTabIndex !== type) {
        this.activeTabIndex = type;
      }
    })
  }
}
