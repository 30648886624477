/**
 * Student
 */
type Student = {
  id: string;
  first_name: string;
  last_name: string;
};

export { Student };
