import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import ScheduleCalendarService, { ScheduleCalendarType } from '../schedule-calendar.service';
import { Subscription } from 'rxjs';
import LanguageService from '../../../../../shared/language/language.service';

interface DiapasonSwitchState {
  type: ScheduleCalendarType,
  ranges: {
    value: Date;
    label: string;
    sublabel?: string;
    isActive?: boolean;
  }[];
}

@Component({
  selector: 'mathema-schedule-calendar-diapason-switch',
  templateUrl: './schedule-calendar-diapason-switch.component.html',
  styleUrls: ['./schedule-calendar-diapason-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarDiapasonSwitchComponent implements OnInit, OnDestroy {

  @Input() desktopMode: boolean;

  public readonly calendarTypes: typeof ScheduleCalendarType = ScheduleCalendarType;
  public diapasonSwitchState: DiapasonSwitchState;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly languageService: LanguageService,
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.scheduleCalendarService.calendarType.subscribe(type => {
      this.setupSwitchState(type);
    }));

    this.subscriptions.push(this.scheduleCalendarService.diapasonDateChange.subscribe(date => {
      this.setupSwitchState(this.diapasonSwitchState.type, date);
    }));

    const activeDate = this.diapasonSwitchState.ranges.find(el => el.isActive).value;
    this.scheduleCalendarService.calendarDate.next(activeDate);
  }

  private setupSwitchState(type: ScheduleCalendarType, value?: Date, idx?: number): void {
    if (type === ScheduleCalendarType.YEAR) {
      this.initYearRanges(value, idx);
    } else if (type === ScheduleCalendarType.MONTH) {
      this.initMonthRanges(value, idx);
    } else if (type === ScheduleCalendarType.WEEK) {
      this.initWeekRanges(value, idx);
    } else if (type === ScheduleCalendarType.DAY) {
      this.initDayRanges(value, idx)
    }

    const activeRange = this.diapasonSwitchState.ranges.find(el => el.isActive);
    this.scheduleCalendarService.calendarDate.next(activeRange.value);
  }

  public setActiveRangeByIndex(idx: number): void {
    const currentActiveRangeIdx = this.diapasonSwitchState.ranges.findIndex(el => el.isActive);

    if (currentActiveRangeIdx != idx) {
      this.diapasonSwitchState.ranges[currentActiveRangeIdx].isActive = false;
      this.diapasonSwitchState.ranges[idx].isActive = true;

      this.scheduleCalendarService.calendarDate.next(this.diapasonSwitchState.ranges[idx].value);
    }
  }

  public setNextActiveRange(): void {
    const currentActiveRangeIdx = this.diapasonSwitchState.ranges.findIndex(el => el.isActive);

    if (currentActiveRangeIdx === 0) {
      this.setupSwitchState(this.diapasonSwitchState.type, this.diapasonSwitchState.ranges[1].value, 1);
    } else if (currentActiveRangeIdx === 1) {
      this.setupSwitchState(this.diapasonSwitchState.type, this.diapasonSwitchState.ranges[1].value, 2);
    } else if (currentActiveRangeIdx === 2) {
      this.setupSwitchState(this.diapasonSwitchState.type, this.diapasonSwitchState.ranges[2].value, 2);
    }
  }

  public setPastActiveRange(): void {
    const currentActiveRangeIdx = this.diapasonSwitchState.ranges.findIndex(el => el.isActive);

    if (currentActiveRangeIdx === 0) {
      this.setupSwitchState(this.diapasonSwitchState.type, this.diapasonSwitchState.ranges[0].value, 0);
    } else if (currentActiveRangeIdx === 1) {
      this.setupSwitchState(this.diapasonSwitchState.type, this.diapasonSwitchState.ranges[1].value, 0);
    } else if (currentActiveRangeIdx === 2) {
      this.setupSwitchState(this.diapasonSwitchState.type, this.diapasonSwitchState.ranges[1].value, 1);
    }
  }

  private initYearRanges(year?: Date, activeYearIdx?: number): void {
    let middleYear = new Date();

    if (year) {
      middleYear = year;
    }
    const pastYear = new Date();
    pastYear.setFullYear(middleYear.getFullYear() - 1);
    const nextYear = new Date();
    nextYear.setFullYear(middleYear.getFullYear() + 1);

    const years: Date[] = [pastYear, middleYear, nextYear];

    this.diapasonSwitchState = {
      type: ScheduleCalendarType.YEAR,
      ranges: years.map(year => ({ label: `${year.getFullYear()}`, value: year })),
    }

    this.diapasonSwitchState.ranges[activeYearIdx ?? 1].isActive = true;
  }

  private initMonthRanges(month?: Date, activeMonthIdx?: number): void {
    let middleMonth = new Date();

    if (month) {
      middleMonth = month;
    }

    middleMonth.setDate(1);

    const middleMonthIndex = middleMonth.getMonth();

    const pastMonth = new Date();
    if (middleMonthIndex === 0) {
      pastMonth.setFullYear(middleMonth.getFullYear() - 1);
      pastMonth.setDate(1);
      pastMonth.setMonth(11);
    } else {
      pastMonth.setFullYear(middleMonth.getFullYear());
      pastMonth.setDate(1);
      pastMonth.setMonth(middleMonthIndex - 1);
    }

    const nextMonth = new Date();
    if (middleMonthIndex === 11) {
      nextMonth.setFullYear(middleMonth.getFullYear() + 1);
      nextMonth.setDate(1);
      nextMonth.setMonth(0);
    } else {
      nextMonth.setFullYear(middleMonth.getFullYear());
      nextMonth.setDate(1);
      nextMonth.setMonth(middleMonthIndex + 1);
    }

    const months = [pastMonth, middleMonth, nextMonth];

    this.diapasonSwitchState = {
      type: ScheduleCalendarType.MONTH,
      ranges: months.map(month => ({ label: this.formatMonth(month), value: month, sublabel: `${month.getFullYear()}` })),
    }

    this.diapasonSwitchState.ranges[activeMonthIdx ?? 1].isActive = true;
  }

  private initWeekRanges(week?: Date, activeWeekIdx?: number): void {
    let middleWeek = new Date();

    if (week) {
      middleWeek = week;
    }

    if (middleWeek.getDay() !== 1) {
      const currentDay = middleWeek.getDay();
      middleWeek.setDate(middleWeek.getDate() - (currentDay - 1));
    }

    const pastWeek = new Date(middleWeek);
    pastWeek.setDate(pastWeek.getDate() - 7);
    const nextWeek = new Date(middleWeek);
    nextWeek.setDate(nextWeek.getDate() + 7);

    const weeks = [pastWeek, middleWeek, nextWeek];

    this.diapasonSwitchState = {
      type: ScheduleCalendarType.WEEK,
      ranges: weeks.map((week) => ({ label: this.formatWeek(week), value: week })),
    }

    this.diapasonSwitchState.ranges[activeWeekIdx ?? 1].isActive = true;
  }

  private initDayRanges(day?: Date, activeDayIdx?: number): void {
    let middleDay = new Date();

    if (day) {
      middleDay = day;
    }

    const pastDay = new Date(middleDay);
    pastDay.setDate(pastDay.getDate() - 1);
    const nextDay = new Date(middleDay);
    nextDay.setDate(nextDay.getDate() + 1);

    const days = [pastDay, middleDay, nextDay];

    this.diapasonSwitchState = {
      type: ScheduleCalendarType.DAY,
      ranges: days.map((day) => ({ label: this.formatDay(day), value: day, sublabel: this.formatDaySublabel(day) })),
    }

    this.diapasonSwitchState.ranges[activeDayIdx ?? 1].isActive = true;
  }

  private formatMonth(date: Date): string {
    const monthFormat = this.desktopMode ? 'long' : 'short';
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      month: monthFormat,
      year: 'numeric',
    });

    let [month, year] = formatter.format(date).split(' ');
    month = month[0].toUpperCase() + month.slice(1);

    if (this.desktopMode) {
      return `${month}, ${year}`;
    } else {
      return month;
    }
  }

  private formatWeek(startWeekDate: Date): string {
    const startWeekDayFormatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
    });
    const [startWeekDay, startWeekMonth] = startWeekDayFormatter.format(startWeekDate).split(' ');

    const endWeekDate = new Date(startWeekDate);
    endWeekDate.setDate(endWeekDate.getDate() + 6);
    const endWeekDayFormatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    let [endWeekDay, endWeekMonth, year] = endWeekDayFormatter.format(endWeekDate).split(' ');

    if (startWeekMonth === endWeekMonth) {
      return `${startWeekDay} - ${endWeekDay} ${endWeekMonth}, ${year}`;
    }

    return `${startWeekDay} ${startWeekMonth} - ${endWeekDay} ${endWeekMonth}, ${year}`;
  }

  private formatDay(day: Date): string {
    const weekday = this.desktopMode ? 'long' : 'short';
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      weekday,
    });

    const result = formatter.format(day);
    return result[0].toUpperCase() + result.slice(1);
  }

  private formatDaySublabel(day: Date): string {
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: '2-digit',
      month: 'short',
    });

    const [dayOfMonth, month] = formatter.format(day).split(' ');

    return `${dayOfMonth} ${month[0].toUpperCase() + month.slice(1, 3)}`;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
