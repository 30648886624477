import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mathema-slot-icon',
  templateUrl: './slot-icon.component.html',
  styleUrls: ['./slot-icon.component.scss']
})
export class SlotIconComponent implements OnInit {

  @Input() type: string;
  @Input() color: string;
  @Input() color2: string;

  constructor() { }

  ngOnInit(): void {
  }

}
