import { LanguageEnum } from "../constants";
import { UserRole } from '../../modules/components/auth/auth.service';

export enum RolesEnum {
  ADMIN = 'ADMIN',
  ADMIN_ID = '61e8e9c1-6d6c-4611-a5d9-254864b78c7f',
  MANAGER = 'MANAGER',
  MANAGER_ID = '741e4614-ed07-49cf-899e-f4b4da1e564a',
  TEACHER = 'TEACHER',
  TEACHER_ID = '2c13b353-6769-4f8d-bd26-4a7a224790f5',
  CLIENT = 'CLIENT',
}

export enum GenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}
/**
 * User
 */
type User = {
  email: string;
  first_name: string;
  last_name: string;
  role: UserRole;
  roleId: string;
  teacher_id?: string;
  client_id?: string;
  language: LanguageEnum;
  gender: string;
  avatarLink?: string;
  blockedAvailability?: boolean;
};

export { User };
