<div class="expanded-teacher-week-loader" *ngIf="loading">
  <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
  <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
</div>
<nz-row class="week-days">
  <ng-container *ngIf="$weekSchedule | async as week; else emptySlots">
    <nz-col class="week-day" nzFlex="auto" *ngFor="let day of week | keyvalue:sortObjectNumericKeys">
      <div class="week-day-name">{{formatWeekDayName(day.value.date) | titlecase}}</div>
      <div class="week-day-number">{{formatWeekDayNumber(day.value.date) | titlecase}}</div>
      <div
        class="slots-box with-slots"
        *ngIf="day.value['items']?.length; else emptySlotsBox"
        [class.vacation]="day.value['isVacation']"
        nz-tooltip
        [nzTooltipTitle]="'calendar.vacations.vacation-day' | translate"
        [nzTooltipTrigger]="day.value['isVacation'] ? 'hover' : null"
      >
        <div class="item-wrapper" *ngFor="let item of day.value['items']" [class.lesson]="item.isLesson">
          <mathema-schedule-calendar-slot [item]="item" [viewType]="'week'" [teacherSearchMode]="true" [isVacation]="day.value['isVacation']"></mathema-schedule-calendar-slot>
        </div>
      </div>
      <ng-template #emptySlotsBox>
        <div class="slots-box empty">
          <span *ngIf="authService.isManager() || authService.isAdmin(); else noLessons">{{'reserve_not_available' | translate}}</span>
          <ng-template #noLessons>
            <span>{{'no_lessons' | translate}}</span>
          </ng-template>
        </div>
      </ng-template>
    </nz-col>
  </ng-container>
  <ng-template #emptySlots>
    <nz-col class="week-day" nzFlex="auto" *ngFor="let day of emptyDays">
      <div class="week-day-name">{{formatWeekDayNameFromNumber(day) | titlecase}}</div>
      <div class="week-day-number">{{formatWeekDayNumberNameFromNumber(day) | titlecase}}</div>
      <div class="slots-box with-slots"></div>
    </nz-col>
  </ng-template>
</nz-row>
