import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  callback: Subject<string>;

  username: string;
  password: string;

  touched = {
    username: false,
    password: false,
  };

  error = {
    username: false,
    password: false,
    wrong: '',
  };

  public fieldsIsValid: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.callback = new Subject<string>();

    this.callback.subscribe((message) => {
      this.error.wrong = message;
    });

    if (!this.authService.isLoggedIn()) return;

    this.router.navigate(['/users']);
  }

  focus(event: Event) {
    this.touched = {
      ...this.touched,
      // @ts-ignore
      [event.target.name]: true,
    };
  }

  blur(event: Event) {
    this.error = {
      ...this.error,
      // @ts-ignore
      [event.target.name]: !event.target.value,
    };
  }

  login() {
    this.authService.login(this.username, this.password, this.callback).catch(err => {
      this.error = {
        ...this.error,
        username: true,
        password: true,
      };
    });
  }

  keyDown(event: KeyboardEvent) {
    if (event.keyCode !== 13 || this.checkButtonDisabled()) return;

    this.login();
  }

  checkButtonDisabled() {
    return !this.username || !this.password;
  }

  public checkValidation(): void{
    this.fieldsIsValid = !!String(this.username)
      .toLowerCase()
      .match(
        '[a-z0-9]+@[a-z]+\.[a-z]{2,3}'
      );
  }
}
