import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import ScheduleApiService, { MonthlyCalendarItem } from '../../../schedule-api.service';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NzModalRef } from 'ng-zorro-antd/modal';
import LessonDto from '../../../dto/lesson.dto';
import LanguageService from '../../../../../../shared/language/language.service';
import ScheduleCalendarService from '../../schedule-calendar.service';
import { timeStringToTimeSlotDiapason } from '../../../../../../shared/utils';
import { splitFreeSlotsByLessonTimes } from '../../../slots-utils';

@Component({
  selector: 'mathema-move-lesson-dnd-modal',
  templateUrl: './move-lesson-dnd-modal.component.html',
  styleUrls: ['./move-lesson-dnd-modal.component.scss']
})
export class MoveLessonDndModalComponent implements OnInit, OnDestroy {

  @Input() lessonItem: MonthlyCalendarItem;
  @Input() freeSlotItem: MonthlyCalendarItem;
  @Input() showNotification = false;

  public isLoading: boolean;
  public lessonData$: Observable<LessonDto>;
  public freeTimeDiapasons: string[] = [];
  public checkedTime: string | null;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);
  public notificationNeeded = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly modalRef: NzModalRef,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.loadingError$.next(false);
    this.lessonData$ = this.scheduleApiService.getLessonInfoById(this.lessonItem.id).pipe(
      catchError(() => {
        this.loadingError$.next(true);
        return EMPTY;
      }),
    );
    this.freeTimeDiapasons = splitFreeSlotsByLessonTimes(
      this.freeSlotItem.date,
      timeStringToTimeSlotDiapason(this.freeSlotItem.time),
      timeStringToTimeSlotDiapason(this.lessonItem.time).length,
    );
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public onTimeChecked(isChecked: boolean, time: string): void {
    if (isChecked) {
      this.checkedTime = time;
    } else {
      this.checkedTime = null;
    }
  }

  public onConfirmMoving(): void {
    const diapason = timeStringToTimeSlotDiapason(this.checkedTime);

    this.isLoading = true;
    this.subscriptions.push(
      this.scheduleApiService.moveLesson(this.lessonItem.id, this.freeSlotItem.id, diapason, this.notificationNeeded).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(() => {
        this.scheduleCalendarService.calendarDate.next(this.scheduleCalendarService.calendarDate.value);
        this.modalRef.close();
      }),
    );
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
