<div class="wrapper">
  <div class="title">
    <mat-icon>calculate</mat-icon>
    <span class="text">{{'points_calculator' | translate}}</span>
  </div>

  <div class="calculation-wrapper">
    <div class="calculator-body">
      <div class="calculation-block-row">
        <div class="calculation-block">
          <div class="calculation-item">
            <div class="label" style="margin-top: 14px">{{'positive_parents_response' | translate}}</div>
            <app-input
              [max]="initialSettings.positiveResponsesMaxValue"
              [min]="_userRewards?.feedbacks?.positive"
              [error]="positiveResponses.invalid"
              [formControl]="positiveResponses"
              [noWrapOpt]="true"
              inputType="number"
              [label]="'Amount' | translate"
              [customInputParams]="{customWidth: '84px'}"
            >

            </app-input>
            <app-input [value]="calculateTeachersPoints().positiveResponses" inputType="display-field" [label]="'points_plural' | translate" [noWrapOpt]="true" [customInputParams]="{customWidth: '84px'}"></app-input>
          </div>
          <div class="calculation-item">
            <div class="label">{{'completed_lessons' | translate}}</div>
            <app-input [max]="initialSettings.lessonsPassedMaxValue" [min]="_lessonsPassedInp" [error]="lessonsPassed.invalid" [formControl]="lessonsPassed" inputType="number" [customInputParams]="{customWidth: '84px'}" [hideLabel]="true"></app-input>
            <app-input [value]="calculateTeachersPoints().lessonsPassed" inputType="display-field" [customInputParams]="{customWidth: '84px'}" [hideLabel]="true"></app-input>
          </div>
        </div>
        <div class="calculation-block">
          <div class="calculation-item">
            <div class="label" style="margin-top: 14px">{{'negative_parents_response' | translate}}</div>
            <app-input [max]="initialSettings.negativeResponsesMaxValue" [min]="_userRewards?.feedbacks?.negative" [error]="negativeResponses.invalid" [noWrapOpt]="true" [formControl]="negativeResponses" inputType="number" [label]="'Amount' | translate" [customInputParams]="{customWidth: '84px'}"></app-input>
            <app-input [value]="calculateTeachersPoints().negativeResponses" inputType="display-field" [label]="'points_plural' | translate" [noWrapOpt]="true" [customInputParams]="{customWidth: '84px'}"></app-input>
          </div>
          <div class="calculation-item">
            <div class="label">{{'regulation_violations_school' | translate}}</div>
            <app-input [max]="initialSettings.regulationViolationMaxValue" [min]="_userRewards?.regulationViolations" [error]="regulationViolation.invalid" [formControl]="regulationViolation" inputType="number" [customInputParams]="{customWidth: '84px'}" [hideLabel]="true"></app-input>
            <app-input [value]="calculateTeachersPoints().regulationViolation" inputType="display-field" [customInputParams]="{customWidth: '84px'}" [hideLabel]="true"></app-input>
          </div>
        </div>
      </div>
      <div class="add-points-wrapper">
        <div class="button-container" style="margin-top: {{addAchievementsPoints ? 9 : 21}}px;">
          <button class="add-point-for-achievements" (click)="addAchievementsPoints = !addAchievementsPoints">
            <mat-icon [class.active]="addAchievementsPoints">add</mat-icon>
            {{addAchievementsPoints ? ('hide_additional_points' | translate) : ('add_additional_points' | translate)}}
          </button>
        </div>
        <div class="add-point-for-achievements" *ngIf="addAchievementsPoints">
          <div class="label" style="max-width: 203px">{{'my_additional_points_for_achievements' | translate}}</div>
          <app-input [value]="calculateTeachersPoints().achievementsPoints" inputType="display-field" [customInputParams]="{customWidth: '84px'}" [hideLabel]="true"></app-input>
        </div>
      </div>
    </div>
    <div class="calculator-results">
      <div class="result-container">
        <div class="text-container">
          <div class="title">{{'calculation_result' | translate}}</div>
          <div class="calc-result">
            <span>{{calculateTeachersPoints().summarise}}</span>
            <span> {{'points' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="calculation-container">
        <div class="images-container">
          <div class="img-item" *ngFor="let item of userLevels, index as i">
            <img [src]="item.image" [alt]="item.name" [class.active]="getActiveLevelLevel(i)">
            <div
              class="status-icon"
              nz-tooltip
              [nzTooltipTitle]="tooltip"
            >
              <ng-template #tooltip>
                <span class="math-tooltip-header">{{('required_points_number' | translate) + ': ' + item.pointsFrom.toString()}}</span><br>
                <span>{{calculateTeachersPoints().summarise < item.pointsFrom ? (('points_leak' | translate)+ ' ' + (item.pointsFrom - calculateTeachersPoints().summarise) + ' ' + 'points' | translate) : ''}}</span>
              </ng-template>
              <div class="hexagon-container">
                <mat-icon class="external">hexagon</mat-icon>
                <div class="inner-hexagon-container">
                  <mat-icon class="internal" [class.active]="calculateTeachersPoints().levelVal >= item.pointsFrom">hexagon</mat-icon>
                </div>
              </div>

            </div>
            <div class="rate" *ngIf="translateService.currentLang === i18nLanguage.UA || translateService.currentLang === i18nLanguage.PL">
              <div class="rate-value" [class.active]="getActiveLevelLevel(i)">{{translateService.currentLang === i18nLanguage.PL ? item.ratePl : item.rate}}</div>
              <div class="currency" [class.active]="getActiveLevelLevel(i)"> {{'currancy' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="progress-bar-container">
          <div class="progress-bar">
            <div class="progress" style="width: {{getPercentageValue()}}%"></div>
          </div>
        </div>
        <div class="payment-per-lesson" *ngIf="translateService.currentLang === i18nLanguage.UA">
          {{'payment_per_lesson' | translate}}
        </div>
      </div>
    </div>
  </div>
  <div class="tips-wrapper" [class.special]="tipsArr[tipsCurrentIndex].isSpecial">
    <div class="img">
      <img [src]="tipsArr[tipsCurrentIndex].photo" [alt]="tipsArr[tipsCurrentIndex].id" [@simpleFadeAnimation]="currentState" (@simpleFadeAnimation.done)="animationFinished($event)">
    </div>
    <div class="description">
      <div class="title">
        <mat-icon>notifications</mat-icon>
        <span class="text">{{'tips' | translate}}</span>
      </div>
      <div class="description-text" [@simpleFadeAnimation]="currentState" (@simpleFadeAnimation.done)="animationFinished($event)">{{tipsArr[tipsCurrentIndex].translation | translate}}</div>
      <div class="controls">
        <span class="control-item" (click)="tooltipControl('previous')">{{'previous_tip' | translate}}</span>
        <span class="control-item" (click)="tooltipControl('next')">{{'next_tip' | translate}}</span>
      </div>
    </div>
  </div>
</div>
