export const photoLinks: {
  noPhotoBoyLink: string;
  noPhotoGirlLink: string;
  stillInWorkPhotoLink: string;
} = {
  stillInWorkPhotoLink: 'https://dev-matema-file-storage.s3.eu-central-1.amazonaws.com/11b9bfea-5ed7-4c8f-974a-453dd7a74c4e-still-in-work-img.png',
  noPhotoBoyLink: 'https://dev-matema-file-storage.s3.eu-central-1.amazonaws.com/1a5c284d-65f4-49fe-937f-d24a12da93e0-NoPhotoBoy.png',
  noPhotoGirlLink: 'https://dev-matema-file-storage.s3.eu-central-1.amazonaws.com/6735a7ad-132a-4f2a-8f95-7c3652d7df71-NoPhotoGirl.png',
}

export enum LanguageEnum {
  english = 'EN',
  polish = 'PL',
  ukrainian = 'UA',
  german = 'DE',
  french = 'GF',
  czechia = 'CZ',
  other = 'other',
}

export enum Regions {
  PL = 'Polska',
  UA = 'Україна',
  EN = 'English',
}

export const phoneNumbersFormats: {oneDigitsFormat: string[]; twoDigitsFormats: string[]; threeDigitsFormat: string[]} = {
  oneDigitsFormat: ['1','7',],
  twoDigitsFormats: ['44','48','93','54','61','43','32','55','56','86','57','53','45','20','33','49','36','91','30','62','98','39','81','60','52','95','31','64','47','92','51','63','48','40','65','27','82','34','94','46','41','66','90','44','58','84',],
  threeDigitsFormat: ['355','213','376','244','374','297','994','973','880','375','501','229','975','591','599','387','673','359','226','257','855','237','238','236','242','682','506','385','357','420','253','593','503','240','291','372','251','500','298','679','358','594','689','241','220','995','233','350','299','502','509','504','852','354','964','353','972','962','254','965','996','856','371','961','266','231','218','423','370','352','389','261','265','960','223','356','596','222','230','691','373','377','976','382','212','264','977','687','505','227','234','850','968','680','507','675','595','351','974','262','250','685','966','221','381','248','232','421','386','677','252','211','249','597','268','963','886','992','255','670','228','676','216','993','688','971','256','380','598','998','678','967','260','263',]
}

export enum PaymentLevels {
  PREMIUM = 'Premium',
  GOLD = 'Gold',
  SILVER = 'Silver',
  BASIC = 'Basic',
}

export const halfHours = [
  '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00',
  '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30',
  '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00',
  '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
];

export const TIME_HOURS = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
];

// array represents time values of used timeSlot array format in Schedule and Lesson entities
// time value can be accessed by timeSlot element (index) 0 is '00:00', 287 is '23:55', etc.
export const TIME_SLOTS_VALUES: string[] = [
  '00:00', '00:05', '00:10', '00:15', '00:20', '00:25', '00:30', '00:35', '00:40', '00:45', '00:50', '00:55', '01:00',
  '01:05', '01:10', '01:15', '01:20', '01:25', '01:30', '01:35', '01:40', '01:45', '01:50', '01:55', '02:00', '02:05',
  '02:10', '02:15', '02:20', '02:25', '02:30', '02:35', '02:40', '02:45', '02:50', '02:55', '03:00', '03:05', '03:10',
  '03:15', '03:20', '03:25', '03:30', '03:35', '03:40', '03:45', '03:50', '03:55', '04:00', '04:05', '04:10', '04:15',
  '04:20', '04:25', '04:30', '04:35', '04:40', '04:45', '04:50', '04:55', '05:00', '05:05', '05:10', '05:15', '05:20',
  '05:25', '05:30', '05:35', '05:40', '05:45', '05:50', '05:55', '06:00', '06:05', '06:10', '06:15', '06:20', '06:25',
  '06:30', '06:35', '06:40', '06:45', '06:50', '06:55', '07:00', '07:05', '07:10', '07:15', '07:20', '07:25', '07:30',
  '07:35', '07:40', '07:45', '07:50', '07:55', '08:00', '08:05', '08:10', '08:15', '08:20', '08:25', '08:30', '08:35',
  '08:40', '08:45', '08:50', '08:55', '09:00', '09:05', '09:10', '09:15', '09:20', '09:25', '09:30', '09:35', '09:40',
  '09:45', '09:50', '09:55', '10:00', '10:05', '10:10', '10:15', '10:20', '10:25', '10:30', '10:35', '10:40', '10:45',
  '10:50', '10:55', '11:00', '11:05', '11:10', '11:15', '11:20', '11:25', '11:30', '11:35', '11:40', '11:45', '11:50',
  '11:55', '12:00', '12:05', '12:10', '12:15', '12:20', '12:25', '12:30', '12:35', '12:40', '12:45', '12:50', '12:55',
  '13:00', '13:05', '13:10', '13:15', '13:20', '13:25', '13:30', '13:35', '13:40', '13:45', '13:50', '13:55', '14:00',
  '14:05', '14:10', '14:15', '14:20', '14:25', '14:30', '14:35', '14:40', '14:45', '14:50', '14:55', '15:00', '15:05',
  '15:10', '15:15', '15:20', '15:25', '15:30', '15:35', '15:40', '15:45', '15:50', '15:55', '16:00', '16:05', '16:10',
  '16:15', '16:20', '16:25', '16:30', '16:35', '16:40', '16:45', '16:50', '16:55', '17:00', '17:05', '17:10', '17:15',
  '17:20', '17:25', '17:30', '17:35', '17:40', '17:45', '17:50', '17:55', '18:00', '18:05', '18:10', '18:15', '18:20',
  '18:25', '18:30', '18:35', '18:40', '18:45', '18:50', '18:55', '19:00', '19:05', '19:10', '19:15', '19:20', '19:25',
  '19:30', '19:35', '19:40', '19:45', '19:50', '19:55', '20:00', '20:05', '20:10', '20:15', '20:20', '20:25', '20:30',
  '20:35', '20:40', '20:45', '20:50', '20:55', '21:00', '21:05', '21:10', '21:15', '21:20', '21:25', '21:30', '21:35',
  '21:40', '21:45', '21:50', '21:55', '22:00', '22:05', '22:10', '22:15', '22:20', '22:25', '22:30', '22:35', '22:40',
  '22:45', '22:50', '22:55', '23:00', '23:05', '23:10', '23:15', '23:20', '23:25', '23:30', '23:35', '23:40', '23:45',
  '23:50', '23:55',
];

// object represents numbers of timeSlot array format in Schedule and Lesson entities,
// that can be accessed by time format through keys
export const TIME_SLOTS_INDICES: { [key: string]: number } = {
  "00:00": 0,
  "00:05": 1,
  "00:10": 2,
  "00:15": 3,
  "00:20": 4,
  "00:25": 5,
  "00:30": 6,
  "00:35": 7,
  "00:40": 8,
  "00:45": 9,
  "00:50": 10,
  "00:55": 11,
  "01:00": 12,
  "01:05": 13,
  "01:10": 14,
  "01:15": 15,
  "01:20": 16,
  "01:25": 17,
  "01:30": 18,
  "01:35": 19,
  "01:40": 20,
  "01:45": 21,
  "01:50": 22,
  "01:55": 23,
  "02:00": 24,
  "02:05": 25,
  "02:10": 26,
  "02:15": 27,
  "02:20": 28,
  "02:25": 29,
  "02:30": 30,
  "02:35": 31,
  "02:40": 32,
  "02:45": 33,
  "02:50": 34,
  "02:55": 35,
  "03:00": 36,
  "03:05": 37,
  "03:10": 38,
  "03:15": 39,
  "03:20": 40,
  "03:25": 41,
  "03:30": 42,
  "03:35": 43,
  "03:40": 44,
  "03:45": 45,
  "03:50": 46,
  "03:55": 47,
  "04:00": 48,
  "04:05": 49,
  "04:10": 50,
  "04:15": 51,
  "04:20": 52,
  "04:25": 53,
  "04:30": 54,
  "04:35": 55,
  "04:40": 56,
  "04:45": 57,
  "04:50": 58,
  "04:55": 59,
  "05:00": 60,
  "05:05": 61,
  "05:10": 62,
  "05:15": 63,
  "05:20": 64,
  "05:25": 65,
  "05:30": 66,
  "05:35": 67,
  "05:40": 68,
  "05:45": 69,
  "05:50": 70,
  "05:55": 71,
  "06:00": 72,
  "06:05": 73,
  "06:10": 74,
  "06:15": 75,
  "06:20": 76,
  "06:25": 77,
  "06:30": 78,
  "06:35": 79,
  "06:40": 80,
  "06:45": 81,
  "06:50": 82,
  "06:55": 83,
  "07:00": 84,
  "07:05": 85,
  "07:10": 86,
  "07:15": 87,
  "07:20": 88,
  "07:25": 89,
  "07:30": 90,
  "07:35": 91,
  "07:40": 92,
  "07:45": 93,
  "07:50": 94,
  "07:55": 95,
  "08:00": 96,
  "08:05": 97,
  "08:10": 98,
  "08:15": 99,
  "08:20": 100,
  "08:25": 101,
  "08:30": 102,
  "08:35": 103,
  "08:40": 104,
  "08:45": 105,
  "08:50": 106,
  "08:55": 107,
  "09:00": 108,
  "09:05": 109,
  "09:10": 110,
  "09:15": 111,
  "09:20": 112,
  "09:25": 113,
  "09:30": 114,
  "09:35": 115,
  "09:40": 116,
  "09:45": 117,
  "09:50": 118,
  "09:55": 119,
  "10:00": 120,
  "10:05": 121,
  "10:10": 122,
  "10:15": 123,
  "10:20": 124,
  "10:25": 125,
  "10:30": 126,
  "10:35": 127,
  "10:40": 128,
  "10:45": 129,
  "10:50": 130,
  "10:55": 131,
  "11:00": 132,
  "11:05": 133,
  "11:10": 134,
  "11:15": 135,
  "11:20": 136,
  "11:25": 137,
  "11:30": 138,
  "11:35": 139,
  "11:40": 140,
  "11:45": 141,
  "11:50": 142,
  "11:55": 143,
  "12:00": 144,
  "12:05": 145,
  "12:10": 146,
  "12:15": 147,
  "12:20": 148,
  "12:25": 149,
  "12:30": 150,
  "12:35": 151,
  "12:40": 152,
  "12:45": 153,
  "12:50": 154,
  "12:55": 155,
  "13:00": 156,
  "13:05": 157,
  "13:10": 158,
  "13:15": 159,
  "13:20": 160,
  "13:25": 161,
  "13:30": 162,
  "13:35": 163,
  "13:40": 164,
  "13:45": 165,
  "13:50": 166,
  "13:55": 167,
  "14:00": 168,
  "14:05": 169,
  "14:10": 170,
  "14:15": 171,
  "14:20": 172,
  "14:25": 173,
  "14:30": 174,
  "14:35": 175,
  "14:40": 176,
  "14:45": 177,
  "14:50": 178,
  "14:55": 179,
  "15:00": 180,
  "15:05": 181,
  "15:10": 182,
  "15:15": 183,
  "15:20": 184,
  "15:25": 185,
  "15:30": 186,
  "15:35": 187,
  "15:40": 188,
  "15:45": 189,
  "15:50": 190,
  "15:55": 191,
  "16:00": 192,
  "16:05": 193,
  "16:10": 194,
  "16:15": 195,
  "16:20": 196,
  "16:25": 197,
  "16:30": 198,
  "16:35": 199,
  "16:40": 200,
  "16:45": 201,
  "16:50": 202,
  "16:55": 203,
  "17:00": 204,
  "17:05": 205,
  "17:10": 206,
  "17:15": 207,
  "17:20": 208,
  "17:25": 209,
  "17:30": 210,
  "17:35": 211,
  "17:40": 212,
  "17:45": 213,
  "17:50": 214,
  "17:55": 215,
  "18:00": 216,
  "18:05": 217,
  "18:10": 218,
  "18:15": 219,
  "18:20": 220,
  "18:25": 221,
  "18:30": 222,
  "18:35": 223,
  "18:40": 224,
  "18:45": 225,
  "18:50": 226,
  "18:55": 227,
  "19:00": 228,
  "19:05": 229,
  "19:10": 230,
  "19:15": 231,
  "19:20": 232,
  "19:25": 233,
  "19:30": 234,
  "19:35": 235,
  "19:40": 236,
  "19:45": 237,
  "19:50": 238,
  "19:55": 239,
  "20:00": 240,
  "20:05": 241,
  "20:10": 242,
  "20:15": 243,
  "20:20": 244,
  "20:25": 245,
  "20:30": 246,
  "20:35": 247,
  "20:40": 248,
  "20:45": 249,
  "20:50": 250,
  "20:55": 251,
  "21:00": 252,
  "21:05": 253,
  "21:10": 254,
  "21:15": 255,
  "21:20": 256,
  "21:25": 257,
  "21:30": 258,
  "21:35": 259,
  "21:40": 260,
  "21:45": 261,
  "21:50": 262,
  "21:55": 263,
  "22:00": 264,
  "22:05": 265,
  "22:10": 266,
  "22:15": 267,
  "22:20": 268,
  "22:25": 269,
  "22:30": 270,
  "22:35": 271,
  "22:40": 272,
  "22:45": 273,
  "22:50": 274,
  "22:55": 275,
  "23:00": 276,
  "23:05": 277,
  "23:10": 278,
  "23:15": 279,
  "23:20": 280,
  "23:25": 281,
  "23:30": 282,
  "23:35": 283,
  "23:40": 284,
  "23:45": 285,
  "23:50": 286,
  "23:55": 287
};

export const NO_ENTITY_ID = '00000000-0000-0000-0000-000000000000';