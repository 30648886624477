import DealDto from './deal.dto';
import TeacherDto from './teacher.dto';
import StudentDto from './student.dto';
import HistoryDto from './history.dto';
import MaterialDto from './material.dto';

export enum LessonType {
  FIRST = 'first',
  REGULAR = 'regular',
}

export enum LessonStatus {
  BOOKED = 'booked',
  RESERVED = 'reserved',
  CANCELED = 'canceled',
}

export enum LessonAction {
  MOVE = 'move',
  SKIP = 'skip',
  CONDUCT = 'conduct',
  EDIT_CONDUCTED_LESSON = 'edit_conducted_lesson',
  REMOVE_CONDUCTION = 'remove_conduction',
}

export interface LessonHistoryContext {
  action: LessonAction;
}

export default interface LessonDto {
  id: string;
  date: string;
  timeSlot: number[];
  type: LessonType;
  status: LessonStatus;
  isConducted: boolean;
  deal: DealDto;
  teacher: TeacherDto;
  student: StudentDto;
  markedForFutureSubscription: boolean;
  history: HistoryDto<Partial<LessonDto>, LessonHistoryContext>[];
  subject: string;
  homework: string;
  notes: string;
  links: string[];
  materials: MaterialDto[];
}
export interface DaysSchedule {
  day: number;
  time: string;
}

export interface CreateLessonDto {
  start: string;
  count: number;
  timeSlots: DaysSchedule[];
  type: 'first' | 'regular';
  status: 'booked' | 'reserved' | 'canceled';
  deal: string;
  teacherId: string;
  studentId: string;
  lang?: string;
  classNumber?: string;
  parentComment?: string;
}

export interface UpdateFirstLessonDto {
  lang?: string;
  classNumber?: string;
  parentComment?: string;
  teacherId?: string;
  date?: string;
  timeRange?: string;
}
