<nz-layout class="page">
  <nz-drawer
    [nzClosable]="false"
    (nzOnClose)="filtersOpened=false"
    [nzVisible]="filtersOpened"
    [nzContent]="filtersComponent"
    nzPlacement="left"
    [nzTitle]="'filters' | translate"
  ></nz-drawer>
  <nz-sider
    nzCollapsible
    nzBreakpoint="lg"
    [nzTrigger]="null"
    [nzCollapsedWidth]="0"
    [nzWidth]="380"
    class="sidebar"
  >
    <nz-row class="sidebar-filters-header" nzJustify="end">
      <nz-col [nzXs]="2" nzPull="2" class="main-menu"><mathema-main-menu></mathema-main-menu></nz-col>
      <nz-col nzPull="2"><h2>{{'filters' | translate}}</h2></nz-col>
      <nz-col>
        <button nz-button nzType="text" class="filters-btn" (click)="resetAllFilters()">
          <span nz-icon nzType="reload"></span>
          {{'reset-all-filters' | translate}}
        </button>
      </nz-col>
    </nz-row>
    <ng-template [ngTemplateOutlet]="filtersComponent"></ng-template>
  </nz-sider>

  <nz-layout class="content">
    <div class="header">
      <nz-row>
        <nz-col [nzXs]="2" [nzSm]="2" [nzMd]="2" [nzLg]="0" class="main-menu-small"><mathema-main-menu></mathema-main-menu></nz-col>
        <nz-col [nzXs]="22" [nzSm]="22" [nzMd]="22" [nzLg]="0" class="logo">
          <img src="./assets/Logo_sm.png" alt="mathema.me">
        </nz-col>
      </nz-row>
      <nz-row>
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="0" class="active-filters-md">
          <mat-free-hours-filters-state
            (onToggleFiltersModal)="modalFiltersOpened = !modalFiltersOpened"
            (onToggleFiltersDrawer)="filtersOpened = !filtersOpened"
            [activeFilters]="activeFilters"
            [teachersFound]="total"
            (onDeactivateFilter)="resetFilterWithEvent($event)"
          ></mat-free-hours-filters-state>
        </nz-col>
      </nz-row>
      <nz-row><nz-col><h1 class="page-title">{{'search-available-hours' | translate}}</h1></nz-col></nz-row>
      <nz-row>
        <nz-col  [nzXs]="0" [nzSm]="0" [nzMd]="0" [nzLg]="24">
          <mat-free-hours-filters-state
            (onToggleFiltersDrawer)="filtersOpened = !filtersOpened"
            [activeFilters]="activeFilters"
            [teachersFound]="total"
            (onDeactivateFilter)="resetFilterWithEvent($event)"
          ></mat-free-hours-filters-state>
        </nz-col>
      </nz-row>
    </div>
    <mathema-some-loading-error *ngIf="errorHappened"></mathema-some-loading-error>
    <div class="no-content" *ngIf="tableData.length === 0 && !errorHappened">
      <ng-template [ngTemplateOutlet]="noResults"></ng-template>
      <h3 class="reason">{{'fh-no-results' | translate}}</h3>
      <span class="details">{{'fh-no-results-details' | translate}}</span>
    </div>
    <nz-table
      *ngIf="!errorHappened && tableData.length > 0"
      #teachersTable
      nzTableLayout="fixed"
      nzShowSizeChanger
      [nzLoadingIndicator]="loader"
      [nzFrontPagination]="false"
      [nzNoResult]="noResults"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzTotal]="total"
      [nzPageSizeOptions]="[5, 10, 15, 20, 30, 40, 50]"
      [nzData]="tableData"
      (nzPageIndexChange)="onPageIndexChange($event)"
      (nzPageSizeChange)="onPageSizeChange($event)"
      class="teachers-table"
    >
      <thead>
        <tr>
          <th>{{'name' | translate}}</th>
          <th>{{'surname' | translate}}</th>
          <th class="small-cell">{{'age' | translate}}</th>
          <th nzEllipsis>{{'teacher_type' | translate}}</th>
          <th class="no-show-mobile" nzEllipsis>{{'payment_level' | translate}}</th>
          <th
            style="padding-left: 0"
            class="no-show-mobile sortable"
            nzEllipsis
            [nzSortDirections]="workloadSortingDirections"
            (nzSortOrderChange)="onColumnOrderChange($event, filtersKeys.WORKLOAD)"
            nzShowSort
          >
            {{'load' | translate}}
            <div class="priority {{numberToStringPriority(sortState[filtersKeys.WORKLOAD].priority)}}" (click)="changeSortPriority($event, filtersKeys.WORKLOAD)"></div>
          </th>
          <th
            class="no-show-mobile sortable"
            nzEllipsis
            [nzSortDirections]="conversionSortingDirections"
            (nzSortOrderChange)="onColumnOrderChange($event, filtersKeys.CONVERSION)"
            nzShowSort
          >
            {{'conversion' | translate}}
            <div class="priority {{numberToStringPriority(sortState[filtersKeys.CONVERSION].priority)}}" (click)="changeSortPriority($event, filtersKeys.CONVERSION)"></div>
          </th>
          <th class="no-show-mobile" nzEllipsis>{{'qualification' | translate}}</th>
          <th class="no-show-mobile" nzEllipsis>{{'tutor_experience' | translate}}</th>
          <th class="no-show-mobile" nzEllipsis>{{'preparing_to_exams' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let teacher of teachersTable.data">
          <div
            class="verified-triangle"
            *ngIf="(authService.isUserUkrainian() || authService.isUserPoland()) && teacher.isVerified"
            nz-tooltip
            [nzTooltipTitle]="'verified' | translate"
          ></div>
          <tr (click)="toggleTeacherCalendar(teacher.id)" [class.teacher-expanded]="teacher.id === expandedTeacherId">
            <td nzEllipsis nzBreakWord nz-tooltip [nzTooltipTitle]="teacher.firstName">
              <a class="td-link" (click)="$event.stopPropagation(); openAccountInfoPage(teacher.id)">{{teacher.firstName}}</a>
            </td>
            <td nzEllipsis nzBreakWord nz-tooltip [nzTooltipTitle]="teacher.lastName"><a class="td-link" (click)="openAccountInfoPage(teacher.id)">{{teacher.lastName}}</a></td>
            <td class="">{{teacher.age}}</td>
            <td nz-tooltip [nzTooltipTitle]="teacher.status | translate">
              <span [class]="teacher.status">{{teacher.status | translate}}</span>
            </td>
            <td class="no-show-mobile" nz-tooltip [nzTooltipTitle]="getPaymentLevelTip(teacher.level)"><span [class]="teacher.level">{{teacher.level}}</span></td>
            <td class="no-show-mobile">{{(teacher.teacherWorkLoad | number:'1.0-2') + '%'}}</td>
            <td class="no-show-mobile">
              <span *ngIf="teacher.conversion != '-1'">{{(teacher.conversion | number:'1.0-2') + '%'}}</span>
              <span *ngIf="teacher.conversion == '-1'">-</span>
            </td>
            <td class="no-show-mobile" nzEllipsis nz-tooltip [nzTooltipTitle]="teacher.qualification | translate">
              {{teacher.qualification | translate}}
            </td>
            <td class="no-show-mobile">{{teacher.tutoringExperience | translate}}</td>
            <td class="no-show-mobile">
              <ng-container *ngFor="let exam of teacher.examPreparation; let last = last">
                <span class="exam">{{exam | translate}}{{!last ? ', ' : ''}}</span>
              </ng-container>
            </td>
          </tr>
          <tr [nzExpand]="teacher.id === expandedTeacherId" class="week-switcher-row">
            <mathema-week-switcher
              *ngIf="teacher.id === expandedTeacherId"
              (onGoToLessons)="openTeacherLessonsPage(teacher.id)"
              [showLessonsLink]="true"
            ></mathema-week-switcher>
          </tr>
          <tr [nzExpand]="teacher.id === expandedTeacherId" class="calendar-row">
            <mathema-week-schedule
              *ngIf="teacher.id === expandedTeacherId"
              [teacherId]="teacher.id"
            ></mathema-week-schedule>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </nz-layout>
</nz-layout>

<nz-modal
  [nzContent]="filtersComponent"
  [(nzVisible)]="modalFiltersOpened"
  [nzTitle]="'filters' | translate"
  nzClassName="filters-mobile"
  (nzOnCancel)="modalFiltersOpened=false"
  (nzOnOk)="modalFiltersOpened=false"
>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="modalFiltersOpened=false" class="btn apply-btn">{{'fh-btn-apply' | translate}}</button>
    <button nz-button nzType="default" (click)="resetAllFilters(); modalFiltersOpened=false" nzDanger class="btn reset-btn">{{'reset-all-filters' | translate}}</button>
  </div>
</nz-modal>

<ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
<ng-template #loader>
  <nz-spin style="display: flex; height: 100%; align-items: center; justify-content: center;" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
</ng-template>

<ng-template #noResults>
  <div class="image">
    <img src="./assets/noResults.png" alt="">
  </div>
</ng-template>

<ng-template #filtersComponent>
  <mat-free-hours-filters
    [activeFilters]="activeFilters"
    [resetFilterEvent]="resetFilterSubject.asObservable()"
    [resetAllFiltersEvent]="resetAllFiltersSubject.asObservable()"
    (onResetFilter)="resetFilter($event)"
    (onChangeFilterValue)="changeFilterValue($event)"
    (onSetFilterValue)="setFilterValue($event)"
  ></mat-free-hours-filters>
</ng-template>

<!-- Create first lesson drawer -->
<nz-drawer
*ngIf="!isFirstLessonDrawerCollapsed"
[nzClosable]="false"
(nzOnClose)="onCloseFirstLessonForm()"
[nzVisible]="!isFirstLessonDrawerCollapsed"
[nzContent]="firstLessonComponent"
nzPlacement="right"
[nzWidth]="365"
[nzTitle]="null"
nzWrapClassName="create-first-lesson-drawer"
></nz-drawer>

<!-- Create first lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="firstLessonComponent"
  [(nzVisible)]="!isFirstLessonModalClosed"
  [nzTitle]="null"
  nzClassName="create-first-lesson-mobile"
  (nzOnCancel)="onCloseFirstLessonForm()"
  (nzOnOk)="onCloseFirstLessonForm()"
  [nzFooter]="null"
></nz-modal>

<!-- Create first lesson templates -->
<ng-template #firstLessonComponent>
  <mathema-schedule-calendar-first-lesson (onCancel)="onCloseFirstLessonForm()"></mathema-schedule-calendar-first-lesson>
</ng-template>