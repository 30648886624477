enum LessonTypes {
  FIRST = 'first',
  REGULAR = 'regular',
}

type LessonTypeOption = {
  label: string;
  value: LessonTypes;
};

export { LessonTypes, LessonTypeOption };
