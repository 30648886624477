import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class SettingsApiService {

  constructor(private readonly httpClient: HttpClient) { }

  public updateTeacherPhoto(teacherId: string, file: FormData): Observable<any> {
    return this.httpClient.post(`/teachers/${teacherId}/updatePhoto`, file);
  }

  public removeFileByUrl$DELETE(teacherId: string, avatarLink: string): Observable<void> {
    return this.httpClient.delete<void>(`/teachers/${teacherId}/avatar`, {body: {url: avatarLink}});
  }

  public updateUserPassword(teacherId: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.httpClient.patch<any>(`/user/${teacherId}`, {oldPassword, newPassword});
  }
}
