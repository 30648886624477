import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ViewUserInfoService {

  public updateTeacherInfo$: Subject<void> = new Subject<void>();

  constructor() { }

  public loadTeacherInfo(): void {
    this.updateTeacherInfo$.next();
  }
}
