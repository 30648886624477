import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ScheduleCalendarTypeSwitchComponent
} from './calendar/schedule-calendar-type-switch/schedule-calendar-type-switch.component';
import { NzModule } from '../../nz-module';
import {
  ScheduleCalendarDiapasonSwitchComponent
} from './calendar/schedule-calendar-diapason-switch/schedule-calendar-diapason-switch.component';
import { RemoveReservationModalComponent } from './calendar/modals/remove-reservation-modal/remove-reservation-modal.component';
import { RemoveFreeSlotModalComponent } from './calendar/modals/remove-free-slot-modal/remove-free-slot-modal.component';
import SharedModule from '../../../shared/shared.module';
import {
  MoveReservationModalComponent
} from './calendar/modals/move-reservation-modal/move-reservation-modal.component';
import { ConductLessonModalComponent } from './calendar/modals/conduct-lesson-modal/conduct-lesson-modal.component';
import { ReportModalComponent } from './calendar/modals/report-modal/report-modal.component';

@NgModule({
  imports: [NzModule, CommonModule, TranslateModule, FormsModule, SharedModule, ReactiveFormsModule],
  declarations: [
    ScheduleCalendarTypeSwitchComponent,
    ScheduleCalendarDiapasonSwitchComponent,
    RemoveReservationModalComponent,
    MoveReservationModalComponent,
    RemoveFreeSlotModalComponent,
    ConductLessonModalComponent,
    ReportModalComponent,
  ],
  exports: [
    ScheduleCalendarTypeSwitchComponent,
    ScheduleCalendarDiapasonSwitchComponent,
    ConductLessonModalComponent,
    ReportModalComponent,
  ],
})
export default class ScheduleModule {}
