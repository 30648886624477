<div class="notification">
  <div class="base-notification success-notification" [class.show]="notificationService.$showSuccessNotification | async">
    <div class="message">
      <div class="text">{{notificationService.$message | async}}</div>
    </div>
    <div class="close-btn">
      <mat-icon (click)="notificationService.instantCloseNotification()">close</mat-icon>
    </div>
  </div>
  <div class="base-notification info-notification" [class.show]="notificationService.$showInfoNotification | async">
    <div class="message">
      <div class="text">{{notificationService.$message | async}}</div>
    </div>
    <div class="close-btn">
      <mat-icon (click)="notificationService.instantCloseNotification()">close</mat-icon>
    </div>
  </div>
  <div class="base-notification error-notification" [class.show]="notificationService.$showErrorNotification | async">
    <div class="message">
      <div class="text">{{notificationService.$message | async}}</div>
    </div>
    <div class="close-btn">
      <mat-icon (click)="notificationService.instantCloseNotification()">close</mat-icon>
    </div>
  </div>
</div>
