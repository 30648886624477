<nz-tabset class="calendar-type-switch" *ngIf="desktopMode; else mobileTypeSwitch" [nzSelectedIndex]="activeTabIndex">
  <nz-tab nzTitle="{{'calendar.type.year' | translate}}" [nzDisabled]="hideYear" (nzClick)="activateType(calendarTypes.YEAR)"></nz-tab>
  <nz-tab nzTitle="{{'calendar.type.month' | translate}}" (nzClick)="activateType(calendarTypes.MONTH)"></nz-tab>
  <nz-tab nzTitle="{{'calendar.type.week' | translate}}" (nzClick)="activateType(calendarTypes.WEEK)"></nz-tab>
</nz-tabset>

<ng-template #mobileTypeSwitch>
  <button
    class="switch-btn-mobile"
    nz-dropdown
    [nzDropdownMenu]="addMenu"
    [nzOverlayClassName]="'switch-type-menu-overlay'"
    nzTrigger="click"
    (nzVisibleChange)="typeSwitchMobileMenuOpened = !typeSwitchMobileMenuOpened"
  >
    <ng-container [ngSwitch]="scheduleCalendarService.calendarType.value">
      <span class="text" *ngSwitchCase="calendarTypes.YEAR">{{'calendar.type.year' | translate}}</span>
      <span class="text" *ngSwitchCase="calendarTypes.MONTH">{{'calendar.type.month' | translate}}</span>
      <span class="text" *ngSwitchCase="calendarTypes.DAY">{{'calendar.type.day' | translate}}</span>
    </ng-container>
    <span nz-icon [nzRotate]="typeSwitchMobileMenuOpened ? 180 : 0" class="suffix">
      <img src="./assets/icons/suffix.svg" alt="suffix">
    </span>
  </button>
  <nz-dropdown-menu #addMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item
          [class.active]="scheduleCalendarService.calendarType.value === calendarTypes.YEAR"
          (click)="activateType(calendarTypes.YEAR)"
      >
        <span>{{'calendar.type.year' | translate}}</span>
      </li>
      <li nz-menu-item
          [class.active]="scheduleCalendarService.calendarType.value === calendarTypes.MONTH"
          (click)="activateType(calendarTypes.MONTH)"
      >
        <span>{{'calendar.type.month' | translate}}</span>
      </li>
      <li nz-menu-item
          [class.active]="scheduleCalendarService.calendarType.value === calendarTypes.DAY"
          (click)="activateType(calendarTypes.DAY)"
      >
        <span>{{'calendar.type.day' | translate}}</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-template>
