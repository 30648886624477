<div class="day-calendar" id="dayCalendar">
  <ng-container *ngIf="calendarView$">
    <ng-container *ngIf="calendarView$ | async as day; else loadingOrError">
      <ng-container *ngIf="currentTime$ | async as time">
        <div class="current-time" [style.top]="getTopOffsetForCurrentTime(time) + 'px'">
          <div class="value">{{time}}</div>
        </div>
      </ng-container>
      <div class="current-date">
        <ng-container *ngIf="isDrawer || isModal">
          <span nz-icon nzType="close" nzTheme="outline" (click)="closeDrawerOrModal()"></span>
        </ng-container>
        {{formatDate(day.date) | titlecase}}
      </div>
      <div class="days">
        <div
          class="day ruler"
          [class.vacation]="day.isVacation"
          nz-tooltip
          [nzTooltipTitle]="'calendar.vacations.vacation-day' | translate"
          [nzTooltipTrigger]="day.isVacation ? 'hover' : null"
        >
          <div *ngFor="let hour of RULER_HOURS" class="time"><span class="value">{{hour}}</span></div>
        </div>

        <div class="day">
          <div
            *ngFor="let hour of TIME_HOURS"
            class="hour-line"
            [style.top]="getTopOffsetFromTime(hour) + 'px'"
          ></div>

          <div class="items">
            <div
              class="item-wrapper"
              [class.lesson]="item.isLesson"
              *ngFor="let item of day.items"
              [style.top]="getTopOffsetForSlot(item.time) + 'px'"
              [style.height]="getHeightFromTime(item.time) + 'px'"
            >
              <mathema-schedule-calendar-slot
                (onSlotSelected)="handleSelectedSlot()"
                [item]="item"
                [viewType]="'week'"
                [isVacation]="day.isVacation"
              ></mathema-schedule-calendar-slot>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingOrError>
  <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  <ng-template #loading>
    <nz-spin class="day-calendar-spin" [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
