export interface TipsModel {
  id: string;
  translation: string;
  photo: string;
  isSpecial?: boolean;
}

export const TipsArr: TipsModel[] = [
  {
    photo: '/assets/tips/Time.svg',
    translation: 'tip1',
    id: 'tip1'
  },
  {
    photo: '/assets/tips/Schedule.svg',
    translation: 'tip2',
    id: 'tip2'
  },
  {
    photo: '/assets/tips/Statistic.svg',
    translation: 'tip3',
    id: 'tip3'
  },
  {
    photo: '/assets/tips/Kids.svg',
    translation: 'tip4',
    id: 'tip4'
  },
  {
    photo: '/assets/tips/ProfileInfo.svg',
    translation: 'tip5',
    id: 'tip5'
  },
  {
    photo: '/assets/tips/Abstract.svg',
    translation: 'tip6',
    id: 'tip6'
  },
  {
    photo: '/assets/tips/Report.svg',
    translation: 'tip7',
    id: 'tip7'
  },
  {
    photo: '/assets/tips/Development.svg',
    translation: 'tip8',
    id: 'tip8'
  },
  {
    photo: '/assets/tips/Report.svg',
    translation: 'tip9',
    id: 'tip9'
  },
  {
    photo: '/assets/tips/Teacher.svg',
    translation: 'tip10',
    id: 'tip10'
  },
  {
    photo: '/assets/tips/Call.svg',
    translation: 'tip11',
    id: 'tip11'
  },
  {
    photo: '/assets/tips/Schedule.svg',
    translation: 'tip12',
    id: 'tip12'
  },
  {
    photo: '/assets/tips/Time.svg',
    translation: 'tip13',
    id: 'tip13'
  },
  {
    photo: '/assets/tips/Teacher.svg',
    translation: 'tip14',
    id: 'tip14'
  }
]
