import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {
  AddCustomRewardModel,
  CustomRewardModel,
  GetCustomRewardModel,
  GetRewardsModel,
  LevelModel
} from "../models/indicators";

@Injectable({
  providedIn: 'root'
})
export class IndicatorsApiService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public obtainLevelsData$GET(): Observable<LevelModel[]> { //get levels params
    return this.http.get<LevelModel[]>(`/levels`);
  }

  public getRewards$GET(): Observable<CustomRewardModel[]>{ //get custom rewards list for manager
    return this.http.get<CustomRewardModel[]>('/rewards');
  }

  public addTeacherCustomReward$POST(teacherId: string, rewardId: number): Observable<AddCustomRewardModel>{
    return this.http.post<AddCustomRewardModel>(`/teacher-rewards/`, {teacherId, rewardId});
  }
  public removeTeacherCustomReward$DELETE(rewardTeacherConnectionId: number): Observable<unknown>{
    return this.http.delete<unknown>(`/teacher-rewards/${rewardTeacherConnectionId}`);
  }
  public getTeacherCustomRewards$GET(teacherId: number): Observable<GetCustomRewardModel>{
    return this.http.get<GetCustomRewardModel>(`/teacher-rewards/${teacherId}`);
  }

  public obtainTeacherRewards$GET(teacherId: string): Observable<GetRewardsModel>{//get teacher rewards for manager
    return this.http.get<{ data: GetRewardsModel }>(`/teacher-rewards/${teacherId}/all`).pipe(map(r => r.data
    ));
  }
  public updateTeacherFeedbacks(params: {teacherId: string; positive: number; negative: number}): Observable<void>{//update teacher`s data fot manager
    return this.http.put<void>(`/feedbacks`, {teacherId: params.teacherId, positive: params.positive, negative: params.negative});
  }
  public updateTeacherVerification(data: { teacherId: string, isVerified: boolean }): Observable<void> {
    return this.http.put<void>('/teachers/verification', data);
  }
  public updateTeacherRegulationViolation(params: {teacherId: string; violations: number}): Observable<void>{//update teacher`s data fot manager
    return this.http.put<void>(`/regulation-violations`, {teacherId: params.teacherId, violations: params.violations});
  }


  public getTeacherIncome$GET(teacherId: string, dateFrom: string, dateTo: string): Observable<unknown>{
    return this.http.get<unknown>(`/teachers/income/${teacherId}`, {params: {dateFrom, dateTo}});
  }

  public obtainTeacherFeedbacks$GET(): Observable<{ positive: number; negative: number; }> { //get feedbacks for teacher
    return this.http.get<{ positive: number; negative: number; }>(`/feedbacks`);
  }
  public obtainViolationsStats$GET(): Observable<{ violations: number; }> { //get violations for teacher
    return this.http.get<{ violations: number; }>(`/regulation-violations`);
  }
  public obtainRewards$GET(): Observable<GetRewardsModel> { //get rewards for teacher
    return this.http.get<GetRewardsModel>(`/teacher-rewards`);
  }
}
