import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mathema-some-loading-error',
  templateUrl: './some-loading-error.component.html',
  styleUrls: ['./some-loading-error.component.scss']
})
export class SomeLoadingErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
