
  <!-- <img
    class="close-icon"
    src="assets/close.svg"
    [mat-dialog-close]="true"
    alt=""
  /> -->
  <h2>{{'create-new-user' | translate}}</h2>

  <div class="block">
    <form [formGroup]="form" >
      <div class="form-group">
        <div class="one-items-block">
          <nz-select
            class="dropdown"
            nzPlaceHolder="{{'select-user-role' | translate}}"
            formControlName="roleId"
            nzNotFoundContent="{{'no-such-role' | translate}}"
            nzBorderless
          >
            <nz-option
              *ngFor="let option of generateUserRolesArray()"
              [nzLabel]="option.roleName === rolesEnum.TEACHER ? ('teacher' | translate) : option.roleName === rolesEnum.MANAGER ? ('manager' | translate) : ('admin' | translate)"
              [nzValue]="option.roleId"
            ></nz-option>
          </nz-select>
        </div>

        <div class="two-items-block" *ngIf="form.controls['roleId'].value === rolesEnum.MANAGER_ID || form.controls['roleId'].value === rolesEnum.ADMIN_ID">
          <div class="form-element-block">
            <label class="center-block">{{'name' | translate}}:</label>
            <input
              class="form-control"
              formControlName="firstName"
            />

            <div
              *ngIf="
                isSubmitted &&
                form.controls['firstName'].invalid &&
                form.controls['firstName'].touched
              "
              class="alert"
            >
              <div *ngIf="form.controls['firstName'].errors?.required">
                {{'can-not-be-empty' | translate}}
              </div>
            </div>
          </div>

          <div class="form-element-block">
            <label class="center-block">{{'email' | translate}}:</label>
            <input class="form-control" formControlName="email" />

            <div
              *ngIf="
                isSubmitted &&
                form.controls['email'].invalid &&
                form.controls['email'].touched
              "
              class="alert"
            >
              <div *ngIf="form.controls['email'].errors?.required">
                {{'can-not-be-empty' | translate}}
              </div>
              <div *ngIf="form.controls['email'].errors?.email">
                {{'wrong-email' | translate}}
              </div>
            </div>
          </div>
        </div>


        <div class="two-items-block" *ngIf="form.controls['roleId'].value === rolesEnum.MANAGER_ID || form.controls['roleId'].value === rolesEnum.ADMIN_ID">
          <div class="form-element-block">
            <label class="center-block">{{'surname' | translate}}:</label>
            <input
              class="form-control"
              formControlName="lastName"
            />

            <div
              *ngIf="
                isSubmitted &&
                form.controls['lastName'].invalid &&
                form.controls['lastName'].touched
              "
              class="alert"
            >
              <div *ngIf="form.controls['lastName'].errors?.required">
                {{'can-not-be-empty' | translate}}
              </div>
            </div>
          </div>

          <div class="form-element-block">
            <label class="center-block">{{'password' | translate}}:</label>
            <input
              (keydown.space)="$event.preventDefault()"
              class="form-control"
              formControlName="password"
            />

            <div
              *ngIf="
                isSubmitted &&
                form.controls['password'].invalid &&
                form.controls['password'].touched
              "
              class="alert"
            >
              <div *ngIf="form.controls['password'].errors?.required">
                {{'can-not-be-empty' | translate}}
              </div>

              <div *ngIf="form.controls['password'].errors?.minlength">
                {{'wrong-pass-length' | translate}}
              </div>
            </div>
          </div>
        </div>




        <div class="one-items-block teacher-select-block" *ngIf="form.controls['roleId'].value === rolesEnum.TEACHER_ID">
          <label class="left-block">{{'teacher-surn-name' | translate}}:</label>
          <nz-select
            class="dropdown select-padding"
            nzPlaceHolder="{{'select-teacher' | translate}}"
            formControlName="bitrixId"
            nzNotFoundContent="{{'no-such-teacher' | translate}}"
            nzShowSearch
            nzAllowClear
          >
            <nz-option
              *ngFor="let teacher of data.teachers"
              [nzLabel]="teacher.first_name + ' ' + teacher.last_name"
              [nzValue]="teacher.id"
            ></nz-option>
          </nz-select>

          <div class="teacher-password-block">
            <label class="left-block">{{'password' | translate}}: </label>
            <input
              placeholder="{{'new-teacher-pass' | translate}}"
              (keydown.space)="$event.preventDefault()"
              class="form-control"
              formControlName="password"
            />

            <div
              *ngIf="
                isSubmitted &&
                form.controls['password'].invalid &&
                form.controls['password'].touched
              "
              class="alert"
            >
              <div *ngIf="form.controls['password'].errors?.required">
                {{'can-not-be-empty' | translate}}
              </div>

              <div *ngIf="form.controls['password'].errors?.minlength">
                {{'wrong-pass-length' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="language-container" *ngIf="form.controls['roleId'].value">
        <nz-select
          class="dropdown"
          nzPlaceHolder="{{'chose-user-language' | translate}}"
          formControlName="language"
          nzBorderless
        >
          <nz-option
            *ngFor="let option of languageList"
            [nzLabel]="option.langName | translate"
            [nzValue]="option.value"
          ></nz-option>
        </nz-select>
        <div
          *ngIf="
                isSubmitted &&
                form.controls['language'].invalid &&
                form.controls['language'].touched
              "
          class="alert"
        >
          <div *ngIf="form.controls['language'].errors?.required">
            {{'can-not-be-empty' | translate}}
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="buttons-container" *ngIf="form.controls['roleId'].value">
    <button
      mat-button
      mat-raised-button
      color="primary"
      [disabled]="isButtonDisabled() || ((form.controls['roleId'].value === rolesEnum.TEACHER_ID && !form.controls['bitrixId'].value) || teacherNoEmail)"
      (click)="onCreateButtonClick()"
    >
      {{'create' | translate}}
    </button>
  </div>
