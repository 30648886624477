import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IGetTeacherSchema} from "../../change-user-info/models/change-user-info";

@Injectable({
  providedIn: 'root'
})
export class ViewUserInfoApiService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public getTeacherDataById$GET(teacherId: string): Observable<{data: IGetTeacherSchema}> {
    return this.http.get<{data: IGetTeacherSchema}>(`/teachers/${teacherId}`);
  }

  public updateUserPhoto$PATCH(teacherId: string, photoUrl: string): Observable<void> {
    return this.http.patch<void>(`/teachers/${teacherId}/updatePhoto`, {avatarLink: photoUrl});
  }

  public uploadAvatar$POST(file: FormData): Observable<{ avatarLink: string; }> {
    return this.http.post<{ avatarLink: string; }>('/file/photo', {file});
  }
}
