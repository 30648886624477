<div class="modal">
  <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate" *ngIf="isLoading"></nz-spin>
  <header>
    <h2>{{params.header}}</h2>
    <span class="close-icon" nz-icon (click)="onDecline.emit()">
      <img src="./assets/icons/cross.svg" alt="close">
    </span>
  </header>
  <section [innerHTML]="params.message"></section>
  <footer>
    <button class="decline" nz-button (click)="onDecline.emit()" *ngIf="params.declineText">
      {{params.declineText}}
    </button>
    <label nz-checkbox *ngIf="params.showNotification" [(ngModel)]="notificationNeeded" class="notif">{{'calendar.lesson-transfer.notification' | translate}}</label>
    <button class="accept" nz-button (click)="onAccept.emit()">
      {{params.acceptText}}
    </button>
  </footer>
</div>
