<div class="year">
  <ng-container *ngIf="calendarView$">
    <ng-container *ngIf="calendarView$ | async as months; else loadingOrError">
      <div class="month" *ngFor="let monthItem of months | keyvalue:sortObjectNumericKeys;">
        <div class="title" (click)="onMonthClick(monthItem.key)">{{formatMonth(monthItem.key) | titlecase}}</div>
        <div class="week-days">
          <div class="week-day" *ngFor="let index of weekDayIndexes">{{formatWeekDayByIdx(index) | titlecase}}</div>
        </div>
        <div class="days">
          <div
            class="day"
            *ngFor="let dayItem of monthItem.value | keyvalue:sortObjectNumericKeys; first as first"
            [ngStyle]="{'margin-left': first ? ('calc(100% / 7 * ' + monthItem.value['offset'] + ' )') : 0}"
            [class.hidden]="dayItem.key === 'offset'"
            [class.selected]="dayItem.value['date'] === selectedDayDate"
            [class.vacation]="dayItem.value['isVacation'] === true"
            (click)="toggleSelectedDay(dayItem.value['date'])"
            nz-tooltip
            [nzTooltipTitle]="'calendar.vacations.vacation-day' | translate"
            [nzTooltipTrigger]="dayItem.value['isVacation'] === true ? 'hover' : null"
          >
            <div *ngIf="dayItem.key !== 'offset'" class="day-number" [class.today]="dayItem.value['isToday'] === true">
              {{dayItem.key}}
            </div>
            <div class="day-details">
              <span
                class="lessons"
                [class.with-circles]="dayItem.value['regular'] || dayItem.value['first'] || dayItem.value['reserved'] || dayItem.value['slots']"
                *ngIf="dayItem.value['lessons']">{{dayItem.value['lessons']}}</span>
              <span class="circles">
                <span class="regular" *ngIf="dayItem.value['regular']"></span>
                <span class="first" *ngIf="dayItem.value['first']"></span>
                <span class="reserved" *ngIf="dayItem.value['reserved']"></span>
                <span class="slots" *ngIf="dayItem.value['slots']"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>


<ng-template #loadingOrError>
  <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>

