import { Component, Input } from '@angular/core';

@Component({
  selector: 'mathema-info-block',
  templateUrl: './mathema-info-block.component.html',
  styleUrls: ['./mathema-info-block.component.scss']
})
export class MathemaInfoBlockComponent {
  private colors = {
    info: '#43A047',
    warn: '#FFC368',
    error: '#E53935',
  }

  public type: 'info' | 'warn' | 'error' = 'info';
  public iconColor: string = this.colors[this.type];

  @Input() set _type(value: 'info' | 'warn' | 'error') {
    this.type = value;
    this.iconColor = this.colors[value];
  }
  @Input() text: string = '';

  constructor() { }

}
