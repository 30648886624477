<div class="loader" *ngIf="isLoading">
  <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
  <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
</div>

<div class="remove-free-slot-modal-wrap">
  <div class="header">
    <div>{{'calendar.remove-slot.free_slot_removal' | translate}}</div>
    <span nz-icon nzType="close" (click)="modalRef.close()"></span>
  </div>
  <div class="body">
    <div class="details">
      <div>
        <span class="label">{{'calendar.remove-slot.slot_datetime' | translate}}:</span>
        <span class="value"><span class="date">{{formatDate(freeSlotItem.date)}}</span> <span class="time">{{freeSlotItem.time}}</span></span>
      </div>
    </div>
    <div class="details" *ngIf="maxRepeatCount > 0">
      <div>
        <span class="label">{{'main.labels.repeat-count' | translate}}:</span>
        <nz-input-number
          style="width: 40%"
          (ngModelChange)="onChangeRepeatCount($event)"
          [ngModel]="repeatCount"
          [nzMin]="0"
          [nzMax]="maxRepeatCount"
          [nzStep]="1"></nz-input-number>
      </div>
      <div nz-tooltip nzTooltipTitle="{{'calendar.remove-slot.tooltips.slot-calculation-period' | translate}}">
        <span class="label">{{'main.labels.last-repeat' | translate}}:</span>
        <span class="value">{{formatDate(lastRepeatDate)}}</span>
      </div>
    </div>
    <div class="details">
      <div class="label">{{'calendar.remove-slot.time_to_remove' | translate}}:</div>
      <div class="time-controls">
        <nz-slider
          nzRange
          [nzMarks]="timeMarks"
          [(ngModel)]="timeRange"
          [nzTipFormatter]="formatTime"
          (ngModelChange)="onTimeRangeChange($event)"
          [nzMax]="maxSlotTime"
          [nzMin]="minSlotTime"></nz-slider>
        <nz-input-number-group nzCompact>
          <span>{{'from' | translate}}</span>
          <nz-input-number
            style="width: 40%"
            nzReadOnly
            (ngModelChange)="onTimeMinChange($event)"
            [nzFormatter]="formatTime"
            [(ngModel)]="timeMin"
            [nzMin]="minSlotTime"
            [nzMax]="timeMax"
            [nzStep]="1"></nz-input-number>
          <span>{{'to' | translate}}</span>
          <nz-input-number
            style="width: 40%"
            nzReadOnly
            (ngModelChange)="onTimeMaxChange($event)"
            [nzFormatter]="formatTime"
            [(ngModel)]="timeMax"
            [nzMin]="timeMin"
            [nzMax]="maxSlotTime"
            [nzStep]="1"></nz-input-number>
        </nz-input-number-group>
      </div>
    </div>
  </div>
  <div class="footer">
    <button nz-button class="cancel" (click)="modalRef.close()">{{'main.btn.decline' | translate}}</button>
    <button
      nz-button
      class="remove with-repeats"
      [disabled]="timeMin === timeMax || !repeatCount || isRemoveDisabled"
      (click)="onRemoveWithRepeats()"
      *ngIf="maxRepeatCount > 0">
      {{'main.btn.remove_with_repeats' | translate}}
    </button>
    <button nz-button class="remove" [disabled]="timeMin === timeMax || isRemoveDisabled" (click)="onRemoveWithoutRepeats()">
      {{'main.btn.remove' | translate}}
    </button>
  </div>
</div>
