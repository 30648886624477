import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RolesEnum, Student, Teacher } from '../../../../../shared';
import { LanguageEnum } from "../../../../../shared/constants";
import { AuthService } from "../../../auth/auth.service";
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ICreateManager, ICreateTeacher, UserService } from '../../services/user.service';

@Component({
  selector: 'create-user-modal',
  templateUrl: './create-user.modal.component.html',
  styleUrls: ['./create-user.modal.component.scss'],
})
export class CreateUserModalComponent implements OnInit, OnDestroy {
  public form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    roleId: ['', Validators.required],
    bitrixId: [''],
    language: ['', Validators.required]
  });

  public bitrixId: string;
  public teacherNoEmail: boolean;

  public teachers: any[] = [];
  public isSubmitted: boolean = false;
  public isExistTeacher: boolean = true;
  public subscriptions: Subscription = new Subscription();
  public languageList: { langName: string; value: string }[] = [
    {
      langName: 'en',
      value: LanguageEnum.english,
    },
    {
      langName: 'pl',
      value: LanguageEnum.polish,
    },
    {
      langName: 'uk',
      value: LanguageEnum.ukrainian,
    },
  ]
  public rolesEnum = RolesEnum;

  @Output() onUserCreated = new EventEmitter<void>();

  constructor(
    public fb: FormBuilder,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      roles: any[];
      teachers: any[];
    },
    protected translateService: TranslateService,
    private authService: AuthService,
    public readonly notificationService: NotificationService
  ) {
    this.disableFields(['firstName', 'lastName', 'email', 'password']);
    this.subscriptions.add(
      this.form.controls['roleId'].valueChanges.subscribe((val) => {
        this.isSubmitted = false;
        this.resetFieldsValue(['firstName', 'lastName', 'email', 'bitrixId']);
        if (val === RolesEnum.TEACHER_ID) {
          this.enableFields(['password']);
          this.disableFields(['firstName', 'lastName', 'email']);
        }
        if (val === RolesEnum.MANAGER_ID || val === RolesEnum.ADMIN_ID) {
          this.form.controls['lastName'].setValue('');
          this.form.controls['firstName'].setValue('');
          this.form.controls['email'].setValue('');
          this.enableFields(['firstName', 'lastName', 'email', 'password']);
        }
      })
    );

    this.subscriptions.add(
      this.form.controls['bitrixId'].valueChanges.subscribe((val) => {
        let teacher = this.data.teachers.find((teacher) => teacher.id === val);
        this.isExistTeacher = !!teacher;
        this.form.controls['lastName'].setValue(teacher?.last_name || '');
        this.form.controls['firstName'].setValue(teacher?.name || '');
        this.form.controls['email'].setValue(teacher?.email || '');
        this.teacherNoEmail = false;
        if (this.form.controls['bitrixId'].value && (!this.form.controls['email'].value || this.form.controls['email'].value === 'null')) {
          this.notificationService.showErrorMessage(this.translateService.instant('no-email-in-teacher-account'));
          this.teacherNoEmail = true;
        }
      })
    );
  }

  ngOnInit() {
    // this.form.controls['bitrixId'].valueChanges.subscribe(()=>{
    //   console.log('bitrixId changed onInit');
    //   this.findAndSelectTeacher();
    // })
    this.form.controls['roleId'].valueChanges.subscribe(() => {
      this.form.controls['language'].reset()
    })

  }

  sortByAbc(data: Student[] | Teacher[]): Student[] | Teacher[] {
    const obtData: Student[] | Teacher[] = JSON.parse(JSON.stringify(data));
    const sorted = obtData.sort((a, b) => {
      if (a.last_name < b.last_name) {
        return -1
      }
      if (a.last_name > b.last_name) {
        return 1
      }
      return 0
    })
    return sorted.map((item) => {
      return { ...item, last_name: item.last_name && item.last_name !== 'null' && item.last_name !== null ? item.last_name.trim() : '', name: item.first_name && item.first_name !== 'null' && item.first_name !== null ? item.first_name.trim() : '' }
    })
  }
  onCreateButtonClick() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field) as FormControl;
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty();
      });
      return;
    }
    if (
      this.form.controls['roleId'].value === RolesEnum.TEACHER_ID &&
      !this.isExistTeacher
    ) {
      return;
    }

    let options;
    const data = this.form.value
    if (data.roleId === RolesEnum.TEACHER_ID) {
      options = {
        teacherId: data.bitrixId.toString(),
        password: data.password,
        language: data.language,
        role: data.roleId,
      } as ICreateTeacher;
    } else {
      options = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        language: data.language,
        role: data.roleId,
      } as ICreateManager;
    }

    this.userService.createUser(options).toPromise().then(() => {
      this.onUserCreated.emit();
    });
  }

  isButtonDisabled() {
    return false;
  }

  disableFields(array: string[]) {
    for (let item of array) {
      this.form.controls[item].disable();
    }
  }
  enableFields(array: string[]) {
    for (let item of array) {
      this.form.controls[item].enable();
    }
  }
  resetFieldsValue(array: string[]) {
    for (let item of array) {
      this.form.controls[item].setValue('');
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  findAndSelectTeacher() {
    let teacher: { first_name?: string; last_name?: string; email?: string; id?: string } = {};
    for (let teacherItem of this.data.teachers) {
      if (teacherItem.id === this.form.controls['bitrixId'].value) {
        teacher = teacherItem as { first_name: string; last_name: string; email: string; id: string };
      }
    }
    this.form.controls['firstName'].setValue(teacher.first_name);
    this.form.controls['lastName'].setValue(teacher.last_name);
    this.form.controls['email'].setValue(teacher.email);
    this.teacherNoEmail = false;
    if (this.form.controls['bitrixId'].value && (!this.form.controls['email'].value || this.form.controls['email'].value === 'null')) {
      this.notificationService.showErrorMessage(this.translateService.instant('no-email-in-teacher-account'));
      this.teacherNoEmail = true;
    }
  }

  public generateUserRolesArray(): { roleName: string; roleId: string }[] {
    if (this.authService.isAdmin()) {
      return [
        { roleName: RolesEnum.MANAGER, roleId: '741e4614-ed07-49cf-899e-f4b4da1e564a' },
        { roleName: RolesEnum.TEACHER, roleId: '2c13b353-6769-4f8d-bd26-4a7a224790f5' },
        { roleName: RolesEnum.ADMIN, roleId: '61e8e9c1-6d6c-4611-a5d9-254864b78c7f' },
      ]
    } else {
      return [
        { roleName: RolesEnum.MANAGER, roleId: '741e4614-ed07-49cf-899e-f4b4da1e564a' },
        { roleName: RolesEnum.TEACHER, roleId: '2c13b353-6769-4f8d-bd26-4a7a224790f5' },
      ]
    }
  }
}
