<div class="diapason" *ngIf="desktopMode; else mobileDiapasonSwitch">
  <ng-container [ngSwitch]="diapasonSwitchState.type">
    <section *ngSwitchCase="calendarTypes.YEAR" class="year">
      <ng-template [ngTemplateOutlet]="switcher"></ng-template>
    </section>
    <section *ngSwitchCase="calendarTypes.MONTH" class="month">
      <ng-template [ngTemplateOutlet]="switcher"></ng-template>
    </section>
    <section *ngSwitchCase="calendarTypes.WEEK" class="week">
      <ng-template [ngTemplateOutlet]="switcher"></ng-template>
    </section>
  </ng-container>
</div>

<ng-template #mobileDiapasonSwitch>
  <div class="diapason-mobile">
    <ng-container [ngSwitch]="diapasonSwitchState.type">
      <section *ngSwitchCase="calendarTypes.YEAR" class="year">
        <ng-template [ngTemplateOutlet]="switcher"></ng-template>
      </section>
      <section *ngSwitchCase="calendarTypes.MONTH" class="month">
        <ng-template [ngTemplateOutlet]="switcher"></ng-template>
      </section>
      <section *ngSwitchCase="calendarTypes.DAY" class="day">
        <ng-template [ngTemplateOutlet]="switcher"></ng-template>
      </section>
    </ng-container>
  </div>
</ng-template>

<ng-template #switcher>
  <button
    class="switch-btn left"
    (click)="setPastActiveRange()"
  ><span nz-icon [nzRotate]="-90"><img src="./assets/icons/chevrone.svg" alt="left"></span></button>
  <span
    *ngFor="let range of diapasonSwitchState.ranges, index as idx"
    class="range"
    [class.active]="range.isActive"
    (click)="setActiveRangeByIndex(idx)"
  >{{range.label}} <span class="sublabel" *ngIf="!desktopMode && range.sublabel">{{range.sublabel}}</span></span>
  <button
    class="switch-btn"
    (click)="setNextActiveRange()"
  ><span nz-icon [nzRotate]="90"><img src="./assets/icons/chevrone.svg" alt="right"></span></button>
  <button
    class="switch-btn reload right"
    (click)="scheduleCalendarService.refreshCalendarDate()"
  ><span nz-icon><img src="./assets/icons/reload.svg" alt="reload" class="reload"></span></button>
</ng-template>
