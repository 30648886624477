import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-inform',
  templateUrl: './modal-inform.component.html',
  styleUrls: ['./modal-inform.component.scss'],
})
export class ModalInformComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      subTitle: string;
      confirmText: string;
      cancelText: string;
      onConfirm: () => void;
      onCancel: () => void;
    }
  ) {}
}
