<div class="remove-reservation-modal-wrap">
  <div class="header">
    <div>{{'calendar.remove-reservation.removal' | translate}}</div>
    <span nz-icon nzType="close" (click)="modalRef.close()"></span>
  </div>
  <div class="body">
    <div class="lesson-details">
      <div>
        <span class="label">{{'calendar.remove-reservation.lesson_datetime' | translate}}:</span>
        <span class="value"><span class="date">{{formatDate(reservationItem.date)}}</span> <span class="time">{{reservationItem.time}}</span></span>
      </div>
    </div>
    <div class="lesson-details">
      <div>
        <span class="label">{{'main.labels.repeat-count' | translate}}:</span>
        <span class="value">{{repeatCount}}</span>
      </div>
      <div>
        <span class="label">{{'main.labels.last-repeat' | translate}}:</span>
        <span class="value">{{formatDate(lastRepeatDate)}}</span>
      </div>
    </div>

  </div>
  <div class="footer">
    <button nz-button class="cancel" (click)="modalRef.close()">{{'main.btn.decline' | translate}}</button>
    <button nz-button class="remove-with-repeats" *ngIf="repeatCount > 0" (click)="onRemoveWithRepeats()">{{'main.btn.remove_with_repeats' | translate}}</button>
    <button nz-button class="remove" (click)="onRemoveWithoutRepeats()">{{'main.btn.remove' | translate}}</button>
  </div>
</div>

