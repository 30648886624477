<div *ngIf="isLoading else content" class="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-template #content>
  <div class="content-wrapper">
    <div class="header-wrapper">
      <div class="main-menu">
        <mathema-main-menu></mathema-main-menu>
      </div>
      <div class="titles">
        <div class="nav-button" (click)="handleBackClick()" *ngIf="!authService.isTeacher()">
          <mat-icon>keyboard_backspace</mat-icon>
          {{'search-available-hours' | translate}}
        </div>
        <div class="header">
          {{!authService.isTeacher() ? ('teacher_cabinet' | translate) : ('my_cabinet' | translate)}}
        </div>
      </div>
    </div>
    <div class="content" *ngIf="!getEditIndicatorsState() else changeIndicatorsPage">
      <div class="left-bar">
        <app-button (btnClick)="openSettingsModal()" class="change-photo" *ngIf="authService.isTeacher()"
                    [buttonType]="'invincibleButton'" icon="settings"></app-button>
        <div class="section">
          <div class="avatar">
            <img [src]="getUserAvatarLink()" alt="user avatar" *ngIf="getUserAvatarLink() else noAvatar">
            <img
              *ngIf="(authService.isUserUkrainian() || authService.isUserPoland()) && isVerified.value"
              src="../../../../../assets/icons/verified.svg"
              alt="verified"
              class="verified-icon"
              [nz-tooltip]="'verification_description' | translate"
            >
            <ng-template #noAvatar>
              {{getUserAvatarLetter()}}
            </ng-template>
          </div>
          <div class="name-container">
            <span class="first-name"
                  [class.no-data-text]="!getField(userInfo?.firstName)">{{getField(userInfo?.firstName) ? getField(userInfo?.firstName) : ('no-data-n' | translate) + '!'}}</span>
            <span class="last-name"
                  [class.no-data-text]="!getField(userInfo?.lastName)">{{getField(userInfo?.lastName) ? getField(userInfo?.lastName) : 'no-data-n' | translate}}</span>
          </div>
          <div class="teacher-status"
               [class.no-data-text]="!getField(userInfo?.status)"
               [ngClass]="{
               trainee: userInfo?.status === 'trainee',
               regular: userInfo?.status === 'regular',
               resigns: userInfo?.status === 'resigns',
               resigned: userInfo?.status === 'resigned'
               }"
          >{{userInfo?.status | translate}}</div>
        </div>
        <div class="section">
          <div class="country" *ngIf="getCountryName() as country">
            <span class="fi fi-{{country.id}}"></span> <span
            class="country-name">{{country.name}}</span>
          </div>
          <div class="birthday-date">
            <ng-container *ngIf="userInfo?.dateOfBirth; else noDateOfBirth">
              <ng-container *ngIf="getDateWithAge(userInfo?.dateOfBirth) as dateAndAge">
                <mat-icon>cake</mat-icon>
                <span class="date">{{dateAndAge.date}}</span>
                <span class="age">({{dateAndAge.age}} {{'years-short' | translate}})</span>
              </ng-container>
            </ng-container>
            <ng-template #noDateOfBirth>
              <span class="date no-data-text">{{'no-info' | translate}}</span>
            </ng-template>
          </div>
        </div>
        <div class="section">
          <div class="parameter">
            <div class="section-name-container">
              <mat-icon>
                switch_access_shortcut_add
              </mat-icon>
              <div class="name">{{'conversion-value' | translate}}</div>
            </div>
            <div class="value"
              *ngIf="userInfo?.conversionValue !== -1"
              [ngClass]="{red: userInfo?.conversionValue < 30, yellow: userInfo?.conversionValue >=30 && userInfo?.conversionValue <=40, green: userInfo?.conversionValue > 40}">
              {{getPrecisionedNumber(userInfo?.conversionValue)}}%
            </div>
            <div class="value"
              *ngIf="userInfo?.conversionValue === -1">
              -
            </div>
          </div>
          <div class="parameter">
            <div class="section-name-container">
              <mat-icon>insert_chart</mat-icon>
              <div class="name">{{'load' | translate}}</div>
            </div>
            <div class="value"
                 [class.no-data-text]="!getField(userInfo?.teacherWorkLoad)">{{getField(userInfo?.teacherWorkLoad) ? (getField(userInfo?.teacherWorkLoad) + '%') : 'no-data-f' | translate}}</div>
          </div>
          <div class="parameter">
            <div class="section-name-container">
              <mat-icon>group</mat-icon>
              <div class="name">{{'number_of_active_students' | translate}}</div>
            </div>
            <div class="value"
                 [class.no-data-text]="!getField(userInfo?.activeStudents)">{{getField(userInfo?.activeStudents) ? getField(userInfo?.activeStudents) : 'no-data-f' | translate}}</div>
          </div>
          <div class="parameter">
            <div class="section-name-container">
              <mat-icon>contact_phone</mat-icon>
              <div class="name">{{'completed_lessons' | translate}}</div>
            </div>
            <div class="value"
                 [class.no-data-text]="!getField(userInfo?.lessons)">{{getField(userInfo?.lessons) ? getField(userInfo?.lessons) : 'no-data-n' | translate}}</div>
          </div>
        </div>
        <div class="section contacts">
          <div class="contact-container">
            <div class="label">{{'e_mail' | translate}}:</div>
            <a class="email" [class.no-email]="!getField(userInfo?.email)" [href]="'mailto:'+ getField(userInfo?.email)"
               target="_blank">
              <span
                [class.no-data-text]="!getField(userInfo?.email)">{{getField(userInfo?.email) ? getField(userInfo?.email) : 'no-data-m' | translate}}</span>
            </a>
          </div>
          <div class="contact-container">
            <div class="label">{{'mobile-number' | translate}}:</div>
            <div class="content"
                 [class.no-data-text]="!getField(userInfo?.phone)">{{getField(userInfo?.phone) ? convertPhoneNumberToValidView(userInfo?.phone) : 'no-data-m' | translate}}</div>
          </div>
        </div>

        <app-button class="change-button" buttonType="colourfulButtonWithFullWidth"
                    [text]="'edit' | translate" (btnClick)="handleChangeBtnClick()"></app-button>
      </div>

      <mat-tab-group (selectedTabChange)="changeMenuPage($event)" #tabs class="tabs">
        <mat-tab
          label="{{authService.isTeacher()? ('info_about_myself' | translate) : ('profile' | translate)}}">
          <div class="about-me">
            <div class="column-1">
              <div class="content">
                <div class="section work-specifications">
                  <div class="section-header">
                    <mat-icon>summarize</mat-icon>
                    <span class="section-name">
                  {{'work_specification' | translate}}
                </span>
                  </div>
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'tutor_experience' | translate}}:</div>
                      <div class="info-content"
                           [class.no-data-text]="!getField(userInfo?.tutoringExperience)">{{getField(userInfo?.tutoringExperience) ? (userInfo?.tutoringExperience | translate) : ('no-data-m' | translate)}}</div>
                    </div>
                    <div class="info-with-label">
                      <div class="label">{{'study_experience' | translate}}:</div>
                      <div class="info-content"
                           [class.no-data-text]="!getField(userInfo?.experience)">{{getField(userInfo?.experience) ? (userInfo?.experience | translate) : ('no-data-m' | translate)}}</div>
                    </div>
                  </div>
                </div>
                <div class="section grades">
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'working_grades' | translate}}:</div>
                      <div class="info-content">
                        <div class="grades-container"
                             *ngIf="userInfo?.workingClass && userInfo?.workingClass.length>0 else noGradesData">
                          <div class="grades" *ngFor="let grade of sortGrades(userInfo?.workingClass)">
                            {{grade | translate}}
                          </div>
                        </div>
                        <ng-template #noGradesData>
                      <span class="no-data-text">
                        {{'no-data-n' | translate}}
                      </span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="info-content margin-32">
                    <div class="info-with-label">
                      <div class="label">{{'preparing_to_exams' | translate}}:</div>
                      <div class="info-content">
                        <div *ngIf="userInfo?.examPreparation && userInfo?.examPreparation.length>0 else noExamData"
                             style="display: flex; flex-wrap: wrap; margin-bottom: -8px">
                          <div class="exams" *ngFor="let exam of userInfo?.examPreparation; index as i">
                            <div class="name color-{{i+1}}"
                                 [class.color-other]="exam.name === 'other'">{{exam.name | translate}}</div>
                            <div class="exp color-{{i+1}}"
                                 *ngIf="exam.name !== 'other' else examPrepOther">{{exam.expirience}} {{'years' | translate}}</div>
                            <ng-template #examPrepOther>
                              <div class="exp color-other">{{exam.expirience}}</div>
                            </ng-template>
                          </div>
                        </div>
                        <ng-template #noExamData>
                      <span class="no-data-text">
                        {{'no-data-n' | translate}}
                      </span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="info-content margin-32">
                    <div class="info-with-label">
                      <div class="label">{{'study_programs' | translate}}:</div>
                      <div class="info-content">
                        <div class="study-programs-container"
                             *ngIf="(userInfo?.educationProgram && userInfo?.educationProgram.length>0) || (userInfo?.educationProgramOther && userInfo?.educationProgramOther.length>0) else noProgramData">
                          <div class="study-programs" *ngFor="let program of userInfo?.educationProgram">
                            {{program | translate}}
                          </div>
                          <div class="study-programs" *ngIf="userInfo?.educationProgramOther">
                            {{'other' | translate}} ({{userInfo?.educationProgramOther}})
                          </div>
                        </div>
                        <ng-template #noProgramData>
                      <span class="no-data-text">
                        {{'no-data-n' | translate}}
                      </span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section language">
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'study_language' | translate}}:</div>
                      <div class="info-content" *ngIf="userInfo?.lang else noStudyLang">
                        <div class="lang" *ngFor="let item of generateLanguages(userInfo?.lang)">
                          <span *ngIf="getLangName(item).flagId && getLangName(item).flagId.length>0" class="fi fi-{{getLangName(item).flagId}}"></span>
                          {{getLangName(item).name | translate}}
                        </div>
                        <span *ngIf="userInfo?.lang.includes('other')">&nbsp;{{'other' | translate}}({{userInfo?.langOther}})</span>
                      </div>
                      <ng-template #noStudyLang>
                        <span class="no-data-text">
                          {{'no-data-n' | translate}}
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <div class="info-content" style="padding-top: 11px;">
                    <div class="info-with-label">
                      <div class="label">{{'additional_jubjects' | translate}}:</div>
                      <div class="info-content">
                        <div class="content-with-coma-container">
                          <div
                            *ngIf="userInfo?.additionalSubject && userInfo?.additionalSubject.length>0 else noAdditionalSubjects">
                            <div class="content-with-coma" *ngFor="let subj of userInfo?.additionalSubject">
                              {{getAdditionalSubjectsKeys(subj) | translate}}
                              <div class="coma">,</div>
                            </div>
                          </div>
                          <ng-template #noAdditionalSubjects>
                        <span class="no-data-text">
                          {{'no-data-n' | translate}}
                        </span>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                    <div class="info-with-label">
                        <div class="label">{{'study_style' | translate}}:</div>
                        <div class="info-content"
                              [class.no-data-text]="!userInfo?.educationStyle">{{userInfo?.educationStyle ? (userInfo?.educationStyle | translate) : 'no-data-m' | translate}}
                        </div>
                    </div>
                    <div class="info-with-label">
                        <div class="label">{{'depth_study' | translate}}:</div>
                        <div class="info-content">
                          <div class="boolean-container">
                            <mat-icon
                              class="{{userInfo?.isDepthStudy ?
                          'is-depth' :
                          'no-depth'}}">
                              {{userInfo?.isDepthStudy ? 'done' : 'close'}}
                            </mat-icon>
                            <span class="boolean">
                          {{userInfo?.isDepthStudy ? ('yes' | translate) : ('no' | translate)}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section no-border special-need">
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'special_needs' | translate}}:</div>
                      <div class="info-content">
                        <div class="content-with-coma-container">
                          <div
                            *ngIf="(userInfo?.childSpecialNeeds && userInfo?.childSpecialNeeds.length>0) else noSpesialNeeds">
                            <div class="content-with-coma" *ngFor="let spec of userInfo?.childSpecialNeeds">
                              {{spec | translate}}
                              <div class="coma">,</div>
                            </div>
                          </div>

                          <ng-template #noSpesialNeeds>
                        <span class="no-data-text">
                          {{'no-data-n' | translate}}
                        </span>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-content margin-32">
                    <div class="info-with-label">
                      <div class="label">{{'tools_for_studing' | translate}}:</div>
                      <div class="info-content">
                        <div class="data-with-icons-container tools-for-studing"
                             *ngIf="(userInfo?.teacherInstruments && userInfo?.teacherInstruments.length) || (userInfo?.instrumentsOther && userInfo?.instrumentsOther.length > 0) else noInstrumentsContainer">
                          <div class="item" *ngFor="let item of userInfo?.teacherInstruments">
                            <mat-icon *ngIf="getInstrumentData(item).icon">{{getInstrumentData(item).icon}}</mat-icon>
                            <span class="name">{{getInstrumentData(item).label | translate}}</span>
                          </div>
                          <div class="item" *ngIf="userInfo?.instrumentsOther">
                            <mat-icon>square_foot</mat-icon>
                            <span class="name">{{'other' | translate}} ({{userInfo?.instrumentsOther}})</span>
                          </div>
                        </div>
                        <ng-template #noInstrumentsContainer>
                      <span class="no-data-text">
                        {{'no-data-n' | translate}}
                      </span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="change-btn" (click)="handleChangeBtnClick(pagesNames.WORK_SPECIFICATION)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>


            <div class="column-2">
              <div class="content education">
                <div class="section-header">
                  <mat-icon>school</mat-icon>
                  <span class="section-name">
                  {{'education' | translate}}
                </span>
                </div>

                <div class="data adjustable-grid">
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'education' | translate}}:</div>
                      <div class="info-content-data"
                           [class.no-data-text]="!userInfo?.education">{{userInfo?.education ? userInfo?.education : 'no-data-f' | translate}}</div>
                    </div>
                  </div>
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'second_education' | translate}}:</div>
                      <div class="info-content-data"
                           [class.no-data-text]="!userInfo?.additionalEducation">{{userInfo?.additionalEducation ? userInfo?.additionalEducation : 'no-data-f' | translate}}</div>
                    </div>
                  </div>
                </div>
                <div class="data">
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'teacher_dyploma' | translate}}:</div>
                      <div class="info-content-data">
                        <div class="boolean-container">
                          <mat-icon
                            class="{{userInfo?.diploma ?
                      'is-depth' :
                      'no-depth'}}">
                            {{userInfo?.diploma ? 'done' : 'close'}}
                          </mat-icon>
                          <span class="boolean">
                      {{userInfo?.diploma ? ('yes' | translate) : ('no' | translate)}}
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'load_dyploma' | translate}}:</div>
                      <div class="info-content-data diploma-link" [class.no-data-text]="!userInfo?.diplomaFileName">
                        <a [href]="userInfo?.diplomaLink" *ngIf="userInfo?.diplomaLink else noDiplomaFileName">
                          <mat-icon>attach_file</mat-icon>
                          <span>{{userInfo?.diplomaFileName}}</span>

                        </a>
                        <ng-template #noDiplomaFileName>
                          {{'no-data-m' | translate}}
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'teacher_qualification' | translate}}:</div>
                      <div class="info-content-data"
                           [class.no-data-text]="!userInfo?.qualification">{{userInfo?.qualification ? ((userInfo?.qualification | translate) + (userInfo?.qualificationOther ? ' (' + userInfo?.qualificationOther + ')' : '')) : ('no-data-f' | translate)}}</div>
                    </div>
                  </div>
                </div>

                <button class="change-btn" (click)="handleChangeBtnClick(pagesNames.EDUCATION)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>


              <div class="content experience">
                <div class="section-header">
                  <mat-icon>work</mat-icon>
                  <span class="section-name">
                  {{'experience' | translate}}
                </span>
                </div>

                <div class="data">
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'current_work_place' | translate}}:</div>
                      <div class="info-content-data"
                           [class.no-data-text]="!userInfo?.currentWorkPlace">{{userInfo?.currentWorkPlace ? userInfo?.currentWorkPlace : 'no-data-n' | translate}}</div>
                    </div>
                  </div>
                  <div class="info-content">
                    <div class="info-with-label">
                      <div class="label">{{'previous_work_place' | translate}}:</div>
                      <div class="info-content-data">
                        <div class="content-with-coma-container">
                          <div
                            *ngIf="(userInfo?.previousWorkPlace && userInfo?.previousWorkPlace.length) else noPreviousWorkPlaces">
                            <div class="content-with-coma" *ngFor="let subj of userInfo?.previousWorkPlace">
                              {{subj | translate}}
                              <div class="coma">,</div>
                            </div>
                          </div>
                          <ng-template #noPreviousWorkPlaces>
                        <span class="no-data-text">
                          {{'no-data-n' | translate}}
                        </span>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <button class="change-btn" (click)="handleChangeBtnClick(pagesNames.EXPERIENCE)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>


              <div class="content zoom">
                <div class="section-header">
                  <mat-icon>videocam</mat-icon>
                  <span class="section-name">
                  {{'lesson-link' | translate}}
                </span>
                </div>

                <div class="data">
                  <div class="row">
                    <div class="info-content">
                      <div class="info-with-label">
                        <div class="label">{{'link' | translate}}:</div>
                        <div class="info-content-data zoom-link" [class.active]="userInfo?.linkToZoom"
                             [class.no-data-text]="!userInfo?.linkToZoom">{{userInfo?.linkToZoom ? userInfo?.linkToZoom : 'no-data-n' | translate}}
                          <mat-icon *ngIf="userInfo?.linkToZoom" [cdkCopyToClipboard]="zoomLink"
                                    (click)="handleCopyBtnClick()">content_copy
                          </mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid" *ngIf="isZoomLink(userInfo?.linkToZoom)">
                    <div class="info-content">
                      <div class="info-with-label">
                        <div class="label">{{'access_code' | translate}}:</div>
                        <div class="info-content-data"
                             [class.no-data-text]="!userInfo?.zoomCode">{{userInfo?.zoomCode ? userInfo?.zoomCode : 'no-data-m' | translate}}</div>
                      </div>
                    </div>
                    <div class="info-content">
                      <div class="info-with-label">
                        <div class="label">{{'zoom_id' | translate}}:</div>
                        <div class="info-content-data"
                             [class.no-data-text]="!userInfo?.zoomIdentificator">{{userInfo?.zoomIdentificator ? userInfo?.zoomIdentificator : 'no-data-m' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="change-btn" (click)="handleChangeBtnClick(pagesNames.ZOOM)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>


              <div class="content additional">
                <div class="section-header">
                  <mat-icon>link</mat-icon>
                  <span class="section-name">
                  {{'additional' | translate}}
                </span>
                </div>

                <div class="data">
                  <div class="row">
                    <div class="info-content">
                      <div class="info-with-label">
                        <div class="label">{{'digital_resources' | translate}}:</div>
                        <div class="info-content-data"
                             [class.no-data-text]="!userInfo?.description">{{userInfo?.description ? userInfo?.description : 'no-data-n' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="change-btn" (click)="handleChangeBtnClick(pagesNames.ADDITIONAL)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab
          label="{{authService.isTeacher()? ('my_indicators' | translate) : ('indicators' | translate)}}">
          <div class="indicators">
            <app-indicators style="width: 100%; height: 100%" [obtData]="obtData" [userLevels]="userLevels"
                            [lessonsPassed]="userInfo?.lessons || 0" [isTeacher]="!!(authService.user.teacherId)"
                            (changeButtonClick)="clickIndicatorsChangeBtn()"></app-indicators>
          </div>
        </mat-tab>
        <mat-tab [label]="'my-library-tab-label' | translate" *ngIf="authService.isTeacher() && authService.isUserUkrainian()">
          <div class="my-materials">
            <div class="materials">
              <img src="../../../../../assets/view-docs.png" alt="documents">
              <button mat-raised-button color="primary" class="load-materials" (click)="showLoadMaterialsButton=false" *ngIf="showLoadMaterialsButton">
                {{'my-library-upload-lib-btn' | translate}}
              </button>
              <div class="thank" *ngIf="!showLoadMaterialsButton">
                <p><b>{{'my-library-temp-thank-you' | translate}}</b></p>
                <p>{{'my-library-temp-explain' | translate}}</p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <ng-template #changeIndicatorsPage>
      <div class="content">
        <div class="left-bar" style="height: 745px">
          <app-button (btnClick)="openSettingsModal()" class="change-photo" *ngIf="authService.isTeacher()"
                      [buttonType]="'invincibleButton'" icon="settings"></app-button>
          <div class="section">
            <div class="avatar">
              <img [src]="getUserAvatarLink()" alt="user avatar" *ngIf="getUserAvatarLink() else noAvatar">
              <img
                *ngIf="(authService.isUserUkrainian() || authService.isUserPoland()) && isVerified.value"
                src="../../../../../assets/icons/verified.svg"
                alt="verified"
                class="verified-icon"
                [nz-tooltip]="'verification_description' | translate"
              >
              <ng-template #noAvatar>
                {{getUserAvatarLetter()}}
              </ng-template>
            </div>
            <div class="name-container">
              <span class="first-name"
                    [class.no-data-text]="!getField(userInfo?.firstName)">{{getField(userInfo?.firstName) ? getField(userInfo?.firstName) : ('no-data-n' | translate) + '!'}}</span>
              <span class="last-name"
                    [class.no-data-text]="!getField(userInfo?.lastName)">{{getField(userInfo?.lastName) ? getField(userInfo?.lastName) : 'no-data-n' | translate}}</span>
            </div>
            <div class="teacher-status"
                 [class.no-data-text]="!getField(userInfo?.status)"
                 [ngClass]="{
               trainee: userInfo?.status === 'trainee',
               regular: userInfo?.status === 'regular',
               resigns: userInfo?.status === 'resigns',
               resigned: userInfo?.status === 'resigned'
               }"
            >{{userInfo?.status | translate}}</div>
          </div>
          <div class="section">
            <div class="country">
              <span class="fi fi-{{getCountryName().id}}"></span> <span
              class="country-name">{{getCountryName().name}}</span>
            </div>
            <div class="birthday-date">
              <mat-icon>cake</mat-icon>
              <span class="date"
                    [class.no-data-text]="!getDateWithAge(userInfo?.dateOfBirth)">{{getDateWithAge(userInfo?.dateOfBirth).date}}</span><span
              class="age">({{getDateWithAge(userInfo?.dateOfBirth).age}} {{'years-short' | translate}})</span>
            </div>
          </div>
          <div class="section">
            <div class="parameter">
              <div class="section-name-container">
                <mat-icon>insert_chart</mat-icon>
                <div class="name">{{'load' | translate}}</div>
              </div>
              <div class="value"
                   [class.no-data-text]="!getField(userInfo?.teacherWorkLoad)">{{getField(userInfo?.teacherWorkLoad) ? (getField(userInfo?.teacherWorkLoad) + '%') : 'no-data-f' | translate}}</div>
            </div>
            <div class="parameter">
              <div class="section-name-container">
                <mat-icon>group</mat-icon>
                <div class="name">{{'number_of_active_students' | translate}}</div>
              </div>
              <div class="value"
                   [class.no-data-text]="!getField(userInfo?.activeStudents)">{{getField(userInfo?.activeStudents) ? getField(userInfo?.activeStudents) : 'no-data-f' | translate}}</div>
            </div>
            <div class="parameter">
              <div class="section-name-container">
                <mat-icon>contact_phone</mat-icon>
                <div class="name">{{'completed_lessons' | translate}}</div>
              </div>
              <div class="value"
                   [class.no-data-text]="!getField(userInfo?.lessons)">{{getField(userInfo?.lessons) ? getField(userInfo?.lessons) : 'no-data-n' | translate}}</div>
            </div>
          </div>
          <div class="section contacts">
            <div class="contact-container">
              <div class="label">{{'e_mail' | translate}}:</div>
              <a class="email" [class.no-email]="!getField(userInfo?.email)" [href]="'mailto:'+ getField(userInfo?.email)"
                 target="_blank">
                <span
                  [class.no-data-text]="!getField(userInfo?.email)">{{getField(userInfo?.email) ? getField(userInfo?.email) : 'no-data-m' | translate}}</span>
              </a>
            </div>
            <div class="contact-container">
              <div class="label">{{'mobile-number' | translate}}:</div>
              <div class="content"
                   [class.no-data-text]="!getField(userInfo?.phone)">{{getField(userInfo?.phone) ? convertPhoneNumberToValidView(userInfo?.phone) : 'no-data-m' | translate}}</div>
            </div>
          </div>

          <app-button *ngIf="!authService.isTeacher()" class="change-button" buttonType="colourfulButtonWithFullWidth"
                      [text]="'edit' | translate" (btnClick)="handleChangeBtnClick()"></app-button>
        </div>

        <div class="change-indicators-page">
          <div class="title">{{'indicators' | translate}}</div>
          <div class="main-part">
            <div class="statistic">
              <div class="group-title">
                {{'statistics' | translate}}
              </div>
              <div class="statistic-group">
                <div class="statistic-item">
                  <div class="group-title statistic-item-title">{{'regulation_violations' | translate}}
                    ({{'amount' | translate}}):
                  </div>
                  <app-input
                    [max]="initialSettings.regulationViolationMaxValue"
                    inputType="number"
                    [formControl]="regulationViolation"
                    [error]="regulationViolation.touched && regulationViolation.invalid"
                    [hideLabel]="true"
                    [customInputParams]="{customWidth: '88px'}"
                  ></app-input>
                </div>
                <div class="statistic-item">
                  <div class="group-title statistic-item-title">{{'positive_feedbacks' | translate}}
                    ({{'amount' | translate}}):
                  </div>
                  <app-input
                    [max]="initialSettings.positiveResponsesMaxValue"
                    inputType="number"
                    [formControl]="positiveFeedback"
                    [error]="positiveFeedback.touched && positiveFeedback.invalid"
                    [hideLabel]="true"
                    [customInputParams]="{customWidth: '88px'}"
                  ></app-input>
                </div>
                <div class="statistic-item">
                  <div class="group-title statistic-item-title">{{'negative_feedbacks' | translate}}
                    ({{'amount' | translate}}):
                  </div>
                  <app-input
                    [max]="initialSettings.negativeResponsesMaxValue"
                    inputType="number"
                    [formControl]="negativeFeedback"
                    [error]="negativeFeedback.touched && negativeFeedback.invalid"
                    [hideLabel]="true"
                    [customInputParams]="{customWidth: '88px'}"
                  ></app-input>
                </div>
              </div>
            </div>
            <div class="achievements">
              <div class="group-title">
                {{'achievements' | translate}}
              </div>
              <div class="achievements-group">
                <div class="achievements-item" *ngFor="let achievement of customRewardsList">
                  <div class="img-container">
                    <img
                      src="{{generateValidImgUrl(achievement.image)}}{{achievement.state ? '' : '-grey'}}.svg"
                      [alt]="achievement.name"
                      nz-tooltip
                      [nzTooltipTitle]="achievementTooltip"
                    >
                  </div>
                  <ng-template #achievementTooltip>
                    <span *ngIf="achievement.name" class="math-tooltip-header">{{achievement.name | translate}}</span><br>
                    <span *ngIf="achievement.header">{{achievement.header | translate}}</span>
                  </ng-template>
                  <div class="setup-container">
                    <div class="setup-container-title">{{achievement.name | translate}}</div>
                    <div class="setup-group">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="achievement.state">
                        <div class="border"
                             (click)="toggleTeacherReward(achievement.id, achievement.connectionId); achievement.state = !achievement.state"></div>
                      </div>
                      <div class="label">{{achievement.header | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="verification" *ngIf="authService.isUserUkrainian() || authService.isUserPoland()">
              <div class="text">
                {{'verification' | translate}}
              </div>
              <input type="checkbox" [formControl]="isVerified">
              <span *ngIf="isVerified.value else notVerified" class="status" (click)="isVerified.patchValue(!isVerified.value); isVerified.markAsDirty()">{{'verified' | translate}}</span>
              <ng-template #notVerified>
                <span class="status" (click)="isVerified.patchValue(!isVerified.value); isVerified.markAsDirty()">{{'not_verified' | translate}}</span>
              </ng-template>
              <div class="text">
                {{'verification_description_long' | translate}}
              </div>
            </div>
          </div>
          <div class="footer">
            <app-button [disabled]="regulationViolation.invalid || positiveFeedback.invalid || negativeFeedback.invalid"
                        [buttonType]="'commonColourfulButton'" [text]="'save' | translate"
                        (btnClick)="indicatorsSaveBtnClick()"></app-button>
            <app-button [buttonType]="'commonWhiteButton'" [text]="'cancel' | translate"
                        (btnClick)="indicatorsCancelBtnClick()"></app-button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>


