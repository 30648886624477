import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentLevels, Regions } from '../../../shared/constants';
import { AuthService } from '../auth/auth.service';
import TeacherDto from '../schedule/dto/teacher.dto';

export interface FreeHoursRequest {
  region: string;
  limit: number;
  offset: number;
  dateFrom?: string;
  dateTo?: string;
  sorting?: object;
  filters?: {
    [key: string]: string | string[];
  };
}

export interface FreeHoursResponse {
  data: TeacherDto[];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export default class FreeHoursService {

  public $expandedTeacherWeekStart = new BehaviorSubject<Date>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) {
  }

  public getTeachersList(body: FreeHoursRequest): Observable<FreeHoursResponse> {
    return this.http.post<FreeHoursResponse>('/teachers/selection', body);
  }

  public getTeachersUsedCountries(): Observable<string[]> {
    return this.http.get<string[]>('/teachers/residence');
  }

  public makePaymentLevelTip(paymentLevel: string, authService: AuthService): string {
    if (!paymentLevel) {
      return '';
    }

    const region = Regions[authService.user.language];

    if (region === Regions.EN) {
      return '';
    }

    let rate;
    switch (paymentLevel) {
      case PaymentLevels.PREMIUM:
        rate = 250;
        break;
      case PaymentLevels.GOLD:
        rate = 200;
        break;
      case PaymentLevels.SILVER:
        rate = 175;
        break;
      case PaymentLevels.BASIC:
        rate = 150;
    }

    if (region === Regions.PL) {
      rate = 50;
    }

    const teacher = this.translateService.instant('teacher-with-level');
    const receives = this.translateService.instant('receives');
    const currency = this.translateService.instant('currancy');
    const perLesson = this.translateService.instant('per-lesson');

    return `${teacher} ${paymentLevel} ${receives} ${rate} ${currency} ${perLesson}`;
  }
}
