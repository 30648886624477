<div class="container">
  <div class="content">
    <div class="header">
      <div class="logo">
        <img src="assets/Logo_sm.png" alt="Logo">
      </div>
      <p>{{'authorization' | translate}}</p>
    </div>

    <div class="form-block">
    <span class="input-label">{{'ent-log-in' | translate}}</span>
    <input
      nz-input
      [placeholder]="'ent-log-in' | translate"
      class="input"
      [class.input-error]="touched.username && error.username"
      [(ngModel)]="username"
      (ngModelChange)="checkValidation()"
      (focus)="focus($event)"
      (blur)="blur($event)"
      (keydown)="keyDown($event)"
      type="text"
      name="username"
    >
    <span class="input-label">{{'ent-pass' | translate}}</span>
    <input
      nz-input
      [placeholder]="'ent-pass' | translate"
      class="input"
      [class.input-error]="touched.password && error.password"
      [(ngModel)]="password"
      (focus)="focus($event)"
      (blur)="blur($event)"
      (keydown)="keyDown($event)"
      type="password"
      name="password"
    >

    <div class="button">
      <p *ngIf="!!error.wrong" class="error">{{ error.wrong }}</p>

      <button
        [disabled]="checkButtonDisabled() || !fieldsIsValid"
        nz-button
        nzType="primary"
        (click)="login()"
      >
        {{'log-in' | translate}}
      </button>
    </div>
    </div>
  </div>
</div>
