<nz-layout class="calendar-page">

  <!-- Filters -->
  <nz-drawer
    *ngIf="tabletMode"
    [nzClosable]="false"
    (nzOnClose)="isFiltersDrawerClosed = true"
    [nzVisible]="!isFiltersDrawerClosed"
    [nzContent]="filtersComponent"
    nzPlacement="left"
    [nzWidth]="280"
    [nzTitle]="filtersDrawerTitle"
    nzWrapClassName="schedule-filters-drawer"
  ></nz-drawer>
  <nz-sider
    *ngIf="!tabletMode"
    nzCollapsible
    nzBreakpoint="lg"
    [nzTrigger]="null"
    [nzCollapsedWidth]="0"
    (nzCollapsedChange)="isFiltersSidebarCollapsed = $event"
    [nzCollapsed]="isFiltersSidebarCollapsed" [nzWidth]="280"
    class="sidebar"
  >
    <ng-template [ngTemplateOutlet]="filtersSidebarTitle"></ng-template>
    <ng-template [ngTemplateOutlet]="filtersComponent"></ng-template>
  </nz-sider>

  <!-- Lesson details -->
  <nz-drawer
    *ngIf="!isLessonDetailsDrawerClosed"
    [nzClosable]="false"
    (nzOnClose)="onCloseLessonDetails()"
    [nzVisible]="!isLessonDetailsDrawerClosed"
    [nzContent]="lessonDetailsComponent"
    nzPlacement="right"
    [nzWidth]="370"
    [nzTitle]="lessonDetailsDrawerTitle"
    nzWrapClassName="lesson-details-drawer"
  ></nz-drawer>

  <!-- Content -->
  <nz-content class="content">
    <nz-row class="conduction-warning" *ngIf="authService.isTeacher() && isUncoductedLessonsPresent">
      <div>{{'calendar.conduction-warning' | translate}}</div>
    </nz-row>
    <nz-row class="header">
      <nz-col [nzMd]="1" [nzLg]="0" class="main-menu-btn">
        <mathema-main-menu></mathema-main-menu>
      </nz-col>
      <nz-col *ngIf="isFiltersSidebarCollapsed" [nzXs]="0" [nzLg]="1" class="main-menu-btn">
        <mathema-main-menu></mathema-main-menu>
      </nz-col>
      <nz-col nzSpan="10">
        <h1 *ngIf="!authService.isTeacher(); else teacherHeading">
          {{'calendar.timetable' | translate}} <span class="teacher-name">
          {{scheduleCalendarService.activeTeacher.value?.lastName}} {{scheduleCalendarService.activeTeacher.value?.firstName}}
        </span>
        </h1>
        <ng-template #teacherHeading>
          <nz-badge
            *ngIf="scheduleCalendarService.activeTeacher.value as teacher"
            [nzCount]="teacher.blockedAvailability ? blockedBadge : null"
            nz-tooltip
            [nzTooltipTrigger]="teacher.blockedAvailability ? 'hover' : null"
            [nzTooltipTitle]="getTeacherBlockedTooltipForTeacher(teacher.blockedAt)"
          >
            <h1>{{'calendar.timetable' | translate}}</h1>
          </nz-badge>
          <ng-template #blockedBadge>
            <span nz-icon nzType="exclamation-circle" class="ant-scroll-number-custom-component" style="color: #f5222d"></span>
          </ng-template>
        </ng-template>
      </nz-col>
      <nz-col nzSpan="12" class="main-action-buttons">
        <ng-template #mainActionButtons [ngTemplateOutlet]="mainActionButtons">
          <div class="ai-btn" *ngIf="authService.isTeacher() && (authService.isUserUkrainian() || authService.isUserPoland())">
            <button class="ai-helper-btn" (click)="openAiHelper()">
              <svg height="18" width="18" fill="#fff" viewBox="0 0 24 24" class="sparkle">
                <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
              </svg>
              <span class="text">{{'main.btn.ai_helper' | translate}}</span>
            </button>
          </div>
          <div class="today-btn">
            <button class="action-btn bordered" (click)="onTodayClick()">
            {{'today' | translate}}
            </button>
          </div>
          <div class="free-slots-btn" *ngIf="authService.isTeacher()">
            <button class="action-btn bordered" (click)="onFreeSlotsClick()">
              <span nz-icon>
                <img src="./assets/icons/pencil.svg" alt="plus">
              </span>
              <span class="text-p-left">{{'calendar.filters.slots' | translate}}</span>
            </button>
          </div>
          <div class="add-btn" *ngIf="authService.isManager() || authService.isAdmin()">
            <button class="action-btn bordered" nz-dropdown [nzDropdownMenu]="addMenu"
              [nzOverlayClassName]="'add-menu-overlay'" nzTrigger="click"
              (nzVisibleChange)="isAddMenuOpened = !isAddMenuOpened">
              <span nz-icon>
                <img src="./assets/icons/plus.svg" alt="plus">
              </span>
              <span class="text">{{'main.btn.add' | translate}}</span>
              <span nz-icon [nzRotate]="isAddMenuOpened ? 0 : 180">
                <img src="./assets/icons/suffix.svg" alt="suffix">
              </span>
            </button>
            <nz-dropdown-menu #addMenu="nzDropdownMenu">
              <ul nz-menu>
                <ng-container *ngIf="scheduleCalendarService.activeTeacher.value as teacher; else addMenuWithoutTeacher">
                  <li
                    nz-menu-item
                    (click)="onOpenFirstLessonForm()"
                    [nzDisabled]="!!teacher.notTakeNewStudentsAt || teacher.blockedAvailability"
                    nz-tooltip
                    [nzTooltipTrigger]="(teacher.notTakeNewStudentsAt || teacher.blockedAvailability) ? 'hover' : null"
                    [nzTooltipTitle]="getTeacherBlockedTooltipForStuff(teacher.notTakeNewStudentsAt, teacher.blockedAvailability, teacher.blockedAt)"
                  >
                    <span>{{'calendar.first-lesson.first_lesson' | translate}}</span>
                  </li>
                  <li nz-menu-item (click)="onFreeSlotsClick()">
                    <span>{{'calendar.filters.slots' | translate}}</span>
                  </li>
                </ng-container>
                <ng-template #addMenuWithoutTeacher>
                  <li nz-menu-item (click)="onOpenFirstLessonForm()">
                    <span>{{'calendar.first-lesson.first_lesson' | translate}}</span>
                  </li>
                </ng-template>
                <li nz-menu-item (click)="onOpenVacations()">
                  <span>{{'calendar.vacations.open-btn' | translate}}</span>
                </li>
                <li nz-menu-item (click)="onNewDealClick()">
                  <span>{{'lessons-package' | translate}}</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </div>
        </ng-template>
      </nz-col>
    </nz-row>
    <nz-row *ngIf="isFiltersSidebarCollapsed" class="filters-switch">
      <nz-col [nzXs]="0" [nzLg]="24">
        <button (click)="isFiltersSidebarCollapsed = false" class="action-btn filters">
          {{'filters' | translate}}
        </button>
      </nz-col>
    </nz-row>
    <nz-row class="view-switchers" [style.margin-top.px]="isFiltersSidebarCollapsed ? 0 : 30">
      <nz-col [nzLg]="0">
        <button class="action-btn filters" (click)="isFiltersDrawerClosed = false">
          {{'filters' | translate}}
        </button>
      </nz-col>
      <nz-col>
        <mathema-schedule-calendar-type-switch [desktopMode]="true"></mathema-schedule-calendar-type-switch>
      </nz-col>
      <nz-col class="diapason-switch">
        <mathema-schedule-calendar-diapason-switch [desktopMode]="true"></mathema-schedule-calendar-diapason-switch>
      </nz-col>
    </nz-row>
    <nz-row class="header-mobile">
      <div class="main-block">
        <div class="main-menu">
          <mathema-main-menu></mathema-main-menu>
        </div>
        <div class="headings">
          <h2>{{'calendar.timetable' | translate}}</h2>
          <h3>{{scheduleCalendarService.activeTeacher.value?.lastName}} {{scheduleCalendarService.activeTeacher.value?.firstName}}</h3>
        </div>
      </div>
      <div class="actions-block">
        <button class="action-btn bordered filters" (click)="isFiltersModalClosed = false">
          {{'filters' | translate}}
        </button>
        <div class="main-action-buttons">
          <ng-template [ngTemplateOutlet]="mainActionButtons"></ng-template>
        </div>
      </div>
    </nz-row>
    <nz-row class="calendar">
      <section class="host"><ng-template scheduleCalendarHost></ng-template></section>
    </nz-row>
    <nz-row class="footer-mobile">
      <nz-col nzFlex="1">
        <mathema-schedule-calendar-type-switch>
        </mathema-schedule-calendar-type-switch>
      </nz-col>
      <nz-col nzFlex="3">
        <mathema-schedule-calendar-diapason-switch>
        </mathema-schedule-calendar-diapason-switch>
      </nz-col>
    </nz-row>
  </nz-content>

  <!-- First lesson
  <nz-sider
    *ngIf="!tabletMode && !isFirstLessonSidebarCollapsed"
    nzBreakpoint="lg"
    [nzWidth]="365"
    class="first-lesson-sider"
  >
    <ng-template [ngTemplateOutlet]="firstLessonComponent"></ng-template>
  </nz-sider> -->
  <!-- *ngIf="tabletMode" -->
  <nz-drawer
    *ngIf="!isFirstLessonDrawerCollapsed"
    [nzClosable]="false"
    (nzOnClose)="onCloseFirstLessonForm()"
    [nzVisible]="!isFirstLessonDrawerCollapsed"
    [nzContent]="firstLessonComponent"
    nzPlacement="right"
    [nzWidth]="365"
    [nzTitle]="null"
    nzWrapClassName="create-first-lesson-drawer"
  ></nz-drawer>

  <!-- Move lesson -->
  <nz-sider
    *ngIf="!tabletMode && !isMoveLessonSidebarCollapsed"
    nzBreakpoint="lg"
    [nzWidth]="365"
    class="move-lesson-sider"
  >
    <ng-template [ngTemplateOutlet]="moveLessonComponent"></ng-template>
  </nz-sider>
  <nz-drawer
    *ngIf="tabletMode"
    [nzClosable]="false"
    (nzOnClose)="onCloseMoveLesson()"
    [nzVisible]="!isMoveLessonDrawerCollapsed"
    [nzContent]="moveLessonComponent"
    nzPlacement="right"
    [nzWidth]="365"
    [nzTitle]="null"
    nzWrapClassName="move-lesson-drawer"
  ></nz-drawer>
</nz-layout>

<!-- Filters modal -->
<nz-modal
  [nzContent]="filtersComponent"
  [(nzVisible)]="!isFiltersModalClosed"
  [nzTitle]="'filters' | translate"
  nzClassName="filters-mobile"
  (nzOnCancel)="isFiltersModalClosed=true"
  (nzOnOk)="isFiltersModalClosed=true"
  [nzFooter]="null"
></nz-modal>

<!-- Conduct lesson modal -->
<nz-modal
  [nzContent]="conductLessonModalComponent"
  [(nzVisible)]="!isConductLessonModalClosed"
  nzTitle="{{'calendar.conduct-lesson.title' | translate}}"
  [nzClassName]="mobileMode ? 'calendar-modal-mobile' : 'calendar-modal'"
  (nzOnCancel)="onCloseConductLesson()"
  (nzOnOk)="onCloseConductLesson()"
  [nzFooter]="null"
  nzCentered
  nzWidth="500px"
></nz-modal>

<!-- Report modal -->
<nz-modal
  [nzContent]="reportModalComponent"
  [(nzVisible)]="!isReportModalClosed"
  nzTitle="{{'calendar.report.title-diagnostic' | translate : {
    studentName: activeLessonToConduct?.student?.lastName + ' ' + activeLessonToConduct?.student?.firstName
  } }}"
  [nzClassName]="mobileMode ? 'calendar-modal-mobile' : 'calendar-modal'"
  (nzOnCancel)="onCloseConductLesson()"
  (nzOnOk)="onCloseConductLesson()"
  [nzFooter]="null"
  nzCentered
  nzWidth="500px"
></nz-modal>

<!-- Lesson details modal -->
<nz-modal
  [nzContent]="lessonDetailsComponent"
  [(nzVisible)]="!isLessonDetailsModalClosed"
  nzTitle="{{'calendar.lesson-details.lesson_details' | translate}}"
  nzClassName="lesson-details-mobile"
  (nzOnCancel)="onCloseLessonDetails()"
  (nzOnOk)="onCloseLessonDetails()"
  [nzFooter]="null"
></nz-modal>

<!-- Create first lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="firstLessonComponent"
  [(nzVisible)]="!isFirstLessonModalClosed"
  [nzTitle]="null"
  nzClassName="create-first-lesson-mobile"
  (nzOnCancel)="onCloseFirstLessonForm()"
  (nzOnOk)="onCloseFirstLessonForm()"
  [nzFooter]="null"
></nz-modal>

<!-- Move lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="moveLessonComponent"
  [(nzVisible)]="!isMoveLessonModalClosed"
  [nzTitle]="null"
  nzClassName="move-lesson-mobile"
  (nzOnCancel)="onCloseMoveLesson()"
  (nzOnOk)="onCloseMoveLesson()"
  [nzFooter]="null"
></nz-modal>

<!-- Vacations modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="vacationsComponent"
  [(nzVisible)]="!isVacationsModalClosed"
  [nzTitle]="vacationsModalTitle"
  [nzMaskClosable]="false"
  [nzClassName]="mobileMode ? 'vacations-mobile' : 'vacations-modal'"
  (nzOnCancel)="onCloseVacations()"
  (nzOnOk)="onCloseVacations()"
  [nzFooter]="null"
  nzWidth="400"
  nzCentered
></nz-modal>

<!-- Filters templates -->

<ng-template #filtersComponent>
  <mathema-schedule-calendar-filters></mathema-schedule-calendar-filters>
</ng-template>

<ng-template #filtersDrawerTitle>
  <nz-row class="schedule-filters-header">
    <nz-col nzSpan="2" nzOffset="2" class="main-menu">
      <mathema-main-menu (onOpenButtonClicked)="isFiltersDrawerClosed = true"></mathema-main-menu>
    </nz-col>
    <nz-col nzSpan="6" nzOffset="2">
      <h2>{{'filters' | translate}}</h2>
    </nz-col>
    <nz-col nzSpan="2" nzOffset="9"><button (click)="isFiltersDrawerClosed = true"
        class="close-schedule-filters-btn"></button></nz-col>
  </nz-row>
</ng-template>

<ng-template #filtersSidebarTitle>
  <nz-row class="schedule-filters-header">
    <nz-col nzSpan="2" nzOffset="2" class="main-menu"><mathema-main-menu></mathema-main-menu></nz-col>
    <nz-col nzSpan="6" nzOffset="2">
      <h2>{{'filters' | translate}}</h2>
    </nz-col>
    <nz-col nzSpan="2" nzOffset="9">
      <button (click)="isFiltersSidebarCollapsed = true" class="close-schedule-filters-btn"></button>
    </nz-col>
  </nz-row>
</ng-template>

<!-- Lesson details templates -->
<ng-template #lessonDetailsDrawerTitle>
  <nz-row class="lesson-details-title" nzJustify="center" nzAlign="middle">
    <nz-col nzSpan="2">
      <span nz-icon (click)="onCloseLessonDetails()"><img src="assets/icons/cross.svg" alt="close"></span>
    </nz-col>
    <nz-col nzOffset="2" nzSpan="20">
      <h2>{{'calendar.lesson-details.lesson_details' | translate}}</h2>
    </nz-col>
  </nz-row>
</ng-template>

<ng-template #lessonDetailsComponent>
  <mathema-schedule-calendar-lesson-details
    (onEditFirstLesson)="onCloseLessonDetails(true); onOpenFirstLessonForm()"
    (onMoveLesson)="onCloseLessonDetails(true); onOpenMoveLesson()"
    (onSkipLesson)="onOpenSkipLesson()"
    (onCancelConductLesson)="onOpenCancelConductLesson()"
    (onConductLesson)="openConductLesson($event)"
    (onCancelLesson)="onOpenCancelLesson()"
    [mobileMode]="mobileMode"
  ></mathema-schedule-calendar-lesson-details>
</ng-template>

<ng-template #conductLessonModalComponent>
  <math-conduct-lesson-modal
    [lessonId]="activeLessonToConduct?.id"
    (onSuccess)="onLessonConducted()"
    (onCancel)="onCloseConductLesson()"
  ></math-conduct-lesson-modal>
</ng-template>


<ng-template #reportModalComponent>
  <math-report-modal
    [firstLessonId]="activeLessonToConduct?.id"
    (onSuccess)="onLessonConducted()"
    (onCancel)="onCloseConductLesson()"
  ></math-report-modal>
</ng-template>

<!-- Create first lesson templates -->
<ng-template #firstLessonComponent>
  <mathema-schedule-calendar-first-lesson (onCancel)="onCloseFirstLessonForm()"></mathema-schedule-calendar-first-lesson>
</ng-template>

<!-- Move lesson component templates -->
<ng-template #moveLessonComponent>
  <mathema-schedule-calendar-move-lesson (onClose)="onCloseMoveLesson()" [showNotification]="isSendNotificationNeeded()">
  </mathema-schedule-calendar-move-lesson>
</ng-template>

<!-- Vacations component templates -->
<ng-template #vacationsComponent>
  <mathema-schedule-calendar-vacations (onSuccess)="onCloseVacations(); scheduleCalendarService.refreshCalendarDate()"></mathema-schedule-calendar-vacations>
</ng-template>

<ng-template #vacationsModalTitle>
  <nz-row class="vacations-title" nzJustify="center" nzAlign="middle">
    <nz-col nzSpan="2">
      <span nz-icon (click)="onCloseVacations()"><img src="assets/icons/cross.svg" alt="close"></span>
    </nz-col>
    <nz-col nzSpan="22">
      <h2>{{'calendar.vacations.header' | translate}}</h2>
    </nz-col>
  </nz-row>
</ng-template>
