import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IndicatorsService {
  public updateIndicatorsData$: Subject<void> = new Subject<void>();

  constructor() { }

  public updateIndicatorsData(): void {
    this.updateIndicatorsData$.next();
  }
}
