import {
  dateToYyyyMmDd,
  splitNumbersArrayByDiapasons,
  splitNumbersArrayBySerialSegments,
  timeSlotDiapasonToTimeString
} from '../../../shared/utils';

export const splitFreeSlotsByLessonTimes = (freeSlotDate: string, freeSlotDiapason: number[], lessonSize: number): string[] => {
  const now = new Date();
  const nowYyMmDd = dateToYyyyMmDd(now);

  const converter = (diapason: number[], sliceEveryDiapasonFrom = 0) => splitNumbersArrayBySerialSegments(diapason)
    .map(solidFreeSlotDiapason => splitNumbersArrayByDiapasons(solidFreeSlotDiapason.slice(sliceEveryDiapasonFrom), lessonSize)
      .filter(hourOrHalfHourDiapason => hourOrHalfHourDiapason.length >= lessonSize)
      .map(timeSlotDiapasonToTimeString)
      .filter((timeString) => {
        if (nowYyMmDd !== freeSlotDate) {
          return true;
        }

        const [startTime] = timeString.split(' - ');
        const freeSlotStartTimeJsDate = new Date(`${freeSlotDate}T${startTime}`);
        return +freeSlotStartTimeJsDate > +now;
      }),
    )
    .reduce((times, value) => times.concat(value), []);

  const slotsTimesFromStart = converter(freeSlotDiapason);
  let slotsTimesFromStartPlus30Minutes = [];

  // duplicate slots with 30 minutes shift only if we build 60 or 90 minutes lessons
  if (lessonSize > 6) {
    slotsTimesFromStartPlus30Minutes = converter(freeSlotDiapason, 6);
  }

  return [...slotsTimesFromStart, ...slotsTimesFromStartPlus30Minutes].sort((a, b) => a.localeCompare(b));
}
