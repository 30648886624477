import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mathema-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainMenuComponent {

  @Output() public readonly onOpenButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  public isOpened = false;
}
