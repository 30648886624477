<ng-container>
  <!-- Teacher slot type -->
  <section class="filter-block teacher-slot-type" *ngIf="authService.isTeacher()">
    <div class="label">{{'calendar.filters.to_show' | translate}}:</div>
    <nz-checkbox-wrapper (nzOnChange)="selectLessonTypes($event)">
      <div *ngFor="let slotType of teacherSlotTypeFilterInputs">
        <label nz-checkbox [nzValue]="slotType.value" [(ngModel)]="slotType.checked">
          {{slotType.label | translate}}</label>
      </div>
    </nz-checkbox-wrapper>
  </section>
  <form [formGroup]="filtersForm">
    <!-- Slot type -->
    <section class="filter-block slot-type" *ngIf="authService.isAdmin() || authService.isManager()">
      <div class="label">{{'calendar.filters.to_show' | translate}}:</div>
      <nz-radio-group formControlName="slotType">
        <label nz-radio *ngFor="let input of slotTypeFilterInputs" [nzValue]="input.value"
          [nzDisabled]="!isTeacherSelected()">
          {{input.label | translate}}
        </label>
      </nz-radio-group>
    </section>

    <section class="clear-filters" *ngIf="isClearAvailable()" (click)="clearFilters()">
      {{'calendar.filters.clear-filters' | translate}}
    </section>

    <!-- Teacher name -->
    <ng-container *ngIf="authService.isAdmin() || authService.isManager()">
      <nz-divider></nz-divider>
      <section class="filter-block teacher-name">
        <nz-collapse nzGhost nzExpandIconPosition="right">
          <nz-collapse-panel nzActive #teacherNameControl [nzExtra]="teacherNameRefresh"
            nzHeader="{{'teacher' | translate}}" [nzExpandedIcon]="teacherNameArrow">
            <nz-input-group [nzPrefix]="prefixSearch">
              <input type="text" nz-input placeholder="{{'calendar.filters.teacher_search' | translate}}"
                (input)="teacherNameSearch.next($event)" [(ngModel)]="teacherNameInput"
                [ngModelOptions]="{standalone: true}" />
            </nz-input-group>
            <ng-container *ngIf="teachersList | async as list">
              <nz-radio-group formControlName="teacherId">
                <nz-spin class="calendar-filters-loader" nzSimple [nzIndicator]="loadingSpin"
                  *ngIf="isTeachersListLoading"></nz-spin>
                <label *ngFor="let teacher of list" nz-radio [nzValue]="teacher.id">
                  {{teacher.lastName}} {{teacher.firstName}}
                </label>
              </nz-radio-group>
              <div class="tip" *ngIf="teacherNameInput.length === 0 && list?.length === 0">
                {{'calendar.filters.start_typing_to_search' | translate}}
              </div>
              <div class="tip no-result"
                *ngIf="!isTeachersListLoading && teacherNameInput.length > 0 && list?.length === 0">
                {{'calendar.filters.no_results' | translate}}
              </div>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
        <ng-template #teacherNameRefresh>
          <span nz-icon class="refresh-icon" (click)="$event.stopPropagation(); resetTeachersFilter()">
            <img src="../../../../../../assets/icons/reload.svg" alt="reload">
          </span>
        </ng-template>
        <ng-template #teacherNameArrow>
          <span nz-icon class="ant-collapse-arrow" [nzRotate]="teacherNameControl.nzActive ? -0 : 180">
            <img src="../../../../../../assets/icons/chevrone.svg" alt="open-close">
          </span>
        </ng-template>
      </section>
    </ng-container>

    <!-- Student name -->
    <nz-divider></nz-divider>
    <section class="filter-block student-name">
      <nz-collapse nzGhost nzExpandIconPosition="right">
        <nz-collapse-panel nzActive #studentNameControl [nzExtra]="studentNameRefresh"
          nzHeader="{{'student' | translate}}" [nzExpandedIcon]="studentNameArrow">
          <nz-input-group [nzPrefix]="prefixSearch">
            <input type="text" nz-input placeholder="{{'calendar.filters.student_search' | translate}}"
              (input)="studentNameSearch.next($event)" [(ngModel)]="studentNameInput"
              [ngModelOptions]="{standalone: true}" />
          </nz-input-group>
          <nz-spin class="calendar-filters-loader" nzSimple [nzIndicator]="loadingSpin"
            *ngIf="isStudentsListLoading"></nz-spin>
          <nz-checkbox-group formControlName="studentsIds"></nz-checkbox-group>
          <div class="tip" *ngIf="studentNameInput.length === 0 && !isStudentsListPresent() ">
            {{'calendar.filters.start_typing_to_search' | translate}}
          </div>
          <div class="tip no-result"
            *ngIf="!isStudentsListLoading && studentNameInput.length > 0 && !isStudentsListPresent()">
            {{'calendar.filters.no_results' | translate}}
          </div>
        </nz-collapse-panel>
      </nz-collapse>
      <ng-template #studentNameRefresh>
        <span nz-icon class="refresh-icon" (click)="$event.stopPropagation(); resetStudentsFilter()">
          <img src="../../../../../../assets/icons/reload.svg" alt="reload">
        </span>
      </ng-template>
      <ng-template #studentNameArrow>
        <span nz-icon class="ant-collapse-arrow" [nzRotate]="studentNameControl.nzActive ? -0 : 180">
          <img src="../../../../../../assets/icons/chevrone.svg" alt="open-close">
        </span>
      </ng-template>
    </section>
  </form>
</ng-container>

<ng-template #prefixSearch><span nz-icon nzType="search"></span></ng-template>
<ng-template #loadingSpin><span nz-icon nzType="loading"></span></ng-template>