<input style="display: none" type="file" (change)="handlePhotoInputChange($event)" [accept]="photoAcceptanceFiles" [multiple]="false" #photo>

<div class="wrapper">
  <app-button class="close-btn"  [buttonType]="'invincibleButton'" [customParams]="{customFontSize: '28px'}" icon="close" (btnClick)="closeDialog()"></app-button>
  <div class="header">
    {{'edit_profile_photo' | translate}}
  </div>
  <div class="content">
    <div class="additional-text">
      <img src="assets/add-photo-tooltip.png" alt="adding">
      <span>{{'image_alert' | translate}}</span>
    </div>
    <div class="avatar">
      <img *ngIf="data.avatarLink else avatarLetter" [src]="data.avatarLink" alt="user avatar">
      <ng-template #avatarLetter>
        {{data.avatarLetter}}
      </ng-template>
    </div>
    <div class="buttons">
      <app-button [customParams]="{customWidth: '155px'}" (btnClick)="uploadNewPhoto()" [buttonType]="'loading-file-btn'" [text]="'add_photo' | translate"></app-button>
      <app-button [customParams]="{customWidth: '155px'}" (btnClick)="removePhoto()" [disabled]="!data.avatarLink || data.avatarLink === userPhotoLinks.noPhotoGirlLink || data.avatarLink === userPhotoLinks.noPhotoBoyLink" [buttonType]="'remove-file-btn'" [text]="'remove_photo' | translate"></app-button>
    </div>
  </div>
  <div class="footer">
    <app-button (btnClick)="closeDialog()" [buttonType]="'commonColourfulButton'" [text]="'done' | translate"></app-button>
  </div>
</div>
