<nz-layout class="base">
    <nz-layout class="base-container">
        <nz-row><nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                <div class="logo">
                    <img src="assets/Logo_sm.png" alt="Logo">
                </div>
            </nz-col></nz-row>
        <nz-row nzJustify="center">
            <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24" class="align-box">
                <img class="illustration" src="assets/login/signup-success.svg">
            </nz-col>
            <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                <div class="header">
                    <p class="head-line">{{'signup-success' | translate}}</p>
                </div>
            </nz-col>
            <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                <div class="links">
                    <div class="login"><a class="login-link" routerLink="/login">{{'signin' | translate}}</a>
                    </div>
                </div>
            </nz-col>
        </nz-row>
    </nz-layout>
</nz-layout>
