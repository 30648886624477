export const slotTypeFilterInputs = [{
  label: 'calendar.filters.all',
  value: 'all'
},{
  label: 'calendar.filters.only-slots',
  value: 'only-slots'
}, {
  label: 'calendar.filters.only-lessons',
  value: 'only-lessons',
}];

export const teacherSlotTypeFilterInputsDefaults = [{
  label: 'calendar.filters.booked',
  value: 'booked',
  checked: false
}, {
  label: 'calendar.filters.reserved',
  value: 'reserved',
  checked: false
}, {
  label: 'calendar.filters.regular',
  value: 'regular',
  checked: false
}, {
  label: 'calendar.filters.first',
  value: 'first',
  checked: false
}, {
  label: 'calendar.filters.slots',
  value: 'slots',
  checked: false
}];

