import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

const notificationParams: {delay: number} = {
  delay: 10000
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private timeout;
  private currentMessageState: boolean = false;

  public $showErrorNotification: Subject<boolean> = new Subject<boolean>();
  public $showInfoNotification: Subject<boolean> = new Subject<boolean>();
  public $showSuccessNotification: Subject<boolean> = new Subject<boolean>();
  public $message: Subject<string> = new Subject<string>();

  public showErrorMessage(message: string): void {
    if(this.currentMessageState){
      this.instantCloseNotification();
      setTimeout(()=>{
        this.currentMessageState = true;
        this.$message.next(message);
        this.$showErrorNotification.next(true);
        this.closeNotification();
      }, 500)
    } else {
      this.currentMessageState = true;
        this.$message.next(message);
        this.$showErrorNotification.next(true);
        this.closeNotification();
    }
  }
  public showInfoMessage(message: string): void {
    if(this.currentMessageState){
      this.instantCloseNotification();
      setTimeout(()=>{
        this.currentMessageState = true;
        this.$message.next(message);
        this.$showInfoNotification.next(true);
        this.closeNotification();
      }, 500)
    } else {
      this.currentMessageState = true;
        this.$message.next(message);
        this.$showInfoNotification.next(true);
        this.closeNotification();
    }
  }
  public showSuccessMessage(message: string): void {
    if(this.currentMessageState){
      this.instantCloseNotification();
      setTimeout(()=>{
        this.currentMessageState = true;
        this.$message.next(message);
        this.$showSuccessNotification.next(true);
        this.closeNotification();
      }, 500)
    } else {
      this.currentMessageState = true;
        this.$message.next(message);
        this.$showSuccessNotification.next(true);
        this.closeNotification();
    }
  }

  public instantCloseNotification(): void {
    this.$showErrorNotification.next(false);
    this.$showInfoNotification.next(false);
    this.$showSuccessNotification.next(false);
    this.currentMessageState=false;
  }




  private closeNotification(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(()=>{
      this.$showErrorNotification.next(false);
      this.$showInfoNotification.next(false);
      this.$showSuccessNotification.next(false);
      this.currentMessageState = false;
      }, notificationParams.delay)
    }
}
