import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import ScheduleApiService, {
  MonthlyCalendarDay,
  MonthlyCalendarItem,
  SlotTypeFilter
} from '../../../schedule-api.service';
import LanguageService from '../../../../../../shared/language/language.service';
import DealService from '../../../../deal/deal.service';
import {
  dateToYyyyMmDd,
  daysBetweenDates,
  minutesToHours,
  timeStringToTimeSlotDiapason
} from '../../../../../../shared/utils';
import { DateTime } from 'luxon';
import ScheduleCalendarService from '../../schedule-calendar.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  MathemaModalConfirmComponent
} from '../../../../../../shared/components/modal-confirm/modal-confirm.component';
import {
  AvailableIntersectionDates, IntersectionsReplaces,
  SlotsIntersectionModalComponent
} from '../../../../deal/modals/slots-intersection-modal/slots-intersection-modal.component';
import { splitFreeSlotsByLessonTimes } from '../../../slots-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mathema-move-reservation-modal',
  templateUrl: './move-reservation-modal.component.html',
  styleUrls: ['./move-reservation-modal.component.scss']
})
export class MoveReservationModalComponent implements OnInit, OnDestroy {

  @Input() reservationItem: MonthlyCalendarItem;

  public isLoading: boolean;

  public repeatCount: number;
  public lastRepeatDate: string;
  private repeatsItems: MonthlyCalendarItem[] = [];

  public selectedDate: Date | null = null;
  public selectedTime: string;
  public freeTimes: string[];

  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly modalRef: NzModalRef,
    private readonly languageService: LanguageService,
    private readonly dealService: DealService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly modalService: NzModalService,
    private readonly translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.initRepeats();
  }

  private initRepeats(): void {
    const days = this.dealService.dealCalendarDays.value;
    const startDate = new Date(this.reservationItem.date);
    startDate.setDate(startDate.getDate() + 7);

    let repeats = 0;
    let lastRepeatDate = this.reservationItem.date;

    for (let i = 0; true; i++) {
      const repeatDate = new Date(startDate);
      repeatDate.setDate(repeatDate.getDate() + (7 * i));

      const day = days[dateToYyyyMmDd(repeatDate)];
      if (day) {
        const repeat = day.items.find(reservation => reservation.time === this.reservationItem.time && reservation.isConfirmedReservation);

        if (repeat) {
          this.repeatsItems.push(repeat);
          repeats += 1;
          lastRepeatDate = dateToYyyyMmDd(repeatDate);
        }
      } else {
        break;
      }
    }

    this.repeatCount = repeats;
    this.lastRepeatDate = lastRepeatDate;
  }

  public onMoveWithoutRepeats(): void {
    const selectedDate = DateTime.fromJSDate(this.selectedDate).toISODate();
    this.moveWithoutRepeats(this.reservationItem, this.selectedTime, selectedDate);

    if (!this.reservationItem.markedForFutureSubscription) {
      this.dealService.updateBorderLessonsDates();
    }
  }

  private moveWithoutRepeats(item: MonthlyCalendarItem, time: string, date: string): void {
    let days = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));

    this.setupItemsForDates([{ date, time, item }], days, () => {
      days = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));
      this.onRemoveWithoutRepeats(item.id, item.date, days);
      this.modalRef.close();
    });
  }

  public onMoveWithRepeats(): void {
    const thisItemDate = DateTime.fromISO(this.reservationItem.date);
    const selectedDate = DateTime.fromJSDate(this.selectedDate);
    const diff = thisItemDate.diff(selectedDate, 'days').days;

    const itemsToMove = [this.reservationItem, ...this.repeatsItems];
    const moveData = [];
    for (const item of itemsToMove) {
      let dateToMove;

      if (diff < 0) {
        dateToMove = DateTime.fromISO(item.date).plus({ day: Math.abs(diff) }).toISODate();
      } else {
        dateToMove = DateTime.fromISO(item.date).minus({ day: Math.abs(diff) }).toISODate();
      }

      moveData.push({ time: this.selectedTime, date: dateToMove, item });
    }

    this.moveWithRepeats(moveData);

    if (!this.reservationItem.markedForFutureSubscription) {
      this.dealService.updateBorderLessonsDates();
    }
  }

  private moveWithRepeats(datesAndTimes: { date: string, time: string, item: MonthlyCalendarItem }[]): void {
    let days = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));

    const setupHoursCallback = (hoursSetup: number) => {
      if (this.reservationItem.markedForFutureSubscription) {
        const { nextReservations } = this.dealService.scheduleMetrics.value;
        this.dealService.updateScheduleMetrics({
          nextReservations: nextReservations + datesAndTimes
            .filter((el) => el.item.markedForFutureSubscription)
            .reduce(((acc, {item}) => acc + minutesToHours(timeStringToTimeSlotDiapason(item.time).length * 5)), 0),
        });
      } else {
        const { setHours, reservedLessons, nextReservations } = this.dealService.scheduleMetrics.value;
        this.dealService.updateScheduleMetrics({
          setHours: setHours + hoursSetup,
          reservedLessons: reservedLessons + hoursSetup,
          nextReservations: nextReservations + datesAndTimes
            .filter((el) => el.item.markedForFutureSubscription)
            .reduce(((acc, {item}) => acc + minutesToHours(timeStringToTimeSlotDiapason(item.time).length * 5)), 0),
        });
      }
    }

    const notLoadedDates = datesAndTimes.filter(({ date }) => !days[date]).map(({ date }) => date);

    const setup = () => this.setupItemsForDates(datesAndTimes, days, () => {
      days = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));
      this.onRemoveWithRepeats(days);
      this.modalRef.close();
    }, setupHoursCallback);

    if (notLoadedDates.length) {
      const query = this.scheduleCalendarService.calendarFilters.value;
      const subscription = this.scheduleApiService.getSetOfDates({
        ...query,
        dates: notLoadedDates,
      }).subscribe(result => {
        for (const day of result) {
          days[day.date] = day;
        }
        setup();
      });
      this.subscriptions.push(subscription);
    } else {
      setup();
    }
  }

  public onTimeChecked(isChecked: boolean, time: string): void {
    if (isChecked) {
      this.selectedTime = time;
    } else {
      this.selectedTime = null;
    }
  }

  public onChangeMoveDate(moveDate: Date): void {
    this.selectedDate = moveDate;
    this.selectedTime = null;
    this.freeTimes = null;

    const date = DateTime.fromJSDate(moveDate).toISODate();
    const day = this.dealService.dealCalendarDays.value[date];

    if (day) {
      this.initSlots(day.items);
    } else {
      const filters = this.scheduleCalendarService.calendarFilters.value;
      const year = this.selectedDate.getFullYear();
      const month = this.selectedDate.getMonth();
      const day = this.selectedDate.getDate();
      const subscription = this.scheduleApiService.getDailyCalendar({
        ...filters,
        year,
        month,
        day,
        slotType: SlotTypeFilter.ALL,
      })
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((day) => {
        this.initSlots(day.items);
      });

      this.isLoading = true;
      this.subscriptions.push(subscription);
    }
  }

  private initSlots(items: MonthlyCalendarItem[]): void {
    const freeSlots = items.filter((item) => !item.isLesson);

    if (!freeSlots.length) {
      return;
    }

    this.freeTimes = freeSlots.reduce((acc, item) => {
      return acc.concat(this.scheduleCalendarService.splitSlotTimeToDiapasons(item.time, this.reservationItem.time))
    }, []);
  }

  public isDateYesterdayOrBefore(): (date: Date) => boolean {
    return (date: Date) => {
      return daysBetweenDates(dateToYyyyMmDd(new Date()), dateToYyyyMmDd(date)) > 0;
    }
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public formatDateForSlots(date: Date | string, isDateString?: boolean): string {
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    let dateToFormat: Date = date as Date;
    if (isDateString) {
      dateToFormat = new Date(date);
    }

    const [day, month, year] = formatter.format(dateToFormat).split(' ');
    return [day, month, year].join(' ');
  }

  private onRemoveWithoutRepeats(id: string, date: string, days: Record<string, MonthlyCalendarDay>): void {
    const day: MonthlyCalendarDay = days[date];

    const itemToRemove = day.items.find(item => item.id === id);
    const mergingResult = this.dealService.removeSlotWithMergingItems(itemToRemove, day.items);
    day.items = mergingResult.newItems;

    const reservationsToRemove = this.dealService.reservationsIdsToRemove.value;
    if (itemToRemove.isLessonReservation) {
      this.dealService.reservationsIdsToRemove.next([...reservationsToRemove, itemToRemove.id]);
    }

    this.dealService.dealCalendarDays.next(days);

    this.dealService.isScheduleChangesDetected.next(true);
  }

  public onRemoveWithRepeats(days: Record<string, MonthlyCalendarDay>): void {
    let hoursRemoved = 0;
    let nextReservationsHoursRemoved = 0;

    const itemsIdsToRemove: string[] = [];

    for (let i = 0; i <= this.repeatCount; i++) {
      const repeatDate = new Date(this.reservationItem.date)
      repeatDate.setDate(repeatDate.getDate() + (7 * i));

      const day: MonthlyCalendarDay = days[dateToYyyyMmDd(repeatDate)];
      const itemToRemove = day.items.find(item => item.time === this.reservationItem.time);
      if (itemToRemove) {
        if (itemToRemove.isLessonReservation) {
          itemsIdsToRemove.push(itemToRemove.id);
        }
        const mergingResult = this.dealService.removeSlotWithMergingItems(itemToRemove, day.items);
        day.items = mergingResult.newItems;

        if (itemToRemove.markedForFutureSubscription) {
          nextReservationsHoursRemoved += mergingResult.hoursRemoved;
        } else {
          hoursRemoved += mergingResult.hoursRemoved;
        }
      }
    }

    const reservationsToRemove = this.dealService.reservationsIdsToRemove.value;
    this.dealService.reservationsIdsToRemove.next([...reservationsToRemove, ...itemsIdsToRemove]);

    const { setHours, reservedLessons, nextReservations } = this.dealService.scheduleMetrics.value;
    this.dealService.updateScheduleMetrics({
      nextReservations: nextReservations - nextReservationsHoursRemoved,
      setHours: setHours - hoursRemoved,
      reservedLessons: reservedLessons - hoursRemoved,
    });

    this.dealService.dealCalendarDays.next(days);

    this.dealService.isScheduleChangesDetected.next(true);
  }

  private setupItemsForDates(
    datesAndTimes: { date: string, time: string, item: MonthlyCalendarItem }[],
    days: Record<string, MonthlyCalendarDay>,
    afterSetup?: () => void,
    setupHoursCb?: (hoursSetup: number) => void,
  ): void {
    const reservationWithoutSlotsDates: string[] = [];
    const lessonsIntersections: { time: string, item: MonthlyCalendarItem }[] = [];
    let hoursSetup = 0;

    for (const { date, time, item } of datesAndTimes) {
      const initialItemsArray = days[date].items;
      const isReservationWithoutSlot: boolean =
        days[date].isVacation ||
        !this.dealService.getFreeSlotItemForReservationItem(time, initialItemsArray);
      const duration = timeStringToTimeSlotDiapason(time).length / 12;

      const intersectedLesson = this.dealService.getLessonForReservationItem(time, initialItemsArray);
      if (intersectedLesson) {
        lessonsIntersections.push({ time, item: intersectedLesson });
        continue;
      }

      const futureItemsArray = days[date].items;

      const futureItem = {
        ...item,
        isLessonReservation: false,
        date,
        time,
        isReservationWithoutSlot,
      };

      const [prevFutureItem, nextFutureItem] = this.dealService.getPrevAndNextFutureItems(futureItem, days[date].items);
      let futureItems;
      if (futureItem.isReservationWithoutSlot) {
        reservationWithoutSlotsDates.push(this.formatDateForSlots(futureItem.date, true));
        futureItems = [];
      } else {
        futureItems = [prevFutureItem, futureItem, nextFutureItem].filter(Boolean);
      }

      const pushAfterIdx = initialItemsArray.findIndex(el => {
        const chosenTimeDiapason = timeStringToTimeSlotDiapason(time).join('');
        const itemTimeDiapason = timeStringToTimeSlotDiapason(el.time).join('');
        return itemTimeDiapason.includes(chosenTimeDiapason);
      });

      if (pushAfterIdx !== -1) {
        futureItemsArray.splice(pushAfterIdx, 1, ...futureItems);
      } else {
        futureItemsArray.push(...futureItems);
        futureItemsArray.sort((a, b) => a.time.localeCompare(b.time));
      }

      if (!futureItem.isReservationWithoutSlot && !futureItem.markedForFutureSubscription) {
        hoursSetup += duration;
      }
    }

    const checkForInterceptionsOrSetupDays = () => {
      if (lessonsIntersections.length) {
        const carry = (hours) => {
          setupHoursCb(hoursSetup);
          setupHoursCb(hours);
        }

        this.openSlotIntersectionsModal(lessonsIntersections, days, datesAndTimes[0].item, afterSetup, carry);
      } else {
        this.dealService.dealCalendarDays.next(days);
        if (afterSetup) {
          afterSetup();
        }

        if (setupHoursCb) {
          setupHoursCb(hoursSetup);
        }
      }
    };

    if (reservationWithoutSlotsDates.length) {
      this.openReservationWithoutSlotsInfoModal(reservationWithoutSlotsDates, checkForInterceptionsOrSetupDays);
    } else {
      checkForInterceptionsOrSetupDays();
    }
  }

  private openReservationWithoutSlotsInfoModal(noSlotsDates: string[], afterClosed: () => void): void {
    const messageText = `${this.translateService.instant('deal.slot-form.reservations-wont-be-created')}<br />`;
    const messageDates = noSlotsDates.map(el => `&mdash; ${el}<br />`).join('');

    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzComponentParams: {
        params: {
          acceptText: this.translateService.instant('main.btn.understood'),
          header: this.translateService.instant('deal.slot-form.days-unavailable'),
          message: messageText + messageDates,
        }
      },
    });

    this.subscriptions.push(modalRef.componentInstance.onAccept.subscribe(() => {
      modalRef.close();
      afterClosed();
    }));
    this.subscriptions.push(modalRef.componentInstance.onDecline.subscribe(() => {
      modalRef.close();
      afterClosed();
    }));
  }

  private openSlotIntersectionsModal(
    intersections: { time: string, item: MonthlyCalendarItem }[],
    days: Record<string, MonthlyCalendarDay>,
    item: MonthlyCalendarItem,
    afterSetup?: () => void,
    hoursSetupCb?: (hoursSetup: number) => void,
  ): void {
    const availableDates: AvailableIntersectionDates = {};

    for (const { time, item: lesson } of intersections) {
      const lessonDuration = timeStringToTimeSlotDiapason(lesson.time).length;
      const intersectionTimeDiapason = timeStringToTimeSlotDiapason(time);
      const date = new Date(lesson.date);
      const lessonDay = days[dateToYyyyMmDd(date)];
      date.setDate(date.getDate() - 1);
      const pastDay = days[dateToYyyyMmDd(date)];
      date.setDate(date.getDate() + 2);
      const nextDay = days[dateToYyyyMmDd(date)];

      availableDates[lesson.date] = [pastDay, lessonDay, nextDay]
        .filter(Boolean)
        .map(day => {
          return {
            date: day.date,
            isChecked: false,
            freeSlots: day.items
              .filter((item) => !item.isLesson)
              .reduce((acc, item) => acc
                  .concat(
                    splitFreeSlotsByLessonTimes(item.date, timeStringToTimeSlotDiapason(item.time), lessonDuration)
                      .map(time => ({ isChecked: false, time }))
                  ),
                [])
              .filter(slot => {
                const slotTimeDiapason = timeStringToTimeSlotDiapason(slot.time);
                if (slot.date !== lessonDay.date) {
                  return true;
                }

                for (const timeSlot of slotTimeDiapason) {
                  if (intersectionTimeDiapason.includes(timeSlot)) {
                    return false;
                  }
                }

                return true;
              }),
          }
        })
        .filter(day => day.freeSlots.length);
    }

    const modalRef = this.modalService.create({
      nzClosable: false,
      nzTitle: null,
      nzMaskClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzWrapClassName: 'slots-intersection-modal',
      nzContent: SlotsIntersectionModalComponent,
      nzComponentParams: {
        intersections,
        availableDates,
      },
    });

    modalRef.componentInstance.onDecline.subscribe(() => {
      modalRef.close();
    });

    modalRef.componentInstance.onAccept.subscribe((replaces: IntersectionsReplaces) => {
      const values = Object.values(replaces);
      this.setupItemsForDates(values.map(el => ({ ...el, item })), days, afterSetup, hoursSetupCb);
      modalRef.close();
    });
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
