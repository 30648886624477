import LessonDto, { LessonStatus, LessonType } from './dto/lesson.dto';

export const isRegularLesson = (lesson: LessonDto) =>
  lesson.type === LessonType.REGULAR;

export const isActiveRegularLesson = (lesson: LessonDto) =>
  lesson.type === LessonType.REGULAR && lesson.status !== LessonStatus.CANCELED;

export const isReservedRegularLesson = (lesson: LessonDto) =>
  lesson.type === LessonType.REGULAR && lesson.status === LessonStatus.RESERVED;
