import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

interface CustomParams {
  customWidth?: string;
  customFontSize?: string;
}

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss']
})
export class AppButtonComponent implements OnInit {
  @Input() text: string;
  @Input() btnId: string;
  @Input() icon: string;
  @Input() underlineAnimation: boolean;
  @Input() selected: boolean;
  @Input() disabled: boolean;
  @Input() buttonType: 'menuButton' | 'commonColourfulButton' | 'commonWhiteButton' | 'loading-file-btn' | 'remove-file-btn' | 'colourfulButtonWithFullWidth'| 'invincibleButton';
  @Input() customParams: CustomParams

  @Output() btnClick: EventEmitter<string> = new EventEmitter();


  ngOnInit(): void {
  }

  public onClick(): void {
    this.btnClick.emit(this.btnId ? this.btnId : 'no data');
  }

}
