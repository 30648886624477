import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { AuthService, UserRole } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      const roles = route.data.roles as UserRole[];

      if (roles.includes(this.authService.userRole)) {
        return true;
      }
    }

    return this.router.navigate(['login']);
  }
}
