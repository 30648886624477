<div class="page-wrapper">
  <div class="current-teacher-status">
    <div class="teacher-points">
      <div class="points-icon">
        <img *ngIf="_obtData?.level?.image" [src]="_obtData?.level?.image" alt="status-icon">
      </div>
      <div class="points-block">
        <div class="title">{{'my_points' | translate}}</div>
        <div class="current-points-value" [countUp]="_obtData?.points || 0"></div>
      </div>
      <div class="progress-bar-block" *ngIf="findMaxLevelValue() > (_obtData?.points || 0) else maxLevelContainer">
        <ng-container [ngSwitch]="translateService.currentLang">
          <div class="to-next-level-value" *ngSwitchCase="i18nLanguage.UA">
            <span class="common-text">{{'to_level' | translate}} </span>
            <span class="golden-text">{{getNextLevelData()?.name}} </span>
            <span class="common-text">{{'points_left' | translate}} </span>
            <span class="to-next-level-value" [countUp]="getNextLevelData()?.pointsFrom - _obtData?.points"></span>
            <span class="common-text"> {{ 'points' | translate}}</span>
          </div>
          <div class="to-next-level-value" *ngSwitchCase="i18nLanguage.PL">
            <span class="common-text">{{'do_poziomu' | translate}} </span>
            <span class="golden-text">{{getNextLevelData()?.name}} </span>
            <span class="common-text">{{'points_left3' | translate}} </span>
            <span class="to-next-level-value" [countUp]="getNextLevelData()?.pointsFrom - _obtData?.points"></span>
            <span class="common-text"> {{ 'points' | translate}}</span>
          </div>
          <div class="to-next-level-value" *ngSwitchDefault>
            <span class="common-text">{{'you_have' | translate}} </span>
            <span class="to-next-level-value" [countUp]="getNextLevelData()?.pointsFrom - _obtData?.points"></span>
            <span class="common-text"> {{'points_left2' | translate}} </span>
            <span class="golden-text">{{getNextLevelData()?.name}} </span>
            <span class="common-text"> {{ 'level' | translate}}</span>
          </div>
        </ng-container>

        <div class="progress-bar">
          <div class="current-status">{{_obtData?.level.name}}</div>
          <div class="bar">
            <div class="progress"
              style="width: {{getPercentageToNextLevel(_obtData?.level.pointsFrom, _obtData?.points, getNextLevelData()?.pointsFrom) || 0}}%">
            </div>
          </div>
          <div class="next-status">{{getNextLevelData()?.name}}</div>
        </div>
        <div class="prediction">
          <span class="common-text">{{'Level' | translate}} </span>
          <span class="golden-text">{{getNextLevelData()?.name}} </span>
          <span class="common-text">{{'new_income_level' | translate}} </span>
          <span class="golden-text" [countUp]="_obtData?.profit"></span>
          <span class="common-text"> {{'income_per_month' | translate}}</span>
        </div>
      </div>
      <ng-template #maxLevelContainer>
        <div class="max-level-container">
          <div class="alert-block">
            <div class="text common">{{'not_stop_on_achieved' | translate}}</div>
            <div class="text orange">{{'new_levels_will_come' | translate}}</div>
          </div>
        </div>
      </ng-template>
      <div class="teacher-achievements">
        <div class="title">{{'my_achievements' | translate}}</div>
        <div class="achievements-items-block">
          <button class="control" [disabled]="page === 0"
            (click)="previousBtnClick()"><mat-icon>chevron_left</mat-icon></button>
          <div class="achievements-items">
            <div class="wrapper">
              <div class="pages" style="margin-left: {{currentPosition}}px">
                <div class="achievement-item" *ngFor="let item of achievementsArr, index as i" #achievement>
                  <img *ngIf="(!item.isVisibleWhenInactive && item.state) || item.isVisibleWhenInactive"
                    [src]="item.photoUrl + (!item.state ? '-grey.svg' : '.svg')" [alt]="item.name" nz-tooltip
                    [nzTooltipTitle]="achievementTooltip">
                  {{getAchievementsWidth(achievement, i)}}
                  <ng-template #achievementTooltip>
                    <span class="math-tooltip-header">{{item.name | translate}}</span><br>
                    <span>{{item.name + '_description' | translate}}</span>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <button class="control" [disabled]="page === getNumberOfPages() - 5"
            (click)="nextBtnClick()"><mat-icon>chevron_right</mat-icon></button>
        </div>
      </div>
    </div>
    <button class="change-btn" (click)="clickChangeInfoBtn()"
      *ngIf="!authService.isTeacher()"><mat-icon>edit</mat-icon></button>
    <div class="statement-link" (click)="openStatementWindow()"><mat-icon>description</mat-icon><span
        class="statement-label">{{'statement-title' | translate}}</span></div>
  </div>
  <div class="teacher-calculator">
    <app-indicators-calc [userRewards]="_obtData" [userLevels]="userLevels"
      [lessonsPassedInp]="lessonsPassed"></app-indicators-calc>
  </div>
  <div class="teacher-statistics">
    <div class="title-block">
      <mat-icon>insert_chart</mat-icon>
      <div class="title">
        {{'statistics' | translate}}
      </div>
    </div>
    <div class="statistics-block">
      <div class="statistics-block-item" style="margin-right: 80px">
        <div class="title">{{'available_hours_per_week' | translate}}:</div>
        <div class="value black">{{_obtData?.availableHours || 0}} {{'hours' | translate}}</div>
      </div>
      <div class="statistics-block-item" style="margin-right: 96px">
        <div class="title">{{'parents_responses' | translate}}:</div>
        <div class="value green">{{_obtData?.feedbacks?.positive || 0}} {{_obtData?.feedbacks?.positive > 0 ?
          ('positive_plural' | translate) : ('positive' | translate)}}</div>
      </div>
      <div class="statistics-block-item" style="margin-right: 84px">
        <div class="title">{{'parents_responses' | translate}}:</div>
        <div class="value red">{{_obtData?.feedbacks?.negative || 0}} {{_obtData?.feedbacks?.negative > 0 ?
          ('negative_plural' | translate) : ('negative' | translate)}}</div>
      </div>
      <div class="statistics-block-item" style="margin-right: 80px">
        <div class="title">{{'regulation_violations' | translate}}:</div>
        <div class="value black">{{_obtData?.regulationViolations || 0}}</div>
      </div>
    </div>
    <button class="change-btn" (click)="clickChangeInfoBtn()"
      *ngIf="!authService.isTeacher()"><mat-icon>edit</mat-icon></button>
  </div>
</div>