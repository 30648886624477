import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CheckScheduleResult } from '../../../../api/services';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scheduleCheckData?: CheckScheduleResult;
      title: string;
      subTitle: string;
      confirmText: string;
      cancelText: string;
      onConfirm: () => void;
      onCancel: () => void;
      closeIcon: boolean;
      additionalInfo: {
        confirmDisable: boolean;
        confDisableData: any;
        confDisableErr: string;

        cancelDisable: boolean;
        cancelDisableData: any;
        cancelDisableErr: string;
      }
    },
    private dialog: MatDialog,
  ) {
  }

  closeDialog() {
    this.dialog.closeAll()
  }
}
