import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import LanguageService from '../../../shared/language/language.service';
import FreeHoursService from '../free-hours/free-hours.service';

@Component({
  selector: 'mathema-week-switcher',
  templateUrl: './week-switcher.component.html',
  styleUrls: ['./week-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekSwitcherComponent implements OnInit, OnDestroy {

  @Input() showLessonsLink: boolean;
  @Output() onGoToLessons: EventEmitter<void> = new EventEmitter<void>();

  private readonly $destroyed: Subject<void> = new Subject();

  public startDate: Date;
  public endDate: Date;

  constructor(
    private readonly languageService: LanguageService,
    private readonly freeHoursService: FreeHoursService,
  ) {
  }

  public ngOnInit(): void {
    this.initCurrentWeek();
    this.freeHoursService.$expandedTeacherWeekStart.next(this.startDate);
  }

  public ngOnDestroy(): void {
    this.$destroyed.next();
  }

  public onChangeWeek(addDays: number): void {
    this.startDate.setDate(this.startDate.getDate() + addDays);
    this.endDate.setDate(this.endDate.getDate() + addDays);

    this.freeHoursService.$expandedTeacherWeekStart.next(this.startDate);
  }

  private initCurrentWeek(): void {
    const startOfWeek = new Date();
    const endOfWeek = new Date();
    const today = (new Date()).getDay();

    const daysToStartOfWeek = today - 1;
    const daysToEndOfWeek = 7 - today;

    startOfWeek.setDate(startOfWeek.getDate() - daysToStartOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + daysToEndOfWeek);

    this.startDate = startOfWeek;
    this.endDate = endOfWeek;
  }

  public formatDate(date: Date): string {
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
    });

    return formatter.format(date);
  }
}
