import { HttpParams } from '@angular/common/http';
import { TIME_SLOTS_INDICES, TIME_SLOTS_VALUES } from '../constants';

export const setFirstZeroIfOneDigit = (n: number): string => (n < 10 ? `0${n}` : String(n));

export const dateToYyyyMmDd = (date: Date): string => {
  const year = date.getFullYear();
  const month = setFirstZeroIfOneDigit(date.getMonth() + 1);
  const day = setFirstZeroIfOneDigit(date.getDate());

  return `${year}-${month}-${day}`;
};

export const minutesToHours = (minutes: number): number => minutes / 60;

export const buildHttpParamsFromFormControlsQuery = (query: object) => {
  let params: HttpParams = new HttpParams();

  for (const key in query) {
    if (Array.isArray(query[key])) {
      for (const control of query[key]) {
        if (typeof control === 'string' || typeof control === 'number') {
          params = params.append(key + '[]', control);
        }
      }
    } else {
      params = params.append(key, query[key]);
    }
  }

  return params;
}

export const makeIntDiapason = (min: number, max: number): number[] => {
  const diapason = [];

  for (let i = min ; i < max; i++) {
    diapason.push(i);
  }

  return diapason;
}

export const splitNumbersArrayBySerialSegments = (numbers: number[]): number[][] => {
  const result: number[][] = [];

  let subArrayStartIdx = 0;
  for (let i = 0; i < numbers.length; i++) {
    if (numbers[i + 1] !== numbers[i] + 1) {
      result.push(numbers.slice(subArrayStartIdx, i + 1));
      subArrayStartIdx = i + 1;
    }
  }

  return result;
};

export const splitNumbersArrayByDiapasons = (numbers: number[], size: number): number[][] => {
  const result: number[][] = [];

  while (numbers.length) {
    result.push(numbers.splice(0, size));
  }

  return result;
}

export const timeStringToTimeSlotDiapason = (timeString: string): number[] => {
  const [startTime, endTime] = timeString.split(' - ');

  return makeIntDiapason(TIME_SLOTS_INDICES[startTime], TIME_SLOTS_INDICES[endTime]);
}

export const timeSlotDiapasonToTimeString = (diapason: number[]): string => {
  const first = diapason[0];
  const last = diapason[diapason.length - 1];

  return TIME_SLOTS_VALUES[first] + ' - ' + TIME_SLOTS_VALUES[last + 1]; // +1 we need to human-readable result, 16:00 instead of 15:55 etc.
}

export const daysBetweenDates = (start: string, end: string): number => {
  const startDate = Number(new Date(start));
  const endDate = Number(new Date(end));
  return (startDate - endDate) / (1000 * 60 * 60 * 24);
};

export const isPastTime = (date: string, time: string): boolean => {
  const now = new Date();
  const nowYyMmDd = dateToYyyyMmDd(now);

  if (nowYyMmDd === date) {
    const [, itemEndTime] = time.split(' - ');
    const itemEndTimeJsDate = new Date(`${date}T${itemEndTime}`);
    return +itemEndTimeJsDate < +now;
  }

  return daysBetweenDates(nowYyMmDd, date) > 0;
}
