import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { dateToYyyyMmDd, daysBetweenDates } from '../../../../../shared/utils';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
import ScheduleApiService from '../../schedule-api.service';
import ScheduleCalendarService from '../schedule-calendar.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mathema-schedule-calendar-vacations',
  templateUrl: './schedule-calendar-vacations.component.html',
  styleUrls: ['./schedule-calendar-vacations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarVacationsComponent implements OnInit, OnDestroy {

  @Output() onSuccess = new EventEmitter<void>();

  public isLoading = false;
  public isAddModeActive = true;
  public dates: Date[] = [];
  public ranges = {};

  private teacherId: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
  ) { }

  public ngOnInit(): void {
    this.teacherId = this.scheduleCalendarService.activeTeacher.value?.id;

    this.translateService.get('calendar.vacations.today').subscribe(message => {
      this.ranges[message] = [new Date(), new Date()];
    });
    this.translateService.get('calendar.vacations.this-week').subscribe(message => {
      this.ranges[message] = [new Date(), DateTime.now().endOf('week').toJSDate()];
    });
    this.translateService.get('calendar.vacations.next-week').subscribe(message => {
      this.ranges[message] = [
        DateTime.now().endOf('week').plus({ days: 1 }).toJSDate(),
        DateTime.now().endOf('week').plus({ days: 1 }).endOf('week').toJSDate(),
      ];
    });
  }

  public submit(): void {
    this.isLoading = true;

    const affectedDates = [];
    let date = DateTime.fromJSDate(this.dates[0]).toISODate();
    const endDate = DateTime.fromJSDate(this.dates[1]).toISODate();

    while (date !== endDate) {
      affectedDates.push(date);
      date = DateTime.fromISO(date).plus({ day: 1 }).toISODate();
    }
    affectedDates.push(endDate);

    const subscription = this.scheduleApiService.updateSchedulesVacations({
      teacherId: this.teacherId,
      dates: affectedDates,
      isVacation: this.isAddModeActive,
    }).pipe(
      finalize(() => this.isLoading = false),
    ).subscribe(() => {
      this.onSuccess.emit();
    });

    this.subscriptions.push(subscription);
  }

  public isDateYesterdayOrBefore(): (date: Date) => boolean {
    return (date: Date) => {
      return daysBetweenDates(dateToYyyyMmDd(new Date()), dateToYyyyMmDd(date)) > 0;
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
