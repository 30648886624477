<div class="report-modal">
  <div class="wrapper" *ngIf="reportData$ | async as report; else loadingOrError">
    <div class="report-loader" *ngIf="isLoading">
      <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
      <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
    </div>
    <div style="margin-bottom: 16px">
      <mathema-info-block [_type]="'warn'" [text]="'calendar.report.content-warn' | translate"></mathema-info-block>
    </div>
    <div class="report-info">
      <div class="info-block">
        <div class="title">{{'calendar.report.date' | translate}}</div>
        <div class="content date">{{formatDate(report.createdAt)}}</div>
      </div>
      <div class="info-block">
        <div class="title">{{'calendar.report.type' | translate}}</div>
        <div [class]="['content', 'type', report.type]">{{('calendar.report.type-' + report.type) | translate}}</div>
      </div>
      <div class="info-block" *ngIf="report.teacher">
        <div class="title">{{'calendar.report.teacher' | translate}}</div>
        <div class="content">{{report.teacher?.lastName}} {{report.teacher?.firstName}}</div>
      </div>
    </div>
    <form nz-form [formGroup]="reportForm" nzLayout="vertical">
      <nz-form-item>
        <nz-form-label nzFor="materials" *ngIf="fileList.length">{{'calendar.report.materials' | translate}}</nz-form-label>
        <nz-form-control>
          <div class="materials" *ngIf="fileList.length">
            <nz-upload
              [(nzFileList)]="fileList"
              [nzShowButton]="false"
              nzListType="picture-card"
              [nzPreview]="handleImagePreview"
              [nzRemove]="handleImageRemove"
            >
            </nz-upload>
          </div>
          <nz-upload
            [nzBeforeUpload]="beforeImageUpload"
            [nzPreview]="handleImagePreview"
            [nzShowButton]="fileList.length < 5"
            (nzChange)="handleUploadImageChange($event)"
            [nzMultiple]="true"
            [nzLimit]="5"
          >
            <div class="upload-controls">
              <button nz-button class="upload-button">{{'calendar.report.upload' | translate}}</button>
              <div class="upload-info">{{'calendar.report.upload-tip' | translate}}</div>
            </div>
          </nz-upload>
          <nz-modal
            [nzVisible]="isImagePreviewVisible"
            [nzContent]="modalContent"
            [nzFooter]="null"
            (nzOnCancel)="isImagePreviewVisible = false"
          >
            <ng-template #modalContent>
              <img alt="" [src]="imagePreview" [ngStyle]="{ width: '100%' }" />
            </ng-template>
          </nz-modal>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="knowledge">{{'calendar.report.knowledge' | translate}}</nz-form-label>
        <nz-form-control [nzErrorTip]="'calendar.report.required-tip' | translate">
           <textarea
             rows="2"
             formControlName="knowledge"
             nz-input
             [placeholder]="'calendar.report.knowledge-ph' | translate"
             nzAutosize
             maxlength="2048"
           ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="gaps">{{'calendar.report.gaps' | translate}}</nz-form-label>
        <nz-form-control [nzErrorTip]="'calendar.report.required-tip' | translate">
           <textarea
             rows="2"
             formControlName="gaps"
             nz-input
             [placeholder]="'calendar.report.gaps-ph' | translate"
             nzAutosize
             maxlength="2048"
           ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired nzFor="conclusion">{{'calendar.report.conclusion' | translate}}</nz-form-label>
        <nz-form-control [nzErrorTip]="'calendar.report.required-tip' | translate">
           <textarea
             rows="2"
             formControlName="conclusion"
             nz-input
             [placeholder]="'calendar.report.conclusion-ph' | translate"
             nzAutosize
             maxlength="2048"
           ></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzFor="links">{{'calendar.report.links' | translate}}</nz-form-label>
        <nz-form-control class="links">
          <nz-tag
            *ngFor="let link of links.controls; let index = index"
            nzMode="closeable"
            (nzOnClose)="onRemoveLink(index)"
            nz-tooltip
            [nzTooltipTitle]="link.value"
          >
            <a [href]="link.value" target="_blank">{{ sliceLinkValue(link.value) }}</a>
          </nz-tag>
          <ng-container *ngIf="links.controls.length < 5">
            <ng-container *ngIf="!linkInputVisible; else linkInput">
              <nz-tag class="editable-tag" nzNoAnimation (click)="showLinkInput()">
                <span nz-icon nzType="plus"></span>
                {{'calendar.report.new-link' | translate}}
              </nz-tag>
            </ng-container>
            <ng-template #linkInput>
              <input
                #linkInputElement
                nz-input
                nzSize="small"
                type="text"
                [(ngModel)]="linkInputValue"
                [ngModelOptions]="{standalone: true}"
                style="width: 80px;"
                (blur)="handleLinkInputConfirm()"
                (keydown.enter)="handleLinkInputConfirm()"
              />
            </ng-template>
          </ng-container>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div class="action-buttons">
      <button class="cancel" (click)="onCancel.emit()">{{'main.btn.cancel' | translate}}</button>
      <button class="accept" [disabled]="reportForm.invalid" (click)="onSubmit()">
        {{(editMode ? 'main.btn.save' : 'calendar.report.accept') | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #loadingOrError>
  <div class="error">
    <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  </div>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple class="report-modal-loader"></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
