import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { buildHttpParamsFromFormControlsQuery } from '../../../shared/utils';
import LessonDto, { CreateLessonDto, UpdateFirstLessonDto } from './dto/lesson.dto';
import { environment } from '../../../../environments/environment';
import ScheduleDto, { ScheduleListQuery, ScheduleValidateResponseDto } from './dto/schedule.dto';
import TeacherDto from './dto/teacher.dto';
import ReportDto from './dto/report.dto';

const API = environment.PifagorApiAddress;

export interface ScheduleContactsQuery {
  name: string;
  limit: number;
}

export type TeachersListQuery = {
  name?: string;
  studentIds?: string[];
}

export type ClientsListQuery = {
  name?: string;
}

export type StudentsListQuery = {
  name?: string;
  teacherId?: string;
}

export interface ContactListElement {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  isTeacher?: boolean;
  blockedAvailability?: boolean;
  blockedAt?: Date | null;
  notTakeNewStudentsAt?: Date | null;
}

export interface ScheduleVacationsRequest {
  teacherId: string;
  dates: string[];
  isVacation: boolean;
}

export enum SlotTypeFilter {
  ALL = 'all',
  ONLY_SLOTS = 'only-slots',
  ONLY_LESSONS = 'only-lessons'
}

export enum LessonTypeFilter {
  BOOKED = 'booked',
  RESERVED = 'reserved',
  FIRST = 'first',
  REGULAR = 'regular',
  SLOTS = 'slots'
}

export type CalendarViewQuery = {
  year?: number;
  month?: number;
  day?: number;
  dates?: string[];
  teacherId?: string;
  studentsIds?: string[];
  slotType?: SlotTypeFilter;
  lessonTypes?: LessonTypeFilter[];
}

export interface YearlyCalendarResponse {
  [key: number]: YearlyCalendarMonth;
}

export interface YearlyCalendarMonth {
  [key: number]: YearlyCalendarDay;
  offset: number;
}

export interface YearlyCalendarDay {
  lessons?: number;
  first?: boolean;
  regular?: boolean;
  reserved?: boolean;
  slots?: boolean;
  isToday?: boolean;
}

export interface MonthlyCalendarResponse {
  [key: number]: MonthlyCalendarDay;
}

export interface MonthlyCalendarDay {
  items?: MonthlyCalendarItem[];
  isToday?: boolean;
  isVacation?: boolean;
  date: string;
  isAffectedByFreeSlotsEditor?: boolean;
  scheduleRecordId?: string;
}

export interface MonthlyCalendarItem {
  id: string;
  type?: string;
  status?: string;
  date: string;
  time: string;
  isConducted?: boolean;
  dealId?: string;
  student?: {
    firstName: string;
    lastName: string;
  }
  isLesson?: boolean;
  markedForFutureSubscription?: boolean;
  isMarkedForReservation?: boolean;
  isLessonPaid?: boolean;
  isRepeat?: boolean;
  isConfirmedReservation?: boolean;
  isLessonReservation?: boolean;
  isReservationWithoutSlot?: boolean;
  isUIGeneratedSlot?: boolean;
  isDisabled?: boolean;
  isMarkedForFreeSlot?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export default class ScheduleApiService {
  constructor(private readonly http: HttpClient) {
  }

  public getContactsList(req: ScheduleContactsQuery): Observable<ContactListElement[]> {
    return this.http.get<ContactListElement[]>(`${API}/calendar/contacts`, { params: { ...req } });
  }

  public getTeachersList(query: TeachersListQuery = {}): Observable<ContactListElement[]> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<ContactListElement[]>(`${API}/teachers/list`, { params });
  }

  public getClientsList(query: ClientsListQuery = {}): Observable<ContactListElement[]> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<ContactListElement[]>(`${API}/clients/list`, { params });
  }

  public getTeacherById(id: string): Observable<TeacherDto> {
    return this.http.get<TeacherDto>(`${API}/teachers/${id}`);
  }

  public updateTeacherById(id: string, body: Partial<TeacherDto>): Observable<TeacherDto> {
    return this.http.put<TeacherDto>(`${API}/teachers/${id}`, body);
  }

  public updateTeacherTakingNewStudents(id: string, body: Pick<TeacherDto, 'notTakeNewStudentsAt'>): Observable<void> {
    return this.http.put<void>(`${API}/teachers/${id}/taking-new-students`, body);
  }

  public getLessonInfoById(id: string): Observable<LessonDto> {
    return this.http.get<LessonDto>(`${API}/lessons/${id}/general-info`);
  }

  public getConductedLessonDetails(lessonId: string): Observable<LessonDto> {
    return this.http.get<LessonDto>(`${API}/lessons/conducted/${lessonId}`);
  }

  public getReportById(reportId: string): Observable<ReportDto> {
    return this.http.get<ReportDto>(`${API}/reports/${reportId}`);
  }

  public createReport(formData: FormData): Observable<void> {
    return this.http.post<void>(`${API}/reports`, formData);
  }

  public editReport(id: string, formData: FormData): Observable<void> {
    return this.http.put<void>(`${API}/reports/${id}`, formData);
  }

  public moveLesson(id: string, freeSlotId: string, freeSlotTimeSlots: number[], notificationNeeded = false): Observable<void> {
    return this.http.post<void>(`${API}/lessons/${id}/move`, { freeSlotId, freeSlotTimeSlots, notificationNeeded });
  }

  public conductRegularLesson(id: string, formData: FormData): Observable<void> {
    return this.http.post<void>(`${API}/lessons/${id}/conduct/regular`, formData);
  }

  public conductFirstLesson(id: string, formData: FormData): Observable<void> {
    return this.http.post<void>(`${API}/lessons/${id}/conduct/first`, formData);
  }

  public editConductedLesson(id: string, formData: FormData): Observable<void> {
    return this.http.put<void>(`${API}/lessons/conducted/${id}`, formData);
  }

  public removeConductionFromLesson(id: string): Observable<void> {
    return this.http.post<void>(`${API}/lessons/${id}/remove-conduction`, {});
  }

  public cancelLesson(id: string): Observable<void> {
    return this.http.post<void>(`${API}/lessons/${id}/cancel`, {});
  }

  public skipLesson(id: string, sendNotification?: boolean): Observable<void> {
    return this.http.post<void>(`${API}/lessons/${id}/skip`, { sendNotification });
  }

  public getStudentsList(params: StudentsListQuery = {}): Observable<ContactListElement[]> {
    return this.http.get<ContactListElement[]>(`${API}/students/list`, { params });
  }

  public getSchedulesList(query: ScheduleListQuery): Observable<ScheduleDto[]> {
    return this.http.get<ScheduleDto[]>(`${API}/schedule/list`, { params: { ...query } });
  }

  public updateSchedulesVacations(data: ScheduleVacationsRequest): Observable<void> {
    return this.http.post<void>(`${API}/schedule/vacations`, data);
  }

  public upsertSchedules(schedules: ScheduleDto[]): Observable<ScheduleValidateResponseDto> {
    return this.http.post<ScheduleValidateResponseDto>(`${API}/schedule`, schedules);
  }

  public validateSchedules(schedules: ScheduleDto[], teacherId: string): Observable<ScheduleValidateResponseDto> {
    return this.http.post<ScheduleValidateResponseDto>(`${API}/schedule/validate/${teacherId}`, schedules);
  }

  public getStudentsNames(ids: string[]): Observable<ContactListElement[]> {
    const params = buildHttpParamsFromFormControlsQuery({ ids });
    return this.http.get<ContactListElement[]>(`${API}/students/names`, { params });
  }

  public getYearlyCalendar(query: CalendarViewQuery = {}): Observable<YearlyCalendarResponse> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<YearlyCalendarResponse>(`${API}/calendar/year`, { params });
  }

  public getMonthlyCalendar(query: CalendarViewQuery = {}): Observable<MonthlyCalendarResponse> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<MonthlyCalendarResponse>(`${API}/calendar/month`, { params });
  }

  public getWeeklyCalendar(query: CalendarViewQuery = {}): Observable<MonthlyCalendarResponse> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<MonthlyCalendarResponse>(`${API}/calendar/week`, { params });
  }

  public getDailyCalendar(query: CalendarViewQuery = {}): Observable<MonthlyCalendarDay> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<MonthlyCalendarDay>(`${API}/calendar/day`, { params });
  }

  public getSetOfDates(query: CalendarViewQuery = {}): Observable<MonthlyCalendarDay[]> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<MonthlyCalendarDay[]>(`${API}/calendar/dates`, { params });
  }

  public getSetOfDatesForDeal(query: CalendarViewQuery = {}, dealId: string): Observable<MonthlyCalendarDay[]> {
    const params = buildHttpParamsFromFormControlsQuery(query);
    return this.http.get<MonthlyCalendarDay[]>(`${API}/calendar/dates/deal/${dealId}`, { params });
  }

  public createLesson(body: CreateLessonDto): Observable<any> {
    return this.http.post(`${API}/lessons/create`, body);
  }

  public updateFirstLesson(id: string, body: UpdateFirstLessonDto): Observable<any> {
    return this.http.patch(`${API}/lessons/first/${id}`, body);
  }

  public checkNotConductedLessons(): Observable<any>{
    return this.http.get(`${API}/lessons/check-conduction`);
  }
}
