import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ContactListElement, LessonTypeFilter, SlotTypeFilter } from '../schedule-api.service';
import { TIME_SLOTS_INDICES, TIME_SLOTS_VALUES } from '../../../../shared/constants';
import {
  makeIntDiapason,
  splitNumbersArrayByDiapasons,
} from '../../../../shared/utils';
import { AuthService } from '../../auth/auth.service';

export const calendarCachedFiltersKey = 'cached-calendar-filters';

export enum ScheduleCalendarType {
  YEAR,
  MONTH,
  WEEK,
  DAY,
}

export interface ScheduleCalendarFilters {
  studentsIds?: string[];
  teacherId?: string;
  slotType?: SlotTypeFilter;
  lessonTypes?: LessonTypeFilter[];
}

export interface ScheduleCalendarActiveSlot {
  id: string;
  type: 'lesson' | 'freeSlot';
  slotItemId: string;
  slotItemTime: string;
  slotItemDate: string;
  freeSlotAction?: 'firstLesson' | 'additionalLesson';
}

const cachedCalendarType = localStorage.getItem('activeCalendarType');

@Injectable({
  providedIn: 'root',
})
export default class ScheduleCalendarService {
  public readonly calendarType =
    new BehaviorSubject<ScheduleCalendarType>(cachedCalendarType ? Number(cachedCalendarType) : ScheduleCalendarType.MONTH);
  public readonly calendarDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  public readonly calendarFilters: BehaviorSubject<ScheduleCalendarFilters> = new BehaviorSubject<ScheduleCalendarFilters>( null);
  public readonly activeSlot: BehaviorSubject<ScheduleCalendarActiveSlot> = new BehaviorSubject<ScheduleCalendarActiveSlot>(null);
  public readonly activeTeacher = new BehaviorSubject<ContactListElement>(null);

  public readonly diapasonDateChange: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

  constructor(
    private readonly authService: AuthService,
  ) {
    this.loadCachedFilters();
    this.calendarFilters.subscribe((calendarFilters) => {
      this.cacheFilters(calendarFilters);
    });
    this.activeTeacher.subscribe((teacher) => {
      if (!teacher) {
        this.activeSlot.next(null);
      }
    });
    this.setupTeacherId();
  }

  public refreshCalendarDate(): void {
    this.calendarDate.next(this.calendarDate.value);
  }

  private loadCachedFilters(): void {
    const cachedFilters = localStorage.getItem(calendarCachedFiltersKey);

    if (cachedFilters) {
      this.calendarFilters.next(JSON.parse(cachedFilters));
    }
  }

  private cacheFilters(filters: ScheduleCalendarFilters): void {
    localStorage.setItem(calendarCachedFiltersKey, JSON.stringify(filters));
  }

  public clearCachedFilters(): void {
    localStorage.removeItem(calendarCachedFiltersKey);
  }

  private setupTeacherId(): void {
    let teacherId;

    if (this.authService.isTeacher()) {
      teacherId = this.authService.user.teacherId;
    } else {
      teacherId = this.calendarFilters.value?.teacherId;
    }

    if (teacherId) {
      this.calendarFilters.next({ ...this.calendarFilters.value, teacherId });
    }
  }

  public isContactsCached(): boolean {
    const cachedFilters = localStorage.getItem(calendarCachedFiltersKey);

    if (cachedFilters) {
      const filters: ScheduleCalendarFilters = JSON.parse(cachedFilters);

      if (filters?.teacherId || filters?.studentsIds?.length) {
        return true;
      }

      return false;
    } else {
      return false
    }
  }

  public splitSlotTimeToDiapasons(slotTime: string, lessonTime: string): string[] {
    const [slotStartTime, slotEndTime] = slotTime.split(' - ');
    const slotTimeDiapason = makeIntDiapason(TIME_SLOTS_INDICES[slotStartTime], TIME_SLOTS_INDICES[slotEndTime]);

    const [lessonStartTime, lessonEndTime] = lessonTime.split(' - ');
    const lessonTimeDiapason = makeIntDiapason(TIME_SLOTS_INDICES[lessonStartTime], TIME_SLOTS_INDICES[lessonEndTime]);

    const converter = (diapason: number[]) => splitNumbersArrayByDiapasons([...diapason], lessonTimeDiapason.length)
      .filter((freeSlot) => freeSlot.length >= lessonTimeDiapason.length)
      .map((freeSlot) => {
        const startTime = TIME_SLOTS_VALUES[freeSlot[0]];
        const endTime = TIME_SLOTS_VALUES[freeSlot[freeSlot.length - 1] + 1];

        return `${startTime} - ${endTime}`;
      });


    const slotsTimesFromStart = converter(slotTimeDiapason);
    let slotsTimesFromStartPlus30Minutes = [];

    // duplicate slots with 30 minutes shift only if we build 60 or 90 minutes lessons
    if (lessonTimeDiapason.length > 6) {
      slotsTimesFromStartPlus30Minutes = converter(slotTimeDiapason.slice(6));
    }

    return [...slotsTimesFromStart, ...slotsTimesFromStartPlus30Minutes].sort((a, b) => a.localeCompare(b));
  }
}
