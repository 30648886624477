import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Teacher,
  User,
} from '../../../shared';
import {Observable} from "rxjs";
import { environment } from '../../../../environments/environment';
import { UserRole } from '../../components/auth/auth.service';
import { LanguageEnum } from '../../../shared/constants';

export default class UserDto {
  id: string;

  email: string;

  role: UserRole;

  teacherId?: string | null;

  clientId?: string | null;

  language: LanguageEnum;

  token?: string;

  firstName: string | null;

  lastName: string | null;

  gender: string | null;

  avatarLink: string | null;
}

export interface CheckScheduleResult {
  availability: boolean;
  weeks: { range: string; hours: number }[];
  confirmNeeded: boolean;
  students: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * Inject dependencies
   */
  public constructor(private http: HttpClient) {}

  /**
   * Get the list of teachers
   */
  public getTeachers(limit = 10): Observable<{ totalCount: number; data: Teacher[] }> {
    return this.http.get<{ totalCount: number; data: Teacher[] }>('/teachers', {
      params: {
        limit,
      },
    });
  }

  public login({ email, password }: { email: string; password: string }) {
    return this.http.post<User & { token: string }>('/user/log-in', {
      email,
      password,
    });
  }

  public pifagorApiLogin(email: string, password: string): Observable<UserDto> {
    return this.http.post<UserDto>(environment.PifagorApiAddress + '/auth/login',  {
      email,
      password,
    });
  }
}
