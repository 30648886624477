<nz-layout class="page">
    <div class="header">
        <nz-row>
            <nz-col class="title-block">
                <mathema-main-menu></mathema-main-menu>
                <h1 class="page-title">{{'calendar.timetable' | translate}}</h1>
            </nz-col>
        </nz-row>
    </div>
    <nz-content class="container">
        <nz-row>
            <nz-col class="illustration-container" nzSpan="24">
                <img class="ornament" src="/assets/schedule/start-ornament-left.svg">
                <img class="illustration" src="/assets/schedule/start-logo.svg">
                <img class="ornament" src="/assets/schedule/start-ornament-right.svg">
            </nz-col>
        </nz-row>
        <nz-row>
            <nz-col nzSpan="24">
                <p class="annotation">{{'calendar.start.select_to_show_timetable' | translate}}</p>
            </nz-col>
        </nz-row>
        <nz-row class="search-row">
            <nz-col nzSpan="24">
                <nz-input-group [nzPrefix]="queryPrefix">
                    <input nz-input class="input" type="text" [placeholder]="'calendar.start.teacher_or_students_search' | translate"
                        [(ngModel)]="searchQuery" [nzAutocomplete]="auto" (input)="onInput($event)"
                        (focus)="onInput($event)">
                    <nz-autocomplete #auto>
                        <nz-auto-option *ngFor="let contact of contacts" class="name-link"
                            (click)="proceedToCalendar(contact)" [nzValue]="getContactName(contact)">
                            {{getContactName(contact)}}
                        </nz-auto-option>
                    </nz-autocomplete>
                </nz-input-group>
                <ng-template #queryPrefix><span nz-icon nzType="search" nzTheme="outline"></span></ng-template>
            </nz-col>
        </nz-row>
    </nz-content>
</nz-layout>