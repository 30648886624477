// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // APIEndpoint: 'http://localhost:3000',// local
  APIEndpoint: 'https://api.matema-dev.loopback.name', // dev
  // APIEndpoint: 'https://api.matema-stage.loopback.name', // stage
  // APIEndpoint: 'https://api.pifagor.mathema.me', // prod
  // PifagorApiAddress: 'http://localhost:8080', // local
  PifagorApiAddress: 'https://pifagor-api.matema-dev.loopback.name', // dev
  // PifagorApiAddress: 'https://pifagor-api.matema-stage.loopback.name', // stage
  // PifagorApiAddress: 'https://pifagor-api.pifagor.mathema.me', // prod
  token: 'rA9YPn0UNqW46O9EQdvdPP1FcN6o6cmI',
  authorizationToken:
    'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTY0NDU4Nzk1NCwiaWF0IjoxNjQ0NTg3OTU0fQ.nP7WPKCikuYwnxdBTETHD69vWFlb0Af4R8NPfbnV_eM',
  username: 'admin',
  password: 'admin123456',
  apiKey: 'rA9YPn0UNqW46O9EQdvdPP1FcN6o6cmI',
  errorCodesExceptions: [939],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
