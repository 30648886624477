import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[define]',
})
export class DefineDirective {
  /**
   * Directive context
   */
  public context = new DefineContext();

  /**
   * Get dependencies
   */
  public constructor(
    private templateRef: TemplateRef<DefineContext>,
    private viewContainerRef: ViewContainerRef
  ) {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }

  /**
   * To make it structural
   */
  @Input('define')
  public set define(expression: any) {
    this.context.$implicit = expression;
  }
}

class DefineContext {
  /**
   * Implicit var
   */
  public $implicit: any;
}
