import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MathemaInfoBlockComponent } from './components/mathema-info-block/mathema-info-block.component';
import { MainMenuComponent } from '../modules/components/main-menu/main-menu.component';
import { MainMenuNavComponent } from '../modules/components/main-menu/main-menu-nav/main-menu-nav.component';
import { NzModule } from '../modules/nz-module';
import { SomeLoadingErrorComponent } from './components/some-loading-error/some-loading-error.component';
import { FilterPipe } from './pipes/filter-pipe';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, NzModule, TranslateModule, RouterModule, ReactiveFormsModule],
  declarations: [
    MathemaInfoBlockComponent,
    MainMenuComponent,
    MainMenuNavComponent,
    SomeLoadingErrorComponent,
    FilterPipe,
    ChangePasswordModalComponent,
  ],
  exports: [
    MathemaInfoBlockComponent,
    MainMenuComponent,
    MainMenuNavComponent,
    SomeLoadingErrorComponent,
    FilterPipe,
  ],
})
export default class SharedModule {}
