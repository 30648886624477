import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { CreateUserModalComponent } from './components/create-user-modal/create-user.modal.component';
import { ModalInformComponent } from './components/modal-inform/modal-inform.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IUser, UserService } from './services/user.service';
import {AuthService} from "../auth/auth.service";
import {AppRoutesDefinitions} from "../../../app.routes";
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../api/services';

export enum userRoleEnum {
  teacher = '1',
  manager = '2',
  admin = '2',
  teacherLabel = 'Вчитель',
  managerLabel = 'Менеджер',
  adminLabel = 'Адміністратор',
}

const rolesSet = {
  2: userRoleEnum.managerLabel,
  1: userRoleEnum.teacherLabel,
  3: userRoleEnum.adminLabel,
};

@Component({
  selector: 'app-users-old',
  templateUrl: './users-old.component.html',
  styleUrls: ['./users-old.component.scss'],
})
export class UsersOldComponent implements OnDestroy {
  displayedColumns: string[] = [
    'first_name',
    'last_name',
    'teacher_working_region',
    'role',
    'email',
    'teacher_id',
    'actions',
  ];
  public roles: any[] = [];

  public dataSource: MatTableDataSource<IUser>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private subscriptions = new Subscription();
  public routes = AppRoutesDefinitions;

  private teachersList;



  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private apiService: ApiService,
    protected translateService: TranslateService,
    public authService: AuthService,
  ) {
    this.load();
  }

  private async load(): Promise<any> {
    let [users, { data: teachers }] = await Promise.all([
      this.userService.getUsers().toPromise(),
      this.apiService.getTeachers(1000000).toPromise(),
    ]);

    users = users.map((item: IUser) => {
      return {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        teacher_id: item.teacher_id || '--',
        // @ts-ignore
        role: rolesSet[item.roleId],
      };
    });

    this.teachersList = teachers;

    this.roles = [
      { id: userRoleEnum.manager, name: userRoleEnum.managerLabel },
      { id: userRoleEnum.teacher, name: userRoleEnum.teacherLabel },
    ];

    this.dataSource = new MatTableDataSource(users);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = this.translateService.instant('elem-on-page');
      this.paginator._intl.getRangeLabel = (
        page: number,
        pageSize: number,
        length: number
      ) => {
        if (length === 0 || pageSize === 0) {
          return `0 ${this.translateService.instant('of')} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.translateService.instant('of')} ${length}`;
      };
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onRemoveUserClick(user: IUser) {
    this.dialog.open(ModalConfirmComponent, {
      data: {
        title: this.translateService.instant('delete-account'),
        subTitle: this.translateService.instant('delete-account-confirm'),
        confirmText: this.translateService.instant('yes'),
        cancelText: this.translateService.instant('no'),
        onConfirm: async () => {
          await this.userService.removeUser(user.id).toPromise();
          this.dialog.closeAll();
          await this.load();

          this.dialog.open(ModalInformComponent, {
            data: {
              subTitle: this.translateService.instant('account-was-deleted'),
              confirmText: this.translateService.instant('return-to-interface'),
            },
          });
        },
        onCancel: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  onCreateUserCLick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '800px';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.width = '80vw';
    dialogConfig.height = '65vh';
    dialogConfig.data = { roles: this.roles, teachers: this.teachersList };
    const dialogRef = this.dialog.open(CreateUserModalComponent, dialogConfig);

    dialogRef.componentInstance.onUserCreated.subscribe(() => {
      this.dialog.closeAll();
      this.load();
      this.dialog.open(ModalInformComponent, {
        data: {
          subTitle: this.translateService.instant('password-created'),
          confirmText: this.translateService.instant('return-to-interface'),
        },
      });
    });
  }

  public createRedirectLink(): string {
    return '/' + this.routes.VIEW_USER_INFO;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
