import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MonthlyCalendarDay, MonthlyCalendarItem } from '../../../schedule-api.service';
import LanguageService from '../../../../../../shared/language/language.service';
import DealService from '../../../../deal/deal.service';
import {
  dateToYyyyMmDd, minutesToHours,
  timeSlotDiapasonToTimeString,
  timeStringToTimeSlotDiapason
} from '../../../../../../shared/utils';

@Component({
  selector: 'mathema-remove-reservation-modal',
  templateUrl: './remove-reservation-modal.component.html',
  styleUrls: ['./remove-reservation-modal.component.scss']
})
export class RemoveReservationModalComponent implements OnInit {

  @Input() reservationItem: MonthlyCalendarItem;

  public repeatCount: number;
  public lastRepeatDate: string;

  constructor(
    public readonly modalRef: NzModalRef,
    private readonly languageService: LanguageService,
    private readonly dealService: DealService,
  ) { }

  public ngOnInit(): void {
    this.initRepeats();
  }

  private initRepeats(): void {
    const days = this.dealService.dealCalendarDays.value;
    const startDate = new Date(this.reservationItem.date);
    startDate.setDate(startDate.getDate() + 7);

    let repeats = 0;
    let lastRepeatDate = this.reservationItem.date;

    for (let i = 0; true; i++) {
      const repeatDate = new Date(startDate);
      repeatDate.setDate(repeatDate.getDate() + (7 * i));

      const day = days[dateToYyyyMmDd(repeatDate)];
      if (day && day.items.find(reservation => reservation.time === this.reservationItem.time && reservation.isConfirmedReservation)) {
        repeats += 1;
        lastRepeatDate = dateToYyyyMmDd(repeatDate);
      } else {
        break;
      }
    }

    this.repeatCount = repeats;
    this.lastRepeatDate = lastRepeatDate;
  }

  public onRemoveWithoutRepeats(): void {
    const days = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));
    const day: MonthlyCalendarDay = days[this.reservationItem.date];

    const itemToRemove = day.items.find(item => item.id === this.reservationItem.id);
    const mergingResult = this.dealService.removeSlotWithMergingItems(itemToRemove, day.items);
    day.items = mergingResult.newItems;

    const reservationsToRemove = this.dealService.reservationsIdsToRemove.value;
    if (itemToRemove.isLessonReservation) {
      this.dealService.reservationsIdsToRemove.next([...reservationsToRemove, itemToRemove.id]);
    }

    if (this.reservationItem.markedForFutureSubscription) {
      const { nextReservations } = this.dealService.scheduleMetrics.value;

      this.dealService.updateScheduleMetrics({
        nextReservations: nextReservations - mergingResult.hoursRemoved,
      });
    } else {
      const { setHours, reservedLessons } = this.dealService.scheduleMetrics.value;

      this.dealService.updateScheduleMetrics({
        setHours: setHours - mergingResult.hoursRemoved,
        reservedLessons: reservedLessons - mergingResult.hoursRemoved,
      });
    }

    this.dealService.dealCalendarDays.next(days);

    if (!this.reservationItem.markedForFutureSubscription) {
      this.dealService.updateBorderLessonsDates();
    }

    this.dealService.isScheduleChangesDetected.next(true);
    this.modalRef.close();
  }

  public onRemoveWithRepeats(): void {
    const days = JSON.parse(JSON.stringify(this.dealService.dealCalendarDays.value));
    let hoursRemoved = 0;
    let nextReservationsHoursRemoved = 0;

    const itemsIdsToRemove: string[] = [];

    for (let i = 0; i <= this.repeatCount; i++) {
      const repeatDate = new Date(this.reservationItem.date)
      repeatDate.setDate(repeatDate.getDate() + (7 * i));

      const day: MonthlyCalendarDay = days[dateToYyyyMmDd(repeatDate)];
      const itemToRemove = day.items.find(item => item.time === this.reservationItem.time);
      if (itemToRemove) {
        if (itemToRemove.isLessonReservation) {
          itemsIdsToRemove.push(itemToRemove.id);
        }
        const mergingResult = this.dealService.removeSlotWithMergingItems(itemToRemove, day.items);
        day.items = mergingResult.newItems;

        if (itemToRemove.markedForFutureSubscription) {
          nextReservationsHoursRemoved += mergingResult.hoursRemoved;
        } else {
          hoursRemoved += mergingResult.hoursRemoved;
        }
      }
    }

    const reservationsToRemove = this.dealService.reservationsIdsToRemove.value;
    this.dealService.reservationsIdsToRemove.next([...reservationsToRemove, ...itemsIdsToRemove]);


    const { setHours, reservedLessons, nextReservations } = this.dealService.scheduleMetrics.value;
    this.dealService.updateScheduleMetrics({
      nextReservations: nextReservations - nextReservationsHoursRemoved,
      setHours: setHours - hoursRemoved,
      reservedLessons: reservedLessons - hoursRemoved,
    });

    this.dealService.dealCalendarDays.next(days);

    if (!this.reservationItem.markedForFutureSubscription) {
      this.dealService.updateBorderLessonsDates();
    }

    this.dealService.isScheduleChangesDetected.next(true);
    this.modalRef.close();
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

}
