import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeUserInfoService {

  constructor() { }

  public validateField(data: any): any {
    let unlinkedData = JSON.parse(JSON.stringify(data));
    if(unlinkedData === 'null' || unlinkedData === 'undefined' || !unlinkedData){
      unlinkedData = undefined;
    }
    return unlinkedData;
  }
}
