import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import LanguageService from '../../../shared/language/language.service';
import ScheduleApiService, {
  MonthlyCalendarResponse,
  SlotTypeFilter
} from '../schedule/schedule-api.service';
import FreeHoursService from '../free-hours/free-hours.service';

@Component({
  selector: 'mathema-week-schedule',
  templateUrl: './week-schedule.component.html',
  styleUrls: ['./week-schedule.component.scss'],
})
export class WeekScheduleComponent implements OnInit {

  public $weekSchedule: Observable<MonthlyCalendarResponse> = null;
  public availability: any[] = null;
  public emptyDays = [0, 1, 2, 3, 4, 5, 6];
  public loading: boolean;
  private dateFrom: Date;

  @Input() $teacherId: Subject<string>;
  @Input() teacherId: string;
  @Input() isTeacherBlocked: boolean;

  constructor(
    public readonly languageService: LanguageService,
    public readonly authService: AuthService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly freeHoursService: FreeHoursService,
  ) {
  }

  public ngOnInit(): void {
    this.freeHoursService.$expandedTeacherWeekStart.subscribe((weekStart) => {
      if (!weekStart) {
        this.$weekSchedule = null;
        this.dateFrom = null;
        return;
      }

      this.dateFrom = weekStart;
      this.load();
    });

    if (this.$teacherId) {
      this.$teacherId.subscribe(teacherId => {
        this.teacherId = teacherId;
        this.load();
      });
    }
  }

  private load(): void {
    this.loading = true;

    if (!this.teacherId) {
      this.loading = false;
      this.$weekSchedule = null;
      return;
    }

    const calendarDate = new Date(this.dateFrom);
    if (calendarDate.getDay() !== 1) {
      const currentDay = calendarDate.getDay();
      calendarDate.setDate(calendarDate.getDate() - (currentDay - 1));
    }

    this.$weekSchedule = this.scheduleApiService.getWeeklyCalendar({
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth(),
      day: calendarDate.getDate(),
      teacherId: this.teacherId,
      slotType: SlotTypeFilter.ALL,
    }).pipe(finalize(() => this.loading = false));
  }

  public sortObjectNumericKeys(a, b): number {
    return a.key - b.key;
  }

  public formatWeekDayNameFromNumber(value: number): string {
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
    });

    return formatter.format(new Date(0, 0, value + 1));
  }

  public formatWeekDayNumberNameFromNumber(value: number): string {
    const date = new Date(this.dateFrom);
    date.setDate(date.getDate() + value);
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
    });

    return formatter.format(date);
  }

  public formatWeekDayName(value: string): string {
    const date = new Date(value);
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
    });

    return formatter.format(date);
  }

  public formatWeekDayNumber(value: string): string {
    const date = new Date(value);
    const formatter = new Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
    });

    return formatter.format(date);
  }
}
