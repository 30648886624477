<ng-container [ngSwitch]="type">
  <svg *ngSwitchCase="'first'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM8.5 10.75H7V4.75H5.5V3.25H8.5V10.75Z" [attr.fill]="color || '#40A9FF'"/>
  </svg>
  <svg *ngSwitchCase="'regular'" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 6.75H3V8.25H4.5V6.75ZM7.5 6.75H6V8.25H7.5V6.75ZM10.5 6.75H9V8.25H10.5V6.75ZM12 1.5H11.25V0H9.75V1.5H3.75V0H2.25V1.5H1.5C0.6675 1.5 0.00749999 2.175 0.00749999 3L0 13.5C0 14.325 0.6675 15 1.5 15H12C12.825 15 13.5 14.325 13.5 13.5V3C13.5 2.175 12.825 1.5 12 1.5ZM12 13.5H1.5V5.25H12V13.5Z" [attr.fill]="color || '#1CC28F'"/>
  </svg>
  <svg *ngSwitchCase="'reserved'" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.25 2.25H11.5V0.75H10V2.25H4V0.75H2.5V2.25H1.75C0.9175 2.25 0.2575 2.925 0.2575 3.75L0.25 14.25C0.25 15.075 0.9175 15.75 1.75 15.75H12.25C13.075 15.75 13.75 15.075 13.75 14.25V3.75C13.75 2.925 13.075 2.25 12.25 2.25ZM12.25 14.25H1.75V6H12.25V14.25ZM3.25 7.5H7V11.25H3.25V7.5Z" [attr.fill]="color || '#FFC368'"/>
  </svg>
  <svg *ngSwitchCase="'conducted'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2.5H13.25V1H11.75V2.5H5.75V1H4.25V2.5H3.5C2.675 2.5 2 3.175 2 4V14.5C2 15.325 2.675 16 3.5 16H14C14.825 16 15.5 15.325 15.5 14.5V4C15.5 3.175 14.825 2.5 14 2.5ZM3.5 5.5V4H14V5.5H3.5Z" [attr.fill]="color || '#1CC28F'"/>
    <path d="M11.03 8L11.825 8.795L9.60125 11.0188L7.3775 13.2425L5 10.865L5.795 10.07L7.3775 11.6525L11.03 8Z" [attr.fill]="color2 || '#fff'"/>
  </svg>
  <svg *ngSwitchCase="'add-slot'" class="add-slot" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 9H9V16H7V9H0V7H7V0H9V7H16V9Z" [attr.fill]="color || '#1B5E20'"/>
  </svg>
  <svg *ngSwitchCase="'check'" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" [attr.fill]="color || '#fff'"/>
  </svg>
  <svg *ngSwitchCase="'paid'" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 0H2C1.1675 0 0.5075 0.6675 0.5075 1.5L0.5 10.5C0.5 11.3325 1.1675 12 2 12H5.75V10.5H2V6H15.5V1.5C15.5 0.6675 14.8325 0 14 0ZM14 3H2V1.5H14V3ZM10.1975 11.3775L8.075 9.255L7.0175 10.3125L10.1975 13.5L15.5 8.1975L14.4425 7.14L10.1975 11.3775Z" [attr.fill]="color || '#A57AFF'"/>
  </svg>
  <svg *ngSwitchCase="'bin'" class="bin" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.35156 12.25C1.35156 13.075 2.02656 13.75 2.85156 13.75H8.85156C9.67656 13.75 10.3516 13.075 10.3516 12.25V3.25H1.35156V12.25ZM3.19656 6.91L4.25406 5.8525L5.85156 7.4425L7.44156 5.8525L8.49906 6.91L6.90906 8.5L8.49906 10.09L7.44156 11.1475L5.85156 9.5575L4.26156 11.1475L3.20406 10.09L4.79406 8.5L3.19656 6.91ZM8.47656 1L7.72656 0.25H3.97656L3.22656 1H0.601562V2.5H11.1016V1H8.47656Z" [attr.fill]="color || '#613CB0'" />
  </svg>
  <svg *ngSwitchCase="'edit'" class="edit" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.25 11.3739V14.1864H3.0625L11.3575 5.89145L8.545 3.07895L0.25 11.3739ZM13.5325 3.71645C13.825 3.42395 13.825 2.95145 13.5325 2.65895L11.7775 0.903945C11.485 0.611445 11.0125 0.611445 10.72 0.903945L9.3475 2.27645L12.16 5.08895L13.5325 3.71645Z" [attr.fill]="color || '#fff'"/>
  </svg>
</ng-container>
