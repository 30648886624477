<nz-layout class="base">
    <nz-layout class="base-container">
        <nz-row nzJustify="space-between"><nz-col [nzXs]="24" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                <div class="logo">
                    <img src="assets/Logo_sm.png" alt="Logo">
                </div>
            </nz-col>
            <nz-col [nzXs]="24" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                <app-language-switch></app-language-switch>
            </nz-col>
        </nz-row>
        <nz-row><nz-col [nzXs]="24" [nzSm]="20" [nzMd]="20" [nzLg]="20">
                <div class="header mobile-align-center">
                    <p class="head-line">{{'forgot-password' | translate}}</p>
                    <p class="annotation">{{'reset-password-annotation' | translate}}</p>
                </div>
            </nz-col></nz-row>
        <nz-row nzJustify="space-evenly">
            <nz-col [nzXs]="{span: 24}" [nzSm]="{span: 14, order: 2}" [nzMd]="{span: 14, order: 2}"
                [nzLg]="{span: 14, order: 2}" class="align-box">
                <img class="illustration" src="assets/login/password-restore.svg">
            </nz-col>
            <nz-col [nzXs]="{span: 24}" [nzSm]="{span: 9, order: 1}" [nzMd]="{span: 9, order: 1}"
                [nzLg]="{span: 9, order: 1}">
                <div class="form-block">
                    <div class="input-group">
                        <span class="input-label">{{'registration-email' | translate}}</span>
                        <nz-input-group [nzPrefix]="usernamePrefix">
                            <input nz-input class="input" type="email" id="email" name="email" [(ngModel)]="email"
                            required>
                        </nz-input-group>
                    </div>
                    <ng-template #usernamePrefix><span nz-icon><img
                                src="assets/login/username-prefix.svg"></span></ng-template>

                    <div class="button">
                        <button nz-button (click)="restore()">
                            {{'password-reset' | translate}}
                        </button>
                    </div>
                </div>
                <div class="links">
                    <div class="registration">{{'no-account' | translate}} <a class="registration-link"
                            routerLink="/signup">{{'signup' | translate}}</a></div>
                    <div class="login">{{'have-account' | translate}} <a class="login-link" routerLink="/login"
                            router>{{'signin' | translate}}</a></div>
                </div>
            </nz-col>
        </nz-row>
    </nz-layout>
</nz-layout>
