  <div class="header">
    <div class="logo">
      <div class="main-menu"><mathema-main-menu></mathema-main-menu></div>
      <img src="assets/Logo_sm.png" alt="Logo" />
    </div>
    <p></p>
  </div>

  <div class="content-block">
    <div class="btn-block">
      <button
        mat-button
        mat-raised-button
        color="primary"
        (click)="onCreateUserCLick()"
      >
        {{'create-new-user' | translate}}
      </button>
    </div>

    <!--  <div *ngIf="state?.isFetching" class='spinner'>-->
    <!--    <mat-spinner></mat-spinner>-->
    <!--  </div>-->

    <div>
      <mat-form-field appearance="standard">
        <mat-label>{{'search' | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="" matSort>
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="long-column">{{'name' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element['teacher_id'] else content" target="_blank" [routerLink]="createRedirectLink()" [queryParams]="{teacherId: element['teacher_id']}" routerLinkActive="active" style="position: relative">
              {{ element.first_name && element.first_name !== 'null' ? element.first_name : '---------------' }}
            </a>
            <ng-template #content>
              {{ element.first_name && element.first_name !== 'null' ? element.first_name : '' }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="long-column">{{'surname' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.last_name && element.last_name !== 'null' ? element.last_name : '' }}</td>
        </ng-container>

        <ng-container matColumnDef="teacher_working_region">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="long-column">{{'region' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.teacher_working_region && element.teacher_working_region !== 'null' ? element.teacher_working_region : '' }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="medium-column">{{'role' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.role === 'TEACHER' ? ('teacher' | translate) : element.role === 'MANAGER' ? ('manager' | translate) : element.role === 'ADMIN' ? ('admin' | translate) : '' }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="long-column">{{'email' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="teacher_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="short-column">CrmId</th>
          <td mat-cell *matCellDef="let element" class="crmId-field" matTooltip="{{element['teacher_id'] ? element['teacher_id'] : ''}}" matTooltipShowDelay="200" matTooltipPosition="above">
            {{ element['teacher_id'] || '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="short-column"></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="(authService.isManager() && element.role !== 'ADMIN') || authService.isAdmin()" class="icon" (click)="onRemoveUserClick(element)"
              >delete_forever</mat-icon
            >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            {{'no-requested-data' | translate}} "{{ input.value }}"
          </td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
      ></mat-paginator>
    </div>
  </div>
