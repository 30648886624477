<div class="move-reservation-modal-loader" *ngIf="isLoading">
  <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
  <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
</div>
<div class="move-reservation-modal-wrap">
  <div class="header">
    <div>{{'calendar.move-reservation.moving' | translate}}</div>
    <span nz-icon nzType="close" (click)="modalRef.close()"></span>
  </div>
  <div class="body">
    <div class="lesson-details">
      <div>
        <span class="label">{{'calendar.move-reservation.lesson-datetime' | translate}}:</span>
        <span class="value"><span class="date">{{formatDate(reservationItem.date)}}</span> <span class="time">{{reservationItem.time}}</span></span>
      </div>
    </div>
    <div class="lesson-details">
      <div>
        <span class="label">{{'main.labels.repeat-count' | translate}}:</span>
        <span class="value">{{repeatCount}}</span>
      </div>
      <div>
        <span class="label">{{'main.labels.last-repeat' | translate}}:</span>
        <span class="value">{{formatDate(lastRepeatDate)}}</span>
      </div>
    </div>
    <div class="lesson-details">
      <div>
        <div class="label">{{'calendar.move-reservation.moving-date' | translate}}</div>
        <nz-date-picker
          [nzAllowClear]="false"
          [ngModel]="selectedDate"
          (ngModelChange)="onChangeMoveDate($event)"
          [nzDisabledDate]="isDateYesterdayOrBefore()"
          [nzShowToday]="false"
          nzFormat="dd MMMM yyyy"
        ></nz-date-picker>
      </div>
    </div>
    <div class="lesson-details">
      <div class="slots">
        <ng-container *ngIf="!selectedDate && !freeTimes">
          <mathema-info-block [text]="'calendar.move-reservation.choose-date' | translate" _type="info"></mathema-info-block>
        </ng-container>
        <ng-container *ngIf="selectedDate && !freeTimes">
          <mathema-info-block [text]="'calendar.move-reservation.no-time' | translate" _type="warn"></mathema-info-block>
        </ng-container>
        <ng-container *ngIf="freeTimes">
          <nz-tag
            [nzChecked]="selectedTime === time"
            nzMode="checkable"
            (nzCheckedChange)="onTimeChecked($event, time)"
            *ngFor="let time of freeTimes"
          >
            {{time}}
          </nz-tag>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="footer">
    <button nz-button class="cancel" (click)="modalRef.close()">{{'main.btn.decline' | translate}}</button>
    <button nz-button class="move-with-repeats" *ngIf="repeatCount > 0" (click)="onMoveWithRepeats()" [disabled]="!selectedTime">
      {{'main.btn.move_with_repeats' | translate}}
    </button>
    <button nz-button class="move" (click)="onMoveWithoutRepeats()" [disabled]="!selectedTime">{{'main.btn.move' | translate}}</button>
  </div>
</div>

