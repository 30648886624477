import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import ScheduleApiService, { SlotTypeFilter, ContactListElement } from '../schedule-api.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutesDefinitions } from 'src/app/app.routes';
import ScheduleCalendarService, { ScheduleCalendarFilters } from '../calendar/schedule-calendar.service';

const cachedContactsKey = 'scheduleCachedContacts';
const autocompleteLimit = 8;

@Component({
  selector: 'mathema-schedule-start',
  templateUrl: './schedule-start.component.html',
  styleUrls: ['./schedule-start.component.scss']
})
export class ScheduleStartComponent implements OnInit {

  contacts: ContactListElement[] = [];
  searchQuery = '';
  private getDataSub: Subscription;

  constructor(
    private readonly scheduleApiService: ScheduleApiService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly router: Router,
    private readonly scheduleCalendar: ScheduleCalendarService
  ) { }

  ngOnInit(): void { }

  onInput(_event): void {
    if (this.searchQuery.length > 0)
      this.loadContacts();
    else this.loadCachedContacts();
  }

  private loadContacts(): void {
    if (this.getDataSub) {
      this.getDataSub.unsubscribe();
    }

    this.getDataSub = this.scheduleApiService.getContactsList({
      name: this.searchQuery,
      limit: autocompleteLimit,
    }).pipe(map(data => {
      return data;
    }))
      .subscribe(contacts => {
        this.contacts = contacts;
      });
  }

  getContactName(contact: ContactListElement): string {
    return contact.lastName + ' ' + contact.firstName;
  }

  loadCachedContacts(): void {
    const cachedContacts = localStorage.getItem(cachedContactsKey);

    if (cachedContacts) {
      this.contacts = JSON.parse(cachedContacts);
    }
  }

  proceedToCalendar(contact: ContactListElement): void {
    this.cacheContact(contact);
    this.loadCalendar(contact);
  }

  cacheContact(contact: ContactListElement): void {
    const cachedContacts = localStorage.getItem(cachedContactsKey);

    if (cachedContacts) {
      const toCache = JSON.parse(cachedContacts);
      if (!toCache.find(c => c.id === contact.id)) {
        if (toCache.length >= autocompleteLimit) toCache.shift();
        toCache.push(contact);
        localStorage.setItem(cachedContactsKey, JSON.stringify(toCache));
      }
    } else {
      const toCache = [];
      toCache.push(contact);
      localStorage.setItem(cachedContactsKey, JSON.stringify(toCache));
    }
  }

  loadCalendar(contact: ContactListElement): void {
    const filters: ScheduleCalendarFilters = {};
    if (contact.isTeacher) {
      filters['teacherId'] = contact.id;
      filters['slotType'] = SlotTypeFilter.ALL;
    } else {
      filters['studentsIds'] = [contact.id];
      filters['slotType'] = SlotTypeFilter.ONLY_LESSONS;
    }

    this.scheduleCalendar.calendarFilters.next(filters);

    this.router.navigate([AppRoutesDefinitions.CALENDAR]);
  }

}
