import { NgModule } from '@angular/core';
import { httpInterceptors } from './interceptors/http-interceptors';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './services';

@NgModule({
  imports: [HttpClientModule],
  providers: [ApiService, ...httpInterceptors],
})
export class ApiModule {}
