import { Component } from '@angular/core';
import en from '@angular/common/locales/en';
import pl from '@angular/common/locales/pl';
import uk from '@angular/common/locales/uk';
import { registerLocaleData } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
registerLocaleData(en);
registerLocaleData(pl);
registerLocaleData(uk);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    public readonly translateService: TranslateService,
  ) {
    const userLanguage = localStorage.getItem('userLanguage');
    if (userLanguage) {
      this.translateService.use(userLanguage.toLowerCase());
    }
  }
}
