<button (click)="isOpened = !isOpened; onOpenButtonClicked.emit()" id="main-menu-open-btn"></button>

<nz-drawer
  [nzClosable]="false"
  (nzOnClose)="isOpened=false"
  [nzVisible]="isOpened"
  [nzContent]="mainMenuNav"
  [nzBodyStyle]="{
    padding: 0
  }"
  [nzTitle]="mainMenuTitle"
  [nzCloseOnNavigation]="true"
  nzTitle="Mathemame"
  nzPlacement="left"
  nzWidth="280px"
></nz-drawer>

<ng-template #mainMenuNav>
  <mathema-main-menu-nav></mathema-main-menu-nav>
</ng-template>

<ng-template #mainMenuTitle>
  <div class="main-menu-title">
    <img src="assets/Logo_sm.png" alt="Mathema.me" width="150" height="40" />
    <button (click)="isOpened = false" id="main-menu-close-btn"></button>
  </div>
</ng-template>
