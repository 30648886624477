<div class="container">
  <img *ngIf="data.closeIcon" class='close-icon' src='assets/close.svg' (click)='closeDialog()' alt=''>
  <h2>
    {{ data.title }}
  </h2>

  <div class="block">
    <h3>
      {{ data.subTitle }}
    </h3>
  </div>

  <div *ngIf="data?.scheduleCheckData?.availability === false" class="delete-tip">
    <div *ngIf="data.scheduleCheckData?.weeks?.length > 0; else fullRangeRestriction">
      <div class="text">
        <span nz-icon nzType="exclamation-circle" nzTheme="outline"></span>
        <span>{{'remove_slot_err_weeks_header' | translate}}</span><br /><br />
        <span *ngFor="let week of data.scheduleCheckData.weeks">
          <span>{{'from' | translate}} {{week.range.split(' - ')[0]}} {{'to' | translate}} {{week.range.split(' - ')[1]}} {{'will_be_opened' | translate}} {{week.hours}} {{'hours' | translate}}</span><br />
        </span><br />
        <span>{{'remove_slot_err_weeks_footer' | translate}}</span>
      </div>
      </div>
    <ng-template #fullRangeRestriction>
      <div class="text">
        <span nz-icon nzType="exclamation-circle" nzTheme="outline"></span>
        {{'remove_slot_err_big_period' | translate}}
      </div>
    </ng-template>
  </div>

  <div *ngIf="data?.scheduleCheckData?.availability === true && data?.scheduleCheckData?.students?.length > 0" class="delete-tip students">
    <div class="text">
      <span nz-icon nzType="exclamation-circle" nzTheme="outline"></span>
      {{'remove_slot_warn_students' | translate}} {{data.scheduleCheckData.students.join((', '))}}
    </div>
  </div>

  <div class="buttons-container">
    <div
      [matTooltipDisabled]="!data.additionalInfo?.confirmDisable"
      [matTooltip]="data.additionalInfo?.confDisableErr ? (data.additionalInfo.confDisableErr | translate:data.additionalInfo.confDisableData) : ''"
      matTooltipPosition="above">
      <button
      *ngIf="data.onConfirm"
      mat-button
      mat-raised-button
      color="primary"
      [disabled]="data.additionalInfo?.confirmDisable || data?.scheduleCheckData?.availability === false"
      (click)="data.onConfirm()"
    >
      {{ data.confirmText }}
    </button>
    </div>
    <div
      [matTooltipDisabled]="!data.additionalInfo?.cancelDisable"
      [matTooltip]="data.additionalInfo?.cancelDisableErr ? (data.additionalInfo.cancelDisableErr | translate:data.additionalInfo.cancelDisableData) : ''"
      matTooltipPosition="above">
      <button
      *ngIf="data.onCancel"
      mat-button
      mat-raised-button
      color="primary"
      [disabled]="data.additionalInfo?.cancelDisable"
      (click)="data.onCancel()"
    >
      {{ data.cancelText }}
    </button>
    </div>
  </div>
</div>
