import { Component, Input } from '@angular/core';
import {GetRewardsModel, IndicatorsInitialSettings, LevelModel} from "../../models/indicators";
import { FormControl, Validators } from "@angular/forms";
import {TipsArr, TipsModel} from "../../models/tips";
import {animate, state, style, transition, trigger} from "@angular/animations";
import { I18nLanguage } from '../../../../../shared/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-indicators-calc',
  templateUrl: './indicators-calc.component.html',
  styleUrls: ['./indicators-calc.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('hidden <=> visible', [
        animate('0.3s ease')
      ])
    ])
  ]
})
export class IndicatorsCalcComponent {
  public readonly initialSettings: typeof IndicatorsInitialSettings = IndicatorsInitialSettings;
  public _userRewards: GetRewardsModel;
  public _lessonsPassedInp: number;
  public tipsArr: TipsModel[] = JSON.parse(JSON.stringify(TipsArr));
  public tipsCurrentIndex: number = 0;
  public currentState: string = 'hidden';
  private tmpIndex: number = 0;
  public readonly i18nLanguage: typeof I18nLanguage = I18nLanguage;

  @Input() public userLevels: LevelModel[] = [];
  @Input() public set userRewards (rewards: GetRewardsModel) {
    this._userRewards=rewards;
    this.positiveResponses.setValue(rewards?.feedbacks?.positive || 0);
    this.positiveResponses.setValidators([Validators.min(rewards?.feedbacks?.positive || 0), Validators.max(this.initialSettings.positiveResponsesMaxValue)])
    this.negativeResponses.setValue(rewards?.feedbacks?.negative || 0);
    this.negativeResponses.setValidators([Validators.min(rewards?.feedbacks?.negative || 0), Validators.max(this.initialSettings.negativeResponsesMaxValue)])
    this.regulationViolation.setValue(rewards?.regulationViolations || 0)
    this.regulationViolation.setValidators([Validators.min(rewards?.regulationViolations || 0), Validators.max(this.initialSettings.regulationViolationMaxValue)])
  };
  @Input() public set lessonsPassedInp (lessonsPassed: number) {
    this._lessonsPassedInp = lessonsPassed;
    this.lessonsPassed.setValue(lessonsPassed);
    this.lessonsPassed.setValidators([Validators.min(lessonsPassed), Validators.max(this.initialSettings.lessonsPassedMaxValue)])
  }
  public addAchievementsPoints: boolean = false;
  public positiveResponses: FormControl = new FormControl(0);
  public lessonsPassed: FormControl = new FormControl(0);
  public negativeResponses: FormControl = new FormControl(0);
  public regulationViolation: FormControl = new FormControl(0);

  constructor(
    public translateService: TranslateService,
  ) {
  }

  public getPercentageValue(): number {
    const tmp = 100/(this.userLevels.length-1);
    let res: number = 0;
    let targetLevelIndex: number = 0;
    this.userLevels.forEach((level, i)=>{
      if (i < this.userLevels.length-1){
        if (this.calculateTeachersPoints().summarise >= level.pointsFrom && this.calculateTeachersPoints().summarise < this.userLevels[i+1]?.pointsFrom){
          targetLevelIndex = i+1
          res = tmp * i;
        }
      } else if (this.calculateTeachersPoints().summarise > this.userLevels[this.userLevels.length-1].pointsFrom) {
        res = 100
      }
    })
    if(res <= 100 && this.userLevels[targetLevelIndex]?.pointsFrom){
      res += (this.calculateTeachersPoints().summarise - this.userLevels[targetLevelIndex - 1].pointsFrom)/(this.userLevels[targetLevelIndex].pointsFrom - this.userLevels[targetLevelIndex - 1].pointsFrom)*tmp;
    }
    return res;
  }

  public getActiveLevelLevel(index: number): boolean {
    if (index !== this.userLevels.length-1){
      return this.calculateTeachersPoints().levelVal >= this.userLevels[index].pointsFrom && this.calculateTeachersPoints().levelVal < this.userLevels[index+1].pointsFrom
    } else if (index === this.userLevels.length-1) {
      return this.calculateTeachersPoints().levelVal >= this.userLevels[index].pointsFrom
    } else {
      return false;
    }
  }

  public calculateTeachersPoints(): {positiveResponses: string; lessonsPassed: string; negativeResponses: string; regulationViolation: string; achievementsPoints: string; summarise: number; levelVal: number;} {
    let achievementsPoints: number = 0;
    let posRes: number;
    if (this.positiveResponses.value > this.initialSettings.positiveResponsesMaxValue) {
      posRes = this.initialSettings.positiveResponsesMaxValue;
    } else if (this.positiveResponses.value < this._userRewards?.feedbacks?.positive) {
      posRes = this._userRewards.feedbacks.positive;
    } else {
      posRes = this.positiveResponses.value;
    }
    let negRes: number;
    if (this.negativeResponses.value > this.initialSettings.negativeResponsesMaxValue) {
      negRes = this.initialSettings.negativeResponsesMaxValue;
    } else if (this.negativeResponses.value < this._userRewards?.feedbacks?.negative) {
      negRes = this._userRewards.feedbacks.negative;
    } else {
      negRes = this.negativeResponses.value;
    }
    let regVal: number;
    if (this.regulationViolation.value > this.initialSettings.regulationViolationMaxValue) {
      regVal = this.initialSettings.regulationViolationMaxValue;
    } else if (this.regulationViolation.value < this._userRewards?.regulationViolations) {
      regVal = this._userRewards.regulationViolations;
    } else {
      regVal = this.regulationViolation.value;
    }
    let lesPas: number;
    if (this.lessonsPassed.value > this.initialSettings.lessonsPassedMaxValue) {
      lesPas = this.initialSettings.lessonsPassedMaxValue;
    } else if (this.lessonsPassed.value < this._lessonsPassedInp) {
      lesPas = this._userRewards.regulationViolations;
    } else {
      lesPas = this.lessonsPassed.value;
    }


    if(this._userRewards?.rewards && this.addAchievementsPoints){
      this._userRewards.rewards.forEach(reward=>{
        achievementsPoints += reward.points;
      })
    }
    const result = {
      positiveResponses: `${posRes === 0 ? '' : '+'}${posRes * 2}`,
      lessonsPassed: `${lesPas === 0 ? '' : '+'}${lesPas * 0.25}`,
      negativeResponses: `${negRes * (-2)}`,
      regulationViolation: `${regVal * (-4)}`,
      achievementsPoints: `+${achievementsPoints}`,
      summarise: posRes * 2 +
        lesPas * 0.25 +
        negRes * (-2) +
        regVal * (-4) +
        achievementsPoints,
      levelVal: (posRes * 2 +
        lesPas * 0.25 +
        negRes * (-2) +
        regVal * (-4) +
        achievementsPoints) > 0 ?
        (posRes * 2 +
          lesPas * 0.25 +
          negRes * (-2) +
          regVal * (-4) +
          achievementsPoints) : 0
    }

    if (this._userRewards?.minLevel) {
      result.levelVal += this._userRewards.minLevel.pointsFrom;
    }

    return result;
  }

  public tooltipControl(action: 'next' | 'previous'): void {
    switch (action) {
      case "next": {
        this.changeCurrentIndex();
        if (this.tmpIndex === this.tipsArr.length-1){
          this.tmpIndex = 0;
        } else {
          this.tmpIndex++;
        }
        break;
      }
      case "previous": {
        this.changeCurrentIndex();
        if (this.tmpIndex === 0){
          this.tmpIndex = this.tipsArr.length-1;
        } else {
          this.tmpIndex--;
        }
        break;
      }
      default: {
        return;
      }
    }
  }



  changeCurrentIndex() {
    this.currentState = 'hidden';
  }

  animationFinished(event: any) {
    if (event.fromState === 'void' && event.toState === 'hidden') {
      this.currentState = 'visible';
    } else if (event.fromState === 'visible' && event.toState === 'hidden') {
      this.tipsCurrentIndex = this.tmpIndex
      this.currentState = 'visible';
    }
  }

}
