<ng-container *ngIf="lessonData$ | async as lesson; else loadingOrError">
  <div class="loading-overlay" *ngIf="isLoading">
    <nz-spin></nz-spin>
  </div>
  <div class="header">
    <div>{{'calendar.lesson-transfer.title' | translate}}</div>
    <span nz-icon nzType="close" (click)="modalRef.close()"></span>
  </div>
  <div class="body">
    <div class="lesson-details" >
      <div>
        <span class="label">{{'calendar.lesson-details.deal_number' | translate}}</span>
        <span class="value">{{lesson.deal.dealNumber}}</span>
      </div>
      <div>
        <span class="label">{{'student' | translate}}</span>
        <span class="value">{{lesson.deal.student.lastName}} {{lesson.deal.student.firstName}}</span>
      </div>
      <div>
        <span class="label">{{'teacher' | translate}}</span>
        <span class="value">{{lesson.teacher.lastName}} {{lesson.teacher.firstName}}</span>
      </div>
    </div>
    <div class="lesson-details pt-0">
      <div>
        <span class="label">{{'student' | translate}}:</span>
        <span class="value">
          {{lesson.student.lastName}}
          {{lesson.student.firstName}}
          <span class="grade">{{lesson.student.grade || lesson.deal.classNumber}} клас</span>
        </span>
      </div>
      <div>
        <span class="label">{{'contact' | translate}}:</span>
        <span class="value">{{lesson.deal.client?.firstName}} {{lesson.deal.client?.lastName}}</span>
        <span class="value">{{lesson.deal.client?.phone}}</span>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div class="free-slots">
      <div>
        <span class="label">{{'main.labels.event_date' | translate}}</span>
        <span class="value">{{formatDate(freeSlotItem.date) | titlecase}}</span>
      </div>
      <div class="time-tags">
        <nz-tag
          *ngFor="let time of freeTimeDiapasons"
          nzMode="checkable"
          [nzChecked]="checkedTime === time"
          (nzCheckedChange)="onTimeChecked($event, time)"
        >{{time}}</nz-tag>
      </div>
    </div>
  </div>
  <div class="footer">
    <button nz-button class="cancel" (click)="modalRef.close()">{{'main.btn.decline' | translate}}</button>
    <label nz-checkbox *ngIf="showNotification" [(ngModel)]="notificationNeeded" class="notif">{{'calendar.lesson-transfer.notification' | translate}}</label>
    <button nz-button class="confirm" [disabled]="!checkedTime" (click)="onConfirmMoving()">{{'main.btn.ready' | translate}}</button>
  </div>
</ng-container>

<ng-template #loadingOrError>
  <div class="error">
    <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  </div>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>

