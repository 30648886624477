import { Component, OnDestroy, OnInit } from '@angular/core';
import LanguageService from '../../../../../shared/language/language.service';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import ScheduleCalendarService, { ScheduleCalendarType } from '../schedule-calendar.service';
import ScheduleApiService, { YearlyCalendarResponse } from '../../schedule-api.service';
import { ScheduleDayCalendarComponent } from '../schedule-day-calendar/schedule-day-calendar.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BreakpointObserver } from '@angular/cdk/layout';
import { setFirstZeroIfOneDigit } from '../../../../../shared/utils';

@Component({
  selector: 'mathema-schedule-year-calendar',
  templateUrl: './schedule-year-calendar.component.html',
  styleUrls: ['./schedule-year-calendar.component.scss'],
})
export class ScheduleYearCalendarComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public mobileMode: boolean = false;
  public selectedDayDate: string;
  public weekDayIndexes: number[] = [1, 2, 3, 4, 5, 6, 0];
  public calendarView$: Observable<YearlyCalendarResponse>;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  constructor(
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly drawerService: NzDrawerService,
    private readonly modalService: NzModalService,
  ) {
    this.subscriptions.push(this.breakpointObserver.observe(['(max-width: 575px)']).subscribe(result => {
      this.mobileMode = result.matches;
    }));
  }

  public ngOnInit(): void {
    const state = combineLatest([
      this.scheduleCalendarService.calendarDate,
      this.scheduleCalendarService.calendarFilters,
    ]);

    const calendarStateSub = state.subscribe(([calendarDate, calendarFilters]) => {
      this.loadingError$.next(false);

      const year = calendarDate.getFullYear();
      this.calendarView$ = this.scheduleApiService.getYearlyCalendar({
        year,
        ...calendarFilters,
      }).pipe(
        catchError(() => {
          this.loadingError$.next(true);
          return EMPTY;
        }),
      );
    });

    this.subscriptions.push(calendarStateSub);
  }

  public sortObjectNumericKeys(a, b): number {
    return a.key - b.key;
  }

  public formatWeekDayByIdx(idx: number): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'short',
    });

    const date = new Date();
    const currenDayIdx = date.getDay();
    const distance = idx - currenDayIdx;
    date.setDate(date.getDate() + distance);

    return formatter.format(date);
  }

  public formatMonth(value: string): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      month: 'long',
      year: 'numeric',
    });

    const year = this.scheduleCalendarService.calendarDate.value.getFullYear();
    const month = setFirstZeroIfOneDigit(+value);
    const isoDate = `${year}-${month}-01`;

    const [monthText, yearText] = formatter.format(new Date(isoDate)).split(' ');

    return `${monthText}, ${yearText}`;
  }

  public toggleSelectedDay(date: string): void {
    if (this.selectedDayDate === date) {
      this.selectedDayDate = '';
    } else {
      this.selectedDayDate = date;
      this.mobileMode ? this.openDayInModal(date) : this.openDayInDrawer(date);
    }
  }

  private openDayInModal(date: string): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzStyle: { top: 0 },
      nzWrapClassName: 'day-calendar-modal',
      nzContent: ScheduleDayCalendarComponent,
      nzComponentParams: {
        isModal: true,
        calendarDate: new Date(date),
      }
    });

    this.subscriptions.push(modalRef.afterClose.subscribe(() => {
      this.selectedDayDate = '';
    }));
  }

  private openDayInDrawer(date: string): void {
    const drawerRef = this.drawerService.create({
      nzWidth: '365px',
      nzClosable: false,
      nzWrapClassName: 'day-calendar-drawer',
      nzContent: ScheduleDayCalendarComponent,
      nzContentParams: {
        isDrawer: true,
        calendarDate: new Date(date),
      }
    });

    this.subscriptions.push(drawerRef.afterClose.subscribe(() => {
      this.selectedDayDate = '';
    }));
  }

  public onMonthClick(month: string): void {
    const dateMonth = Number(month) - 1;
    const date = this.scheduleCalendarService.calendarDate.value;
    date.setDate(1);
    date.setMonth(dateMonth);

    this.scheduleCalendarService.calendarType.next(ScheduleCalendarType.MONTH);
    this.scheduleCalendarService.diapasonDateChange.next(date);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
