<div class="header">
  <div>{{'deal.intersections-modal.lessons-intersection' | translate}}</div>
  <span nz-icon nzType="close" (click)="onDecline.emit()"></span>
</div>
<div class="body">
  <p class="info">{{'deal.intersections-modal.tip' | translate:{ count: intersections.length } }}</p>
  <nz-divider></nz-divider>
  <div class="intersections">
    <div class="intersection" *ngFor="let intersection of intersections; index as i">
      <div class="number">{{i + 1}}</div>
      <div class="details">
        <div class="summary">
          <span class="date">{{formatDate(intersection.item.date)}}</span>
          <span class="time">&nbsp;{{formatTime(intersection.item.time)}}</span>
          <span class="student" *ngIf="intersection.item.student">&nbsp;{{'deal.intersections-modal.busy-by-lesson' | translate}} {{intersection.item.student.lastName}} {{intersection.item.student.firstName}}</span>
        </div>
        <div class="dates">
          <div class="label" *ngIf="availableDates[intersection.item.date]?.length; else noDates">{{'deal.intersections-modal.available-dates' | translate}}</div>
          <ng-template #noDates>
            <div [style.margin-top]="'8px'">
              <mathema-info-block
                _type="warn"
                [text]="'deal.intersections-modal.no-time-warn' | translate:{ date: formatDate(intersection.item.date) }"
              ></mathema-info-block>
            </div>
          </ng-template>
          <nz-tag
            *ngFor="let availableDate of availableDates[intersection.item.date]"
            nzMode="checkable"
            (nzCheckedChange)="onDateCheckChange($event, intersection.item.date, availableDate.date)"
            [nzChecked]="availableDate.isChecked"
          >
            {{formatDate(availableDate.date, true)}}
          </nz-tag>
        </div>
        <div class="times">
          <ng-container *ngFor="let availableDate of availableDates[intersection.item.date]">
            <ng-container *ngIf="availableDate.isChecked">
              <div class="label">{{'deal.intersections-modal.available-time' | translate}}</div>
              <nz-tag
                *ngFor="let freeSlot of availableDate.freeSlots"
                nzMode="checkable"
                (nzCheckedChange)="onTimeCheckChange($event, intersection.item.date, availableDate.date, freeSlot.time)"
                [nzChecked]="freeSlot.isChecked"
              >
                {{freeSlot.time}}
              </nz-tag>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <button nz-button class="cancel" (click)="onDecline.emit()">{{'main.btn.decline' | translate}}</button>
  <button nz-button class="accept" (click)="onAccept.emit(intersectionsReplaces)" [disabled]="isAcceptDisabled()">{{'main.btn.ready' | translate}}</button>
</div>
