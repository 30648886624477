/**
 * Teacher
 */
type Teacher = {
  id: string;
  first_name: string;
  last_name: string;
  blockedAvailability?: boolean;
  blocked_availability?: boolean;
};

export { Teacher };
