<div [ngSwitch]="buttonType">
  <button [disabled]="disabled" class="menu-btn" style="width: {{customParams?.customWidth}}" (click)="onClick()" [class.selected]="selected" *ngSwitchCase="'menuButton'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{text}}
    <div *ngIf="underlineAnimation" class="underline"></div>
  </button>
  <button [disabled]="disabled" class="commonWhiteButton" style="width: {{customParams?.customWidth}}" (click)="onClick()" *ngSwitchCase="'commonWhiteButton'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{text}}
  </button>
  <button [disabled]="disabled" class="commonColourfulButton" style="width: {{customParams?.customWidth}}" (click)="onClick()" *ngSwitchCase="'commonColourfulButton'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{text}}
  </button>
  <button [disabled]="disabled" (click)="onClick()" class="loading-file-btn" style="width: {{customParams?.customWidth}}" *ngSwitchCase="'loading-file-btn'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{text}}
  </button>
  <button [disabled]="disabled" (click)="onClick()" class="remove-file-btn" style="width: {{customParams?.customWidth}}" *ngSwitchCase="'remove-file-btn'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{text}}
  </button>
  <button [disabled]="disabled" (click)="onClick()" class="full-width-btn" style="width: {{customParams?.customWidth}}" *ngSwitchCase="'colourfulButtonWithFullWidth'">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{text}}
  </button>
  <button [disabled]="disabled" (click)="onClick()" class="invincible-button" style="width: {{customParams?.customWidth}}" *ngSwitchCase="'invincibleButton'">
    <mat-icon *ngIf="icon" style="font-size: {{customParams?.customFontSize}};">{{icon}}</mat-icon> {{text}}
  </button>
</div>
