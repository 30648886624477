import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import ScheduleApiService, { ContactListElement, SlotTypeFilter } from '../../schedule-api.service';
import { Subscription } from 'rxjs';
import { NzMarks } from 'ng-zorro-antd/slider';
import ScheduleCalendarService, { ScheduleCalendarActiveSlot } from '../schedule-calendar.service';
import { LanguageEnum, NO_ENTITY_ID, halfHours } from '../../../../../shared/constants';
import LessonDto, { CreateLessonDto, DaysSchedule, UpdateFirstLessonDto } from '../../dto/lesson.dto';
import { CheckboxData } from '../../../../../shared/components/app-input/app-input.component';
import { LanguageArr, WorkingGradesArr, polSchools } from '../../../change-user-info/models/variables';
import {
  dateToYyyyMmDd,
  daysBetweenDates,
  splitNumbersArrayByDiapasons,
  splitNumbersArrayBySerialSegments,
  timeSlotDiapasonToTimeString,
  timeStringToTimeSlotDiapason
} from '../../../../../shared/utils';
import { StudyRequestDealStage } from '../../dto/deal.dto';
import DealApiService from '../../../deal/deal-api.service';
import { MathemaModalConfirmComponent, MathemaModalConfirmParams } from '../../../../../shared/components/modal-confirm/modal-confirm.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../auth/auth.service';
import LanguageService from '../../../../../shared/language/language.service';

@Component({
  selector: 'mathema-schedule-calendar-first-lesson',
  templateUrl: './schedule-calendar-first-lesson.component.html',
  styleUrls: ['./schedule-calendar-first-lesson.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarFirstLessonComponent implements OnInit, OnDestroy {

  public activeTeacherId = '';
  public activeTeacherName = '';
  teachers: ContactListElement[] = [];
  lesson = {
    teacher: this.activeTeacherName,
    teacherId: this.activeTeacherId,
    date: null,
    duration: 60,
    deal: '',
    dealId: '',
    paid: false,
    contact: '',
    contactId: '',
    phoneNumber: '',
    contactEmail: '',
    student: '',
    studentId: '',
    class: '0',
    language: 'UA',
    comment: '',
  };
  oldLesson: LessonDto;
  lessonSlot = '';
  slot: ScheduleCalendarActiveSlot;
  schedule: string[];
  public deal = null;

  public manualChooseTime: boolean = false;
  public manualTimeRange: number[];
  public manualTimeMin = 0;
  public manualTimeMax = 47;
  public manualTimeInputMin = 0;
  public manualTimeInputMax = 47;
  public manualTimeMarks: NzMarks = {};

  public selectedTime: string;
  public autoSelectedTime: string;
  public manuallySelectedTime: string;

  public dealLoading: boolean = false;
  public dealValid: { valid: boolean, type: 'info' | 'warn' | 'error', error: string } = { valid: true, type: 'info', error: '' };
  public isProcessing: boolean = false;

  public languages: CheckboxData[] = LanguageArr as CheckboxData[];
  public grades = WorkingGradesArr;

  public isEditMode: boolean;

  private subscriptions: Subscription[] = [];

  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly scheduleApiService: ScheduleApiService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly dealApiService: DealApiService,
    private readonly modalService: NzModalService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
  ) { }

  ngOnInit(): void {
    this.slot = {
      id: '',
      type: 'freeSlot',
      slotItemDate: '',
      slotItemTime: '',
      slotItemId: '',
    }
    this.subscriptions.push(
      this.scheduleCalendarService.activeTeacher.subscribe((teacher) => {
        this.activeTeacherId = teacher?.id;
        if (teacher) {
          this.lesson.teacherId = teacher.id;
          this.lesson.teacher = this.getTeacherName(teacher);
          this.activeTeacherName = this.lesson.teacher;
        } else {
          this.lesson.teacherId = '';
          this.lesson.teacher = '';
        }
      })
    );
    this.subscriptions.push(
      this.scheduleCalendarService.activeSlot.subscribe(slot => {
        if (slot) {
          this.slot = slot;
          if (slot.type === 'freeSlot') {
            this.lesson.date = new Date(slot.slotItemDate ?? null);
            this.schedule = [slot.slotItemTime];
            this.setManualMarks(slot.slotItemTime);

            this.selectedTime = null;
            this.autoSelectedTime = null;
            this.manuallySelectedTime = null;
          }
          if (slot.type === 'lesson') {
            this.isEditMode = true;
            const [, lessonId] = slot.id.split('|');
            this.scheduleApiService.getLessonInfoById(lessonId).subscribe(lesson => {
              this.oldLesson = lesson;
              this.lesson = {
                teacher: this.getTeacherName(lesson.teacher),
                teacherId: lesson.teacher?.id,
                date: new Date(lesson.date),
                duration: lesson.timeSlot?.length * 5,
                deal: String(lesson.deal?.dealNumber),
                dealId: lesson.deal?.id,
                paid: this.isPaidByStage(lesson.deal?.stageId),
                contact: `${lesson.deal?.client?.lastName} ${lesson.deal?.client?.firstName}`,
                contactId: '',
                phoneNumber: lesson.deal?.client?.phone,
                email: lesson.deal?.client?.email,
                student: `${lesson.student?.lastName} ${lesson.student?.firstName}`,
                studentId: lesson.student?.id,
                class: lesson.deal?.classNumber,
                language: lesson.deal?.lang,
                comment: lesson.deal?.parentComment,
              } as any;
              this.lessonSlot = timeSlotDiapasonToTimeString(lesson.timeSlot);
              this.selectedTime = this.lessonSlot;
              this.loadSchedule();
            }
            );
          }
        } else {
          if (this.scheduleCalendarService.activeTeacher.value?.notTakeNewStudentsAt) {
            this.onCancel.emit();
            return;
          }

          if (this.scheduleCalendarService.activeTeacher.value?.blockedAvailability) {
            this.onCancel.emit();
            return;
          }

          this.resetForm();
        }
      }));

    if (this.authService.isUserPoland()) this.addPolishGrades();
  }

  cancel(): void {
    this.resetForm();
    this.onCancel.emit();
  }

  resetForm(): void {
    this.lesson = {
      teacher: this.activeTeacherName,
      teacherId: this.activeTeacherId,
      date: null,
      duration: 60,
      deal: '',
      dealId: '',
      paid: false,
      contact: '',
      contactId: '',
      phoneNumber: '',
      contactEmail: '',
      student: '',
      studentId: '',
      class: '0',
      language: this.languages.find((lang) => lang.id === this.languageService.locale)?.id || LanguageEnum.english,
      comment: '',
    };
    this.oldLesson = null;
    this.slot.slotItemTime = '';
    this.resetDealValidity();
    this.dealLoading = false;
    this.isProcessing = false;
    this.isEditMode = false;
    this.deal = null;
    if (this.authService.isUserPoland()) this.addPolishGrades();
  }

  addPolishGrades(): void {
    this.grades = [...WorkingGradesArr].splice(0, WorkingGradesArr.length - 3).concat(polSchools);
    this.grades.sort((a, b) => {
      if (a.id < b.id) return -1
      else return 1;
    });
  }

  isFormValid(): boolean {
    return (this.lesson.teacherId && this.lesson.teacherId.length > 0 && this.lesson.teacherId !== NO_ENTITY_ID)
      && (this.lesson.dealId && this.lesson.dealId.length > 0)
      && (this.lesson.studentId && this.lesson.studentId.length > 0 && this.lesson.studentId !== NO_ENTITY_ID)
      && (this.lesson.date)
      && (this.selectedTime && this.selectedTime.length > 0)
  }

  clearTeacher(): void {
    this.lesson.teacher = '';
    this.lesson.teacherId = '';
  }

  getTeacherName(teacher: ContactListElement): string {
    return `${teacher.lastName} ${teacher.firstName}`;
  }

  onTeacherNameInput(_event): void {
    this.loadTeachers();
  }

  onDateSelect(): void {
    this.selectedTime = null;
    this.loadSchedule();
  }

  loadSchedule(): void {
    const date = new Date(this.lesson.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    this.scheduleApiService.getDailyCalendar({
      slotType: SlotTypeFilter.ONLY_SLOTS,
      teacherId: this.lesson.teacherId,
      year,
      month,
      day,
    }).subscribe(daySchedule => {
      if (daySchedule.items.length > 0) {
        this.schedule = daySchedule.items.reduce((acc, s) => { acc.push(s.time); return acc; }, []);
        let scheduleStart: string, scheduleEnd: string;
        this.schedule.forEach(s => {
          const [start, end] = s.split(' - ');
          scheduleStart = scheduleStart
            ? (halfHours.findIndex(t => t === start) <= halfHours.findIndex(t => t === scheduleStart) ? start : scheduleStart) : start;
          scheduleEnd = scheduleEnd
            ? (halfHours.findIndex(t => t === end) >= halfHours.findIndex(t => t === scheduleEnd) ? end : scheduleEnd) : end;
        });
        this.slot.slotItemTime = `${scheduleStart} - ${scheduleEnd}`;
        this.setManualMarks(this.slot.slotItemTime);
        this.manualTimeRange = [halfHours.findIndex(t => t === scheduleStart), halfHours.findIndex(t => t === scheduleEnd)];
      } else {
        this.slot.slotItemTime = '';
        this.schedule = [];
      }
    });
  }
  public isSchedulePresent(): boolean {
    return this.slot.slotItemTime && this.slot.slotItemTime.length > 0;
  }

  public isTimeSlotValid(timeSlot: string): boolean {
    const [slotStart, slotEnd] = timeSlot.split(' - ');
    const slotDateTime = new Date(this.lesson.date);
    slotDateTime.setHours(+slotStart.slice(0, 2), +slotStart.slice(3, 5));
    if (this.schedule && this.schedule.length > 0)
      return this.schedule.some(s => {
        const [start, end] = s.split(' - ');
        return halfHours.findIndex(t => t === slotStart) >= halfHours.findIndex(t => t === start)
          && halfHours.findIndex(t => t === slotEnd) <= halfHours.findIndex(t => t === end);
      }) && slotDateTime >= new Date();
    else return false;
  }

  private loadTeachers(): void {
    this.subscriptions.push(
      this.scheduleApiService.getTeachersList({
        name: this.lesson.teacher
      }).subscribe(teachers => {
        this.teachers = teachers;
      }));
  }

  selectTeacher(teacher: ContactListElement): void {
    this.lesson.teacher = this.getTeacherName(teacher);
    this.lesson.teacherId = teacher.id;
    this.selectedTime = null;
    this.loadSchedule();
  }

  resetDealValidity(): void {
    this.dealValid.valid = true;
  }

  search(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.getDeal();
    }
  }

  getDeal() {
    if (!this.dealLoading && this.lesson.deal && this.lesson.deal.length > 0) {
      this.dealLoading = true;
      this.dealValid.valid = true;
      this.lesson.dealId = null;
      this.dealApiService.getByNumber(this.lesson?.deal).subscribe(deal => {
        this.deal = deal;
        this.dealLoading = false;
        if (!deal) {
          this.dealValid = {
            error: this.translateService.instant('calendar.first-lesson.deal_not_found', { dealNumber: this.lesson?.deal }),
            valid: false,
            type: 'error'
          };
          return;
        }

        if (!deal.teacherId || deal.teacherId === NO_ENTITY_ID) {
          this.dealValid = {
            error: this.translateService.instant('calendar.first-lesson.no_teacher_in_deal'),
            valid: false,
            type: 'warn'
          };
        }

        if (!deal.studentId || deal.studentId === NO_ENTITY_ID) {
          this.dealValid = {
            error: this.translateService.instant('calendar.first-lesson.no_student_in_deal'),
            valid: false,
            type: 'error'
          };
          return;
        }

        if (![
          StudyRequestDealStage.WORK_IN_PROGRESS,
          StudyRequestDealStage.BILLED_1ST_LESSON_INVOICE,
          StudyRequestDealStage.PAID_1ST_LESSON_INVOICE,
          StudyRequestDealStage.WAS_ON_1ST_LESSON,
          StudyRequestDealStage.BILLED_FINAL_INVOICE,
          StudyRequestDealStage.SUBSCRIPTION_PAID,
        ].includes(deal.stageId as StudyRequestDealStage)) {
          this.dealValid = {
            error: this.translateService.instant('calendar.first-lesson.unable_to_add_lesson'),
            valid: false,
            type: 'error'
          };
          return;
        }

        if (this.isTeacherChangedOnCreation()) {
          this.dealValid = {
            error: this.translateService.instant('calendar.first-lesson.change_teacher_prompt', { deal: this.lesson.deal, teacherName: this.getTeacherName(deal.teacher) }),
            valid: false,
            type: 'warn'
          };
        }

        this.lesson.dealId = deal.id;
        this.lesson.paid = this.isPaidByStage(deal.stageId);
        this.lesson.contact = `${deal.client?.firstName} ${deal.client?.lastName}`;
        this.lesson.contactId = deal.contactId;
        this.lesson.phoneNumber = `${deal.client?.phone ?? '-'}`;
        this.lesson.contactEmail = `${deal.client?.email ?? '-'}`;
        this.lesson.comment = deal.parentComment;
        this.lesson.student = `${deal.student?.firstName} ${deal.student?.lastName}`;
        this.lesson.studentId = deal.studentId;
        this.lesson.teacherId = (deal.teacherId !== NO_ENTITY_ID) ? this.activeTeacherId ?? deal.teacherId : this.activeTeacherId;
        this.lesson.class = String(deal.classNumber);
        this.lesson.language = this.authService.isUserUkrainian() ? LanguageEnum.ukrainian : LanguageEnum.polish
      }, error => {
        if (error.status === 404) {
          this.dealValid = {
            error: this.translateService.instant('calendar.first-lesson.deal_not_found', { dealNumber: this.lesson?.deal }),
            valid: false,
            type: 'error'
          };
        }
        this.dealLoading = false;
      });
    }
  }

  private isTeacherChangedOnCreation(): boolean {
    return this.deal && this.deal.teacherId && this.lesson.teacherId != this.deal.teacherId && this.deal.teacherId !== NO_ENTITY_ID;
  }

  private isPaidByStage(stageId: string): boolean {
    switch (stageId) {
      case StudyRequestDealStage.PAID_1ST_LESSON_INVOICE:
      case StudyRequestDealStage.WAS_ON_1ST_LESSON:
      case StudyRequestDealStage.BILLED_FINAL_INVOICE: return true;
      case StudyRequestDealStage.NEW_REQUEST:
      case StudyRequestDealStage.WORK_IN_PROGRESS:
      case StudyRequestDealStage.BILLED_1ST_LESSON_INVOICE: return false;
      default: return false;
    }
  }

  public isDealSelected(): boolean {
    return (this.lesson.dealId != null && this.lesson.dealId.length > 0);
  }

  createWithConfirm(): void {
    if (this.isTeacherChangedOnCreation()) {
      this.openModalConfirm({
        header: this.translateService.instant('calendar.first-lesson.change_teacher_in_deal'),
        message: this.translateService.instant('calendar.first-lesson.change_teacher_modal_prompt',
          {
            dealNumber: this.lesson.deal,
            oldTeacher: this.getTeacherName(this.deal.teacher),
            newTeacher: this.lesson.teacher
          }),
        acceptText: this.translateService.instant('calendar.first-lesson.make_change'),
        declineText: this.translateService.instant('cancel'),
      }, (modalRef) => {
        this.create();
        modalRef.close();
      })
    } else this.create();
  }

  private openModalConfirm(params: MathemaModalConfirmParams, acceptCallback: (modalRef: NzModalRef) => void): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzComponentParams: { params },
    });

    this.subscriptions.push(
      modalRef.componentInstance.onDecline.subscribe(() => {
        modalRef.close();
      }),
    );
    this.subscriptions.push(
      modalRef.componentInstance.onAccept.subscribe(acceptCallback.bind(this, modalRef)),
    );
  }

  create(): void {
    let day = new Date(this.lesson.date).getDay();
    day = (day) ? day : 7;
    const daySlot: DaysSchedule = { day, time: this.selectedTime };
    const timeSlots = [];
    timeSlots.push(daySlot);
    const dto: CreateLessonDto = {
      type: "first",
      count: 1,
      start: new Date(this.lesson.date).toISOString().slice(0, 10),
      studentId: this.lesson.studentId,
      teacherId: this.lesson.teacherId,
      deal: this.lesson.dealId,
      status: (this.lesson.paid) ? 'booked' : 'reserved',
      timeSlots,
      lang: this.lesson.language,
      classNumber: this.lesson.class,
      parentComment: this.lesson.comment,
    };
    this.isProcessing = true;
    this.scheduleApiService.createLesson(dto).subscribe((lesson) => {
      if (lesson.teacherId !== this.activeTeacherId)
        this.scheduleCalendarService.calendarFilters.next({ teacherId: lesson.teacherId, slotType: SlotTypeFilter.ALL });
      else this.scheduleCalendarService.refreshCalendarDate();

      this.cancel();
    }, error => {
      this.isProcessing = false;
      console.log(JSON.stringify(error));
    }, () => {
      this.isProcessing = false;
    });
  }

  isDealChanged(): boolean {
    return this.oldLesson && (
      this.oldLesson.deal?.classNumber !== this.lesson.class ||
      this.oldLesson.deal?.lang !== this.lesson.language ||
      this.oldLesson.deal?.parentComment !== this.lesson.comment
    );
  }

  isDateTimeChanged(): boolean {
    return this.oldLesson && (
      new Date(this.lesson.date).toString() !== new Date(this.oldLesson?.date).toString() ||
      timeSlotDiapasonToTimeString(this.oldLesson.timeSlot) !== this.selectedTime
    );
  }

  isTeacherChanged(): boolean {
    return this.oldLesson &&
      this.oldLesson.teacher?.id !== this.lesson.teacherId;
  }

  save() {
    let needRefresh = false;
    let needSwitch = false;
    let lessonUpdate: UpdateFirstLessonDto = {};
    if (this.isDealChanged()) {
      lessonUpdate = {
        classNumber: this.lesson.class,
        lang: this.lesson.language,
        parentComment: this.lesson.comment,
      };
    }
    if (this.isDateTimeChanged()) {
      lessonUpdate = {
        ...lessonUpdate,
        teacherId: this.lesson.teacherId,
        date: new Date(this.lesson.date).toISOString().slice(0, 10),
        timeRange: this.selectedTime,
      };
      needRefresh = true;
    }
    if (this.isTeacherChanged()) {
      lessonUpdate = {
        ...lessonUpdate,
        teacherId: this.lesson.teacherId,
      };
      needSwitch = true;
    }
    this.isProcessing = true;
    this.scheduleApiService.updateFirstLesson(this.oldLesson.id, lessonUpdate).subscribe(response => {
      if (needSwitch)
        this.scheduleCalendarService.calendarFilters.next({ teacherId: response?.teacherId, slotType: SlotTypeFilter.ALL });
      else if (needRefresh)
        this.scheduleCalendarService.refreshCalendarDate();
      this.cancel();
    }, error => {
      this.isProcessing = false;
      console.log(JSON.stringify(error));
    }, () => {
      this.isProcessing = false;
    });
  }

  public isLessonDaySelected(): boolean {
    if (!this.isEditMode) return false;
    return new Date(this.lesson.date).toString() === new Date(this.oldLesson?.date).toString();
  }

  public isTimeSlotsAvailable(): boolean {
    return new Date(this.lesson.date).toString() && this.schedule && this.schedule.length > 0;
  }

  public isTeacherSelected(): boolean {
    return this.lesson.teacherId && this.lesson.teacherId.length > 0 && this.lesson.teacherId !== NO_ENTITY_ID;
  }

  public onTimeChecked(isChecked: boolean, time: string): void {
    if (isChecked) {
      this.autoSelectedTime = time;
      this.selectedTime = this.autoSelectedTime;
    } else {
      this.autoSelectedTime = null;
      this.selectedTime = this.autoSelectedTime;
    }
  }

  public timeDiapasonToFreeSlotsTimes(slotTime: string, lessonDuration: number): string[] {
    if (slotTime.length === 0) return [];
    const timeDiapason = timeStringToTimeSlotDiapason(slotTime);

    return splitNumbersArrayBySerialSegments(timeDiapason)
      .map(solidFreeSlotDiapason => splitNumbersArrayByDiapasons(solidFreeSlotDiapason, lessonDuration / 5)
        .filter(hourOrHalfHourDiapason => hourOrHalfHourDiapason.length >= lessonDuration / 5) // todo: remove in future when everything will be ok with slots
        .map(timeSlotDiapasonToTimeString),
      )
      .reduce((times, value) => times.concat(value), []);
  }

  public onConfirmManualTime(): void {
    this.autoSelectedTime = null;

    const [min, max] = this.manualTimeRange;
    this.manuallySelectedTime = `${halfHours[min]} - ${halfHours[max]}`;
    this.autoSelectedTime = null;
    this.selectedTime = this.manuallySelectedTime;

    this.manualChooseTime = false;
  }

  public onManualTimeToggle(value: boolean): void {
    if (!value) {
      this.selectedTime = null;
    } else {
      this.onConfirmManualTime();
    }
  }

  private setManualMarks(slotTime: string): void {
    const [startTime, endTime] = slotTime.split(' - ');
    const startTimeIdx = halfHours.findIndex(el => el === startTime);
    const endTimeIdx = halfHours.findIndex(el => el === endTime);

    this.resetManualTime(startTimeIdx, endTimeIdx);
  }

  public resetManualTime(min = 0, max = 47): void {
    this.manualTimeRange = [min, max];
    this.manualTimeMin = min;
    this.manualTimeMax = max;
    this.manualTimeInputMin = min;
    this.manualTimeInputMax = max;
    this.manualTimeMarks = {
      [min]: {
        style: { left: '3%', top: '2px' },
        label: halfHours[min],
      },
      [max]: {
        style: { left: '97%', top: '2px' },
        label: halfHours[max],
      },
    }
  }

  public onManualTimeRangeChange(value: number[] | number): void {
    if (Array.isArray(value)) {
      const [min, max] = value;
      this.manualTimeInputMin = min;
      this.manualTimeInputMax = max;
    }
  }

  public onManualTimeMinChange(value: number): void {
    this.manualTimeRange = [value, this.manualTimeRange[1]];
  }

  public onManualTimeMaxChange(value: number): void {
    this.manualTimeRange = [this.manualTimeRange[0], value];
  }

  public isManualTimeValid(): boolean {
    const minutesInRange = (this.manualTimeRange[1] - this.manualTimeRange[0]) * 30;
    return this.isTimeSlotValid(`${halfHours[this.manualTimeRange[0]]} - ${halfHours[this.manualTimeRange[1]]}`) && minutesInRange === this.lesson.duration;
  }

  public formatManualTime(value: number): string {
    return halfHours[value];
  }

  public isDateYesterdayOrBefore(): (date: Date) => boolean {
    return (date: Date) => {
      return daysBetweenDates(dateToYyyyMmDd(new Date()), dateToYyyyMmDd(date)) > 0;
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
