<div class="container">
  <!--  <img class='close-icon' src='assets/close.svg' (click)='closeDialog()' alt=''>-->
  <h2></h2>

  <div class="block">
    <h3 style="display: flex">
      <mat-icon class="icon">check_box</mat-icon>
      {{ data.subTitle }}
    </h3>
  </div>

  <div class="buttons-container">
    <button
      mat-button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
    >
      {{ data.confirmText }}
    </button>
  </div>
</div>
