import { Component, OnInit } from '@angular/core';
import { AppRoutesDefinitions } from '../../../../app.routes';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfirmComponent } from '../../users-old/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'mathema-main-menu-nav',
  templateUrl: './main-menu-nav.component.html',
  styleUrls: ['./main-menu-nav.component.scss']
})
export class MainMenuNavComponent implements OnInit {

  public readonly routes = AppRoutesDefinitions;
  public currentUrl: string;

  constructor(
    private router: Router,
    public authService: AuthService,
    private dialog: MatDialog,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit() {

  }

  onLogoutClick() {
    this.dialog.open(ModalConfirmComponent, {
      data: {
        title: this.translateService.instant('log-out-from-account'),
        subTitle: this.translateService.instant('log-out-confirm'),
        confirmText: this.translateService.instant('yes'),
        cancelText: this.translateService.instant('no'),
        onConfirm: () => {
          this.authService.removeToken();
          this.dialog.closeAll();
          this.router.navigate(['/login']);
        },
        onCancel: () => {
          this.dialog.closeAll();
        },
      },
    });
  }

  public getLogoLetter(): string {
    return this.authService.user.firstName.trim()[0];
  }

  public redirectToMyAccount(): void {
    this.router.navigate([AppRoutesDefinitions.VIEW_USER_INFO]);
  }

}
