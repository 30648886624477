import {
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import LessonDto from '../../../dto/lesson.dto';
import { catchError, finalize, tap } from 'rxjs/operators';
import ScheduleApiService from '../../../schedule-api.service';
import { timeSlotDiapasonToTimeString } from '../../../../../../shared/utils';
import LanguageService from '../../../../../../shared/language/language.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NotificationService } from '../../../../../../shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../../../../app/modules/components/auth/auth.service';

@Component({
  selector: 'math-conduct-lesson-modal',
  templateUrl: './conduct-lesson-modal.component.html',
  styleUrls: ['./conduct-lesson-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConductLessonModalComponent implements OnInit {

  @Input() lessonId: string;
  @Input() editMode: boolean;

  @Output() onSuccess = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  public isLoading = false;

  public lessonData$: Observable<LessonDto>;
  public loadingError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public lessonForm: FormGroup = new FormGroup({
    subject: new FormControl('', Validators.required),
    homework: new FormControl('', Validators.required),
    notes: new FormControl(''),
    links: new FormArray([], Validators.maxLength(5)),
  });

  @ViewChild('linkInputElement', { static: false }) linkInputElement?: ElementRef;
  public linkInputVisible = false;
  public linkInputValue = '';

  @HostListener('document:keydown.control.v')
  private onPasteByCtrlV() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.control.м')
  private onPasteByCtrlVCyrillic() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.meta.v')
  private onPasteByCommandV() {
    this.handleClickPaste();
  }

  @HostListener('document:keydown.meta.м')
  private onPasteByCommandVCyrillic() {
    this.handleClickPaste();
  }

  private readonly supportedFileTypes: string[] = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
  private readonly oneKb: number = 1000;
  public fileList: NzUploadFile[] = [];
  private filesIdsToRemove: number[] = [];
  public imagePreview: string | undefined = '';
  public isImagePreviewVisible = false;

  constructor(
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
  ) { }

  public async handleClickPaste (): Promise<void> {
    const clipboardItems = await navigator.clipboard.read();

    if (!clipboardItems.length) {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.conduct-lesson.upload-err-by-empty-buffer'));
      return;
    }

    const [item] = clipboardItems;

    if (item.types.find((type) => type === 'text/plain')) {
      return;
    }

    const foundImageMimeType = item.types.find((type: string) => this.supportedFileTypes.includes(type));

    if (foundImageMimeType) {
      const blob = await item.getType(foundImageMimeType);
      const uid = crypto.randomUUID();
      const file = new File([blob], uid, {
        type: foundImageMimeType,
      });

      (file as any).uid = uid;

      this.beforeImageUpload(file as any);
    } else {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.conduct-lesson.upload-err-by-type-buffer'));
    }
  }

  public ngOnInit(): void {
    this.loadingError$.next(false);
    this.lessonData$ = this.scheduleApiService.getConductedLessonDetails(this.lessonId).pipe(
      tap(lesson => {
        this.lessonForm.patchValue({
          subject: lesson.subject,
          homework: lesson.homework,
          notes: lesson.notes,
        });

        if (lesson.links?.length) {
          for (const link of lesson.links) {
            this.links.push(new FormControl(link));
          }
        }

        this.fileList.push(...lesson.materials.map(el => ({
          id: el.id,
          uid: el.externalId,
          name: `${el.externalId}.${el.extension}`,
          url: el.src,
          currentlyUploaded: true,
        })));
      }),
      catchError(() => {
        this.loadingError$.next(true);
        return EMPTY;
      }),
    );
    if(this.isSimplifiedValidation()) {
      this.lessonForm.controls.subject.clearValidators();
      this.lessonForm.controls.homework.clearValidators();
    }
  }

  public isSimplifiedValidation(): boolean {
    return this.authService.isAdmin() || this.authService.isManager();
  }

  public onSubmit(): void {
    const formData = new FormData();
    const filesToUpload = this.fileList.filter(file => !file.currentlyUploaded);

    if (filesToUpload.length) {
      for (const file of filesToUpload) {
        formData.append('files', file as any);
      }
    }

    const body = {
      ...this.lessonForm.value,
      filesIdsToRemove: this.filesIdsToRemove.filter(Number.isInteger),
    };

    formData.append('body', JSON.stringify(body));

    this.isLoading = true;

    const method = this.editMode ?
      this.scheduleApiService.editConductedLesson.bind(this.scheduleApiService) :
      this.scheduleApiService.conductRegularLesson.bind(this.scheduleApiService);

    method(this.lessonId, formData)
      .pipe(finalize(() => { this.isLoading = false; }))
      .subscribe(() => {
        this.onSuccess.emit();
      });
  }

  get links(): FormArray {
    return this.lessonForm.get('links') as FormArray;
  }

  public showLinkInput(): void {
    this.linkInputVisible = true;
    setTimeout(() => {
      this.linkInputElement?.nativeElement?.focus();
    }, 10);
  }

  public handleLinkInputConfirm(): void {
    if (this.linkInputValue.length > 255) {
      this.linkInputValue = '';
      this.linkInputVisible = false;
      return;
    }

    if (this.linkInputValue && this.links.controls.map(el => el.value).indexOf(this.linkInputValue) === -1) {
      this.links.push(new FormControl(this.linkInputValue));
    }

    this.linkInputValue = '';
    this.linkInputVisible = false;
  }

  public onRemoveLink(index): void {
    this.links.removeAt(index);
  }

  public sliceLinkValue(link: string): string {
    const isLongLink = link.length > 15;
    return isLongLink ? `${link.slice(0, 15)}...` : link;
  }

  public handleUploadImageChange(change: NzUploadChangeParam): void {
    const { file } = change;

    if (file.status === 'removed' && file.currentlyUploaded) {
      this.filesIdsToRemove.push(file.id);
    }
  }

  public beforeImageUpload = (file: NzUploadFile): boolean => {
    if (this.fileList.length >= 5) {
      return false;
    }

    if (!this.supportedFileTypes.includes(file.type)) {
      this.notificationService.showInfoMessage(this.translateService.instant('calendar.conduct-lesson.upload-err-by-type'));
      return false;
    }

    if (file.size > (this.oneKb * 512)) {
      this.notificationService.showInfoMessage(
        this.translateService.instant('calendar.conduct-lesson.upload-err-by-size', { size: Math.ceil(file.size / 1000) }),
      );
      return false;
    }

    const reader = new FileReader();

    reader.onload = event => {
      file.url = `${event.target.result}`;
      this.fileList = this.fileList.concat(file);

      setTimeout(() => {
        this.fileList = [...this.fileList]
      }, 0);
    };

    reader.readAsDataURL(file as any);

    return false;
  }

  public handleImageRemove = (file: NzUploadFile): boolean => {
    this.filesIdsToRemove.push(file.id);
    return true;
  }

  public handleImagePreview = async (file: NzUploadFile): Promise<void> => {
    this.imagePreview = file.url;
    this.isImagePreviewVisible = true;
  };

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public timeSlotToTime(timeSlots: number[]): string {
    return timeSlotDiapasonToTimeString(timeSlots);
  }
}
