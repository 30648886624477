<div class="move-lesson-controls">
  <h3 class="title">{{'calendar.lesson-transfer.title' | translate}}</h3>
  <button nz-button (click)="onClose.emit()">{{'cancel' | translate}}</button>
  <button nz-button class="done" [disabled]="!checkedTime" (click)="onConfirmMoving()">{{'done' | translate}}</button>
</div>
<div class="move-lesson-body lesson-details-body" *ngIf="lessonData$ | async as lesson; else loadingOrError">
  <div class="loading-overlay" *ngIf="isLoading">
    <nz-spin></nz-spin>
  </div>
  <div class="notification-block">
    <label nz-checkbox *ngIf="showNotification" [(ngModel)]="notificationNeeded">{{'calendar.lesson-transfer.notification' | translate}}</label>
  </div>
  <div class="lesson-details">
    <div class="details-block">
      <div class="lesson-detail">
        <div class="label">{{'calendar.lesson-details.event_datetime' | translate}}:</div>
        <div class="value date">{{formatDate(lesson.date)}}<span class="time">{{timeSlotToTime(lesson.timeSlot)}}</span></div>
      </div>
    </div>
    <div class="details-block white">
      <div class="lesson-detail">
        <div class="label">{{'student' | translate}}:</div>
        <div class="value">
          {{lesson.student.firstName}}
          {{lesson.student.lastName}}
          <span class="sticker grade">{{lesson.student.grade || lesson.deal.classNumber}} {{'grade' | translate}}</span></div>
      </div>
      <div class="lesson-detail" *ngIf="lesson.deal.client">
        <div class="label">{{'contact' | translate}}:</div>
        <div class="value">{{lesson.deal.client?.firstName}} {{lesson.deal.client?.lastName}}</div>
        <div class="value">{{lesson.deal.client?.phone}}</div>
      </div>
    </div>
    <div class="auto-slots" *ngIf="!manualMode">

      <ng-container *ngIf="autoSlotsData$ | async as freeSlots">
        <div class="details-block">
          <div class="lesson-detail">
            <ng-container *ngIf="freeSlots.length; else noSlots">
              <div class="label">{{'calendar.lesson-transfer.available_slots_to_transfer' | translate}}:</div>
              <nz-collapse nzExpandIconPosition="right">
                <nz-collapse-panel
                  *ngFor="let freeSlot of freeSlots, first as first"
                  [nzHeader]="formatDate(freeSlot.date)"
                  [nzActive]="first"
                >
                  <div class="slots">
                    <nz-tag
                      nzMode="checkable"
                      [nzChecked]="checkedTime === (time + '|' + freeSlot.id)"
                      *ngFor="let time of timeDiapasonToFreeSlotsTimes(freeSlot.date, freeSlot.timeSlots, lesson.timeSlot.length)"
                      (nzCheckedChange)="onTimeChecked($event, time + '|' + freeSlot.id)"
                    >
                      {{time}}
                    </nz-tag>
                  </div>
                </nz-collapse-panel>
              </nz-collapse>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <button nz-button class="mode-switch" (click)="switchMode(true)">{{'calendar.lesson-transfer.enter_date_manually' | translate}}</button>
    </div>
    <ng-container *ngIf="manualMode">
      <div class="details-block">
        <div class="lesson-detail">
          <div class="label">{{'calendar.lesson-transfer.transfer_date' | translate}}:</div>
          <nz-date-picker
            [(ngModel)]="manualDate"
            (ngModelChange)="onManualDateChange($event)"
            nzFormat="dd MMMM yyyy"
            [nzDisabledDate]="isDateYesterdayOrBefore()"
          ></nz-date-picker>
        </div>
      </div>

      <ng-container *ngIf="manualSlotsData$ | async as freeSlots">
        <div class="details-block">
          <ng-container *ngIf="freeSlots.length; else noSlots">
            <div class="lesson-detail">
              <div class="label">{{'calendar.lesson-transfer.available_slots_to_transfer' | translate}}:</div>
              <ng-container *ngFor="let freeSlot of freeSlots">
                <div class="slots">
                  <nz-tag
                    nzMode="checkable"
                    [nzChecked]="checkedTime === (time + '|' + freeSlot.id)"
                    *ngFor="let time of timeDiapasonToFreeSlotsTimes(freeSlot.date, freeSlot.timeSlots, lesson.timeSlot.length)"
                    (nzCheckedChange)="onTimeChecked($event, time + '|' + freeSlot.id)"
                  >
                    {{time}}
                  </nz-tag>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div>
        <button nz-button class="mode-switch" (click)="switchMode()">{{'calendar.lesson-transfer.show_offers' | translate}}</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noSlots>
  <mathema-info-block _type="error" text="{{'calendar.lesson-transfer.no_free_slots_found' | translate}}"></mathema-info-block>
</ng-template>

<ng-template #loadingOrError>
  <div class="error">
    <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  </div>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple class="lesson-move-loader"></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
