import { I18nLanguage } from '../../../../shared/language/language.service';

export const studyDays = [{
  value: '1',
  label: 'monday',
  isChecked: false,
}, {
  value: '2',
  label: 'tuesday',
  isChecked: false,
}, {
  value: '3',
  label: 'wednesday',
  isChecked: false,
}, {
  value: '4',
  label: "thursday",
  isChecked: false,
}, {
  value: '5',
  label: 'friday',
  isChecked: false,
}, {
  value: '6',
  label: 'saturday',
  isChecked: false,
}, {
  value: '0',
  label: 'sunday',
  isChecked: false,
}];

const studentGradesUa = [{
  value: '1',
  label: '1-grade',
  isChecked: false,
}, {
  value: '2',
  label: '2-grade',
  isChecked: false,
}, {
  value: '3',
  label: '3-grade',
  isChecked: false,
}, {
  value: '4',
  label: '4-grade',
  isChecked: false,
}, {
  value: '5',
  label: '5-grade',
  isChecked: false,
}, {
  value: '6',
  label: '6-grade',
  isChecked: false,
}, {
  value: '7',
  label: '7-grade',
  isChecked: false,
}, {
  value: '8',
  label: '8-grade',
  isChecked: false,
}, {
  value: '9',
  label: '9-grade',
  isChecked: false,
}, {
  value: '10',
  label: '10-grade',
  isChecked: false,
}, {
  value: '11',
  label: '11-grade',
  isChecked: false,
}];

const studentGradesPl = [{
  value: '0',
  label: '0-grade',
  isChecked: false,
}, {
  value: '1',
  label: '1-grade',
  isChecked: false,
}, {
  value: '2',
  label: '2-grade',
  isChecked: false,
}, {
  value: '3',
  label: '3-grade',
  isChecked: false,
}, {
  value: '4',
  label: '4-grade',
  isChecked: false,
}, {
  value: '5',
  label: '5-grade',
  isChecked: false,
}, {
  value: '6',
  label: '6-grade',
  isChecked: false,
}, {
  value: '7',
  label: '7-grade',
  isChecked: false,
}, {
  value: '8',
  label: '8-grade',
  isChecked: false,
}, {
  value: 'high_school',
  label: 'high_school',
  isChecked: false,
}, {
  value: 'technical_school',
  label: 'technical_school',
  isChecked: false,
}, {
  value: 'professional_school',
  label: 'professional_school',
  isChecked: false,
}];

export const studentGrades = {
  [I18nLanguage.UA]: studentGradesUa,
  [I18nLanguage.PL]: studentGradesPl,
  [I18nLanguage.EN]: studentGradesUa,
};

const studyProgramUa = [{
  value: 'intelectUkraine',
  label: 'intelectUkraine',
  isChecked: false,
}, {
  value: 'naKrylaxUspixy',
  label: 'naKrylaxUspixy',
  isChecked: false,
}, {
  value: 'rostock',
  label: 'rostock',
  isChecked: false,
}, {
  value: 'NUS',
  label: 'NUS',
  isChecked: false,
}, {
  value: 'other',
  label: 'other',
  isChecked: false,
}];

const studyProgramPl = [...studyProgramUa].filter(el => el.value !== 'NUS');
const studyProgramEn = [...studyProgramUa].concat([{
  value: 'a_level_further',
  label: 'a_level_further',
  isChecked: false,
}, {
  value: 'adv_math',
  label: 'adv_math',
  isChecked: false,
}]);

export const studyProgram = {
  [I18nLanguage.UA]: studyProgramUa,
  [I18nLanguage.PL]: studyProgramPl,
  [I18nLanguage.EN]: studyProgramEn,
}


export const teachingExperience = [{
  value: 'to_three_years',
  label: 'to_three_years',
  isChecked: false,
}, {
  value: 'from_three_to_five',
  label: 'from_three_to_five',
  isChecked: false,
}, {
  value: 'from_five_to_ten',
  label: 'from_five_to_ten',
  isChecked: false,
}, {
  value: 'from_ten_to_fifteen',
  label: 'from_ten_to_fifteen',
  isChecked: false,
}, {
  value: 'above_fifteen',
  label: 'above_fifteen',
  isChecked: false,
}];

export const tutoringExperience = [{
  value: 'to_three_years',
  label: 'to_three_years',
  isChecked: false,
}, {
  value: 'from_three_to_five',
  label: 'from_three_to_five',
  isChecked: false,
}, {
  value: 'from_five_to_ten',
  label: 'from_five_to_ten',
  isChecked: false,
}, {
  value: 'from_ten_to_fifteen',
  label: 'from_ten_to_fifteen',
  isChecked: false,
}, {
  value: 'above_fifteen',
  label: 'above_fifteen',
  isChecked: false,
}];

const examsUa = [{
  value: 'dpa',
  label: 'dpa',
  isChecked: false,
}, {
  value: 'zno',
  label: 'zno',
  isChecked: false,
}];

const examsPl = examsUa.concat({
  value: 'polExam',
  label: 'polExam',
  isChecked: false,
}, {
  value: 'polInternational',
  label: 'polInternational',
  isChecked: false,
});

const examsEn = examsUa.concat({
  value: 'polExam',
  label: 'polExam',
  isChecked: false,
});

export const exams = {
  [I18nLanguage.UA]: examsUa,
  [I18nLanguage.PL]: examsPl,
  [I18nLanguage.EN]: examsEn,
}

export const specialNeeds = [{
  value: 'autism',
  label: 'autism',
  isChecked: false,
}, {
  value: 'miscalculation',
  label: 'miscalculation',
  isChecked: false,
}, {
  value: 'cerebralPalsy',
  label: 'cerebralPalsy',
  isChecked: false,
}, {
  value: 'mentalStress',
  label: 'mentalStress',
  isChecked: false,
}, {
  value: 'mentalDisabilities',
  label: 'mentalDisabilities',
  isChecked: false,
}];

export const studyLevels = [{
  value: 'depth_study',
  label: 'depth_study',
  isChecked: false,
}];

export const teachingStyles = [{
  value: 'authoritarian',
  label: 'authoritarian',
  isChecked: false,
}, {
  value: 'democratic',
  label: 'democratic',
  isChecked: false,
}, {
  value: 'liberal',
  label: 'liberal',
  isChecked: false,
}];

export const lessonDurations = [{
  value: '30',
  label: 'half-hour',
  isChecked: false,
}, {
  value: '60',
  label: 'one-hour',
  isChecked: false,
}];

export const teachingLanguages = [{
  value: I18nLanguage.UA.toUpperCase(),
  label: 'uk',
  isChecked: false,
  img: '/assets/flags/ua-100px.png',
}, {
  value: I18nLanguage.EN.toUpperCase(),
  label: 'en',
  isChecked: false,
  img: '/assets/flags/gb-100px.png',
}, {
  value: I18nLanguage.DE.toUpperCase(),
  label: 'de',
  isChecked: false,
  img: '/assets/flags/de-100px.png',
}, {
  value: I18nLanguage.PL.toUpperCase(),
  label: 'pl',
  isChecked: false,
  img: '/assets/flags/pl-100px.png',
}, {
  value: I18nLanguage.FR.toUpperCase(),
  label: 'gf',
  isChecked: false,
  img: '/assets/flags/fr-100px.png',
}, {
  value: I18nLanguage.CZ.toUpperCase(),
  label: 'cz',
  isChecked: false,
  img: '/assets/flags/cz-100px.png',
}, {
  value: I18nLanguage.OTHER,
  label: 'other',
  isChecked: false,
}];

export const teacherStatuses = [{
  value: 'regular',
  label: 'regular',
  isChecked: false,
}, {
  value: 'trainee',
  label: 'trainee',
  isChecked: false,
}];

export const verifications = [{
  value: 'show_verified',
  label: 'show_verified',
  isChecked: false,
}];

export const additionalSubjects = [{
  value: 'Phisics',
  label: 'phisics',
  isChecked: false,
}, {
  value: 'ComputerSciense',
  label: 'informatics',
  isChecked: false,
}];

export const paymentLevels = [{
  value: 'Basic',
  label: 'Basic',
  isChecked: false,
}, {
  value: 'Silver',
  label: 'Silver',
  isChecked: false,
}, {
  value: 'Gold',
  label: 'Gold',
  isChecked: false,
}, {
  value: 'Premium',
  label: 'Premium',
  isChecked: false,
}];

export const qualificationsUa = [{
  value: 'chartered_math_teacher',
  label: 'chartered_math_teacher',
  isChecked: false,
}, {
  value: 'further_math_teacher',
  label: 'further_math_teacher',
  isChecked: false,
}, {
  value: 'npqml',
  label: 'npqml',
  isChecked: false,
}, {
  value: 'npqsl',
  label: 'npqsl',
  isChecked: false,
}, {
  value: 'first_category',
  label: 'first_category',
  isChecked: false,
}, {
  value: 'second_category',
  label: 'second_category',
  isChecked: false,
}, {
  value: 'higher_category',
  label: 'higher_category',
  isChecked: false,
}, {
  value: 'no_category',
  label: 'no_category',
  isChecked: false,
}, {
  value: 'other',
  label: 'other',
  isChecked: false,
}];

const qualificationsPl = [...qualificationsUa].splice(0, 4);
const qualificationsEn = [...qualificationsUa].splice(0, 4);

export const qualifications = {
  [I18nLanguage.UA]: qualificationsUa,
  [I18nLanguage.PL]: qualificationsPl,
  [I18nLanguage.EN]: qualificationsEn,
}

export const genders = [{
  value: 'Male',
  label: 'male',
  isChecked: false,
}, {
  value: 'Female',
  label: 'female',
  isChecked: false,
}];
