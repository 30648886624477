export enum AppRoutesDefinitions {
  USERS_OLD = 'users-old',
  USERS = 'users',
  LOGIN = 'login',
  PROFILE_CREATION = 'profile-creation',
  CLIENT_PROFILE = 'client-profile',
  VIEW_USER_INFO = 'account-info',
  FREE_HOURS = 'free-hours',
  SIGNUP = 'signup',
  SIGNUP_SUCCESS = 'signup-success',
  PASSWORD_RESTORE = 'password-restore',
  CALENDAR_START = 'calendar-start',
  CALENDAR = 'calendar',
  DEAL = 'deal',
  FREE_SLOTS = 'free-slots',
  STUDENTS = 'students',
  STUDENT_JOURNAL = 'student-journal',
}
