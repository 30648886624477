import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import ScheduleCalendarService from '../schedule-calendar.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import ScheduleApiService from '../../schedule-api.service';
import LanguageService from '../../../../../shared/language/language.service';
import {
  dateToYyyyMmDd, daysBetweenDates,
  splitNumbersArrayByDiapasons,
  splitNumbersArrayBySerialSegments,
  timeSlotDiapasonToTimeString, timeStringToTimeSlotDiapason,
} from '../../../../../shared/utils';
import LessonDto from '../../dto/lesson.dto';
import ScheduleDto from '../../dto/schedule.dto';
import { splitFreeSlotsByLessonTimes } from '../../slots-utils';

@Component({
  selector: 'mathema-schedule-calendar-move-lesson',
  templateUrl: './schedule-calendar-move-lesson.component.html',
  styleUrls: [
    './schedule-calendar-move-lesson.component.scss',
    '../schedule-calendar-lesson-details/schedule-calendar-lesson-details.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarMoveLessonComponent implements OnInit, OnDestroy {

  @Input() showNotification = false;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  public isLoading: boolean;

  public lessonData$: Observable<LessonDto>;
  public autoSlotsData$: Observable<ScheduleDto[]>;
  public manualSlotsData$: Observable<ScheduleDto[]>;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  public manualMode: boolean = false;
  public manualDate: Date | null = null;
  public checkedTime: string | null;
  public notificationNeeded = false;

  private teacherId: string;
  private lessonId: string;
  private lessonDuration: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(this.scheduleCalendarService.activeSlot.subscribe(activeSlot => {
      if (!activeSlot) {
        return;
      }

      const [,lessonId] = activeSlot.id.split('|');
      this.lessonId = lessonId;

      this.lessonData$ = this.scheduleApiService.getLessonInfoById(this.lessonId).pipe(
        tap((lesson) => {
          this.teacherId = lesson.teacher.id;
          this.lessonDuration = lesson.timeSlot.length;

          if (!this.manualMode) {
            this.loadAutoSlots(lesson);
          }
        }),
        catchError(() => {
          this.loadingError$.next(true);
          return EMPTY;
        }),
      );
    }));
  }

  private loadAutoSlots(lesson: LessonDto): void {
    const today = dateToYyyyMmDd(new Date());
    const dateFrom = daysBetweenDates(today, lesson.date) > 0 ? today : lesson.date;
    this.autoSlotsData$ = this.scheduleApiService.getSchedulesList({
      teacherId: this.teacherId,
      dateFrom,
      lessonDuration: lesson.timeSlot.length * 5, // 30 for half hour lesson, 60 for hour lesson
      limit: 5,
    });
  }

  public switchMode(isManual: boolean = false): void {
    this.manualMode = isManual;
    this.checkedTime = null;
  }

  public onManualDateChange(date: Date): void {
    if (!date) {
      this.checkedTime = null;
      this.manualSlotsData$ = EMPTY;
      return;
    }

    this.manualSlotsData$ = this.scheduleApiService.getSchedulesList({
      teacherId: this.teacherId,
      dateFrom: dateToYyyyMmDd(date),
      limit: 1,
    }).pipe(map(result => {
      if (result.every(freeSlot => freeSlot.timeSlots.length < this.lessonDuration)) {
        return [];
      }

      return result;
    }));
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public timeSlotToTime(timeSlots: number[]): string {
    return timeSlotDiapasonToTimeString(timeSlots);
  }

  public timeDiapasonToFreeSlotsTimes(freeSlotDate: string, freeSlotFullDiapason: number[], size: number): string[] {
    return splitFreeSlotsByLessonTimes(freeSlotDate, freeSlotFullDiapason, size);
  }

  public onTimeChecked(isChecked: boolean, timeId: string): void {
    if (isChecked) {
      this.checkedTime = timeId;
    } else {
      this.checkedTime = null;
    }
  }

  public onConfirmMoving(): void {
    const [time, freeSlotId] = this.checkedTime.split('|');
    const diapason = timeStringToTimeSlotDiapason(time);

    this.isLoading = true;
    this.subscriptions.push(
      this.scheduleApiService.moveLesson(this.lessonId, freeSlotId, diapason, this.notificationNeeded).pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(() => {
        this.scheduleCalendarService.refreshCalendarDate();
        this.onClose.emit();
      }),
    );
  }

  public isDateYesterdayOrBefore(): (date: Date) => boolean {
    return (date: Date) => {
      return daysBetweenDates(dateToYyyyMmDd(new Date()), dateToYyyyMmDd(date)) > 0;
    }
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
