import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import LanguageService from '../../../../../shared/language/language.service';
import { MonthlyCalendarItem } from '../../../schedule/schedule-api.service';
import { TranslateService } from '@ngx-translate/core';

export interface AvailableIntersectionDates {
  [lessonDate: string]: {
    date: string,
    isChecked: boolean,
    freeSlots: {
      time: string,
      isChecked: boolean,
    }[],
  }[];
}

export interface IntersectionsReplaces {
  [lessonDate: string]: {
    date: string;
    time: string;
    item?: MonthlyCalendarItem;
  }
}

@Component({
  selector: 'mathema-slots-intersection-modal',
  templateUrl: './slots-intersection-modal.component.html',
  styleUrls: ['./slots-intersection-modal.component.scss']
})
export class SlotsIntersectionModalComponent implements OnInit {

  @Input() intersections: { time: string, item: MonthlyCalendarItem }[];
  @Input() availableDates: AvailableIntersectionDates;

  @Output() onDecline: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAccept: EventEmitter<IntersectionsReplaces> = new EventEmitter<IntersectionsReplaces>();

  private timesToChooseCount: number = 0;

  public intersectionsReplaces: IntersectionsReplaces = {};

  constructor(
    public readonly modalRef: NzModalRef,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    for (const availableDate of Object.values(this.availableDates)) {
      if (availableDate.length) {
        this.timesToChooseCount++;
      }
    }
  }

  public isAcceptDisabled(): boolean {
    return Object.keys(this.intersectionsReplaces).length !== this.timesToChooseCount;
  }

  public onDateCheckChange(isChecked: boolean, lessonDate: string, date: string): void {
    const lessonDates = this.availableDates[lessonDate];

    const pastCheckedDate = lessonDates.find(el => el.isChecked);
    if (pastCheckedDate) {
      pastCheckedDate.isChecked = false;
      pastCheckedDate.freeSlots.forEach(el => {
        el.isChecked = false;
      });
    }

    const affectedDate = lessonDates.find(el => el.date === date);
    affectedDate.isChecked = isChecked;
    affectedDate.freeSlots.forEach(el => {
      el.isChecked = false;
    });

    delete this.intersectionsReplaces[lessonDate];
  }

  public onTimeCheckChange(isChecked: boolean, lessonDate: string, date: string, time: string): void {
    const lessonDates = this.availableDates[lessonDate];

    const affectedDate = lessonDates.find(el => el.date === date);

    const pastAffectedTime = affectedDate.freeSlots.find(el => el.isChecked);
    if (pastAffectedTime) {
      pastAffectedTime.isChecked = false;
    }

    const affectedTime = affectedDate.freeSlots.find(el => el.time === time);
    affectedTime.isChecked = isChecked;

    if (isChecked) {
      this.intersectionsReplaces[lessonDate] = {
        date,
        time,
      }
    } else {
      delete this.intersectionsReplaces[lessonDate];
    }
  }

  public formatDate(date: string, withYear: boolean = false): string {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
    }

    if (withYear) {
      options.year = 'numeric';
    }

    const formatter = Intl.DateTimeFormat(this.languageService.locale, options);

    const result = new Date(date);

    if (withYear) {
      return formatter.format(result);
    }

    return formatter.format(result);
  }

  public formatTime(time: string): string {
    const [from, to] = time.split(' - ');
    return `${this.translateService.instant('from')} ${from} ${this.translateService.instant('to')} ${to}`;
  }

}
