import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  emailValue: string;
  password: string;
  teacher_id: string;
  role: string;
  roleId: string;
}

export interface ICreateTeacher {
  teacherId: string;
  firstName?: string;
  lastName?: string;
  email?:string;
  password: string;
  language: string;
  role: string;
}

export interface ICreateManager {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  language: string;
  role: string
}

export interface IUpdatePassword {
  oldPassword: string;
  newPassword: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUsers() {
    return this.http.get<any[]>('/user');
  }

  public removeUser(id: string) {
    return this.http.delete<any[]>('/user/' + id);
  }

  public createUser(data: ICreateTeacher | ICreateManager) {
    return this.http.post<IUser>('/user/register', data);
  }

  public updatePassword(userId: string, data: IUpdatePassword) {
    return this.http.patch<IUser>(`/user/${userId}`, data);
  }

  public restorePassword(email: string) {
    return this.http.post<any>('/user/password-restore', {email});
  }
}
