import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StatementModel } from '../models/statement';

@Injectable({
    providedIn: 'root'
})
export class StatementApiService {
    constructor(
        private readonly http: HttpClient
    ) { }

    public getTeacherStatement$GET(teacherId: string, dateFrom: string, dateTo: string): Observable<StatementModel>{
        return this.http.get<StatementModel>(`/teachers/statement/${teacherId}`, {params: {dateFrom, dateTo}});
    }
}