<div class="change-password-modal">
  <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate" *ngIf="isLoading"></nz-spin>
  <header>{{'change-password.header' | translate}}</header>
  <section>
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="oldPassword" nzRequired>{{'change-password.old-password' | translate}}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="'change-password.old-password-required' | translate">
          <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordSuffix">
            <input
              nz-input
              [type]="showPasswords ? 'text' : 'password'"
              id="oldPassword"
              formControlName="oldPassword"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="newPassword" nzRequired>{{'change-password.new-password' | translate}}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
          <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordSuffix">
            <input
              nz-input
              [type]="showPasswords ? 'text' : 'password'"
              id="newPassword"
              formControlName="newPassword"
              (ngModelChange)="updateConfirmValidator()"
            />
          </nz-input-group>
          <ng-template #errorTpl let-control>
            <ng-container *ngIf="control.errors?.['required']">{{'change-password.new-password-required' | translate}}</ng-container>
            <ng-container *ngIf="control.errors?.['minlength']">{{'change-password.new-password-min' | translate}}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="checkPassword" nzRequired>{{'change-password.password-confirm' | translate}}</nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="errorTpl">
          <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordSuffix">
            <input
              nz-input
              [type]="showPasswords ? 'text' : 'password'"
              formControlName="checkPassword"
              id="checkPassword"
            />
          </nz-input-group>
          <ng-template #errorTpl let-control>
            <ng-container *ngIf="control.errors?.['required']">{{'change-password.password-confirm-required' | translate}}</ng-container>
            <ng-container *ngIf="control.errors?.['confirm']">{{'change-password.password-confirm-differs' | translate}}</ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <ng-template #passwordPrefix>
        <span nz-icon>
          <img alt="" src="../../../../assets/login/password-prefix.svg">
        </span>
      </ng-template>
      <ng-template #passwordSuffix>
        <span nz-icon (click)="showPasswords = !showPasswords" class="pointer">
          <img src="../../../../assets/login/password-suffix.svg" alt="">
        </span>
      </ng-template>
    </form>
  </section>
  <footer>
    <button class="decline" nz-button (click)="modalRef.close()">{{'main.btn.cancel' | translate}}</button>
    <button class="accept" nz-button [disabled]="!form.valid" (click)="onSubmit()">{{'main.btn.save' | translate}}</button>
  </footer>
</div>
