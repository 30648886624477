<div class="vacations-loader" *ngIf="isLoading">
  <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
  <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
</div>

<div class="vacations-wrapper">
  <div class="mode-control">
    <span (click)="isAddModeActive = true">{{'calendar.vacations.add' | translate}}</span>
    <nz-switch
      [(ngModel)]="isAddModeActive"
      [nzCheckedChildren]="addVacationIcon"
      [nzUnCheckedChildren]="removeVacationIcon"
    ></nz-switch>
    <span (click)="isAddModeActive = false">{{'calendar.vacations.remove' | translate}}</span>
    <ng-template #addVacationIcon><span nz-icon nzType="plus"></span></ng-template>
    <ng-template #removeVacationIcon><span nz-icon nzType="minus"></span></ng-template>
  </div>
  <div class="range-control">
    <span nz-typography nzType="secondary">{{'calendar.vacations.duration' | translate}}</span>
    <nz-range-picker [nzRanges]="ranges" [(ngModel)]="dates" [nzDisabledDate]="isDateYesterdayOrBefore()"></nz-range-picker>
  </div>
  <div class="action-control">
    <button (click)="submit()" nz-button class="action-btn" [disabled]="!dates[0] || !dates[1]" [class.isAdd]="isAddModeActive">
      <span *ngIf="isAddModeActive; else deleteText">{{'main.btn.add' | translate}}</span>
      <ng-template #deleteText>{{'main.btn.remove' | translate}}</ng-template>
    </button>
  </div>
</div>
